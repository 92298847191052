import {
  ACCOUNT_NO,
  ALL_AGENTS_ARE_BUSY_ENG,
  ALL_AGENTS_ARE_BUSY_ESP,
  ALL_AGENTS_BUSY_EST_TIME_ENG,
  ALL_AGENTS_BUSY_EST_TIME_ESP,
  CB_GREETING_INTENT_ZERO_ONE_ENG,
  CB_GREETING_INTENT_ZERO_ONE_ESP,
  CHOOSE_END_DATE_ENG,
  CHOOSE_END_DATE_ESP,
  CHOOSE_START_DATE_ENG,
  CHOOSE_START_DATE_ESP,
  CONNECT_ME_TO_LIVE_AGENT_ENG,
  CONNECT_ME_TO_LIVE_AGENT_ESP,
  CONTACT_YOU_SOON_ENG,
  CONTINUE_WITH_CHATBOT_ENG,
  CONTINUE_WITH_CHATBOT_ESP,
  ENTER_ACCOUNT_NUMBER_ENG,
  ENTER_ACCOUNT_NUMBER_ESP,
  EST_WAIT_TIME_ENG,
  EST_WAIT_TIME_ESP,
  GO_BACK_TO_CHATBOT_ENG,
  GO_BACK_TO_CHATBOT_ESP,
  NEED_ANYTHING_ELSE_ENG,
  NEED_ANYTHING_ELSE_ESP,
  REFER_TO_THIS_IMAGE,
  REFER_TO_THIS_IMAGE_ESP,
  REQUEST_CALL_BACK_ENG,
  REQUEST_CALL_BACK_ESP,
  SKIP_TEXT_ENG,
  SKIP_TEXT_ESP,
  SOON_CONTACT_YOU_ESP,
  TRAVEL_DATES,
  WAIT_FOR_LIVE_AGENT_ENG,
  WAIT_FOR_LIVE_AGENT_ESP,
  WANT_CONTACT_BACK_ENG,
  WANT_CONTACT_BACK_ESP,
  WILL_CONTACT_YOU_SOON_ENG,
  WILL_CONTACT_YOU_SOON_ESP,
} from '../Constants';

const commonCheck = (bot, param1, param2) => {
  if (bot) {
    return param1;
  }
  return param2;
};
export const getBotConversation = (messages, englishBot) => {
  const BotConversation = [];
  const yesNoButton = [
    {
      title: commonCheck(englishBot, 'Yes', 'Sí'),
    },
    {
      title: 'No',
    },
  ];
  const liveAgentFlowCallbackButtons = [
    {
      title: commonCheck(englishBot, 'Yes', 'Sí'),
    },
    {
      title: commonCheck(englishBot, CONNECT_ME_TO_LIVE_AGENT_ENG, CONNECT_ME_TO_LIVE_AGENT_ESP),
    },
    {
      title: commonCheck(englishBot, CONTINUE_WITH_CHATBOT_ENG, CONTINUE_WITH_CHATBOT_ESP),
    },
  ];
  const check = (message) =>
    message.intentName === CB_GREETING_INTENT_ZERO_ONE_ENG ||
    message.intentName === CB_GREETING_INTENT_ZERO_ONE_ESP;

  messages?.map((message) => {
    let buttons = [];
    let messageImage = {};
    let inputMessage = '';
    if (check(message)) {
      buttons = [
        {
          title: commonCheck(englishBot, 'Yes', 'Sí'),
        },
        {
          title: 'No',
        },
      ];
    } else if (message.message.match(/^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/)) {
      inputMessage = message.message.replace(/([^- )(])(?=.{2})/g, 'X');
    } else if (
      /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i.test(
        message.message
      )
    ) {
      const slug = message.message.split('@');
      const prefix = slug[0].replace(/.(?=.{4})/g, 'X');
      const sufix = slug[1];
      const emailMsg = `${prefix}@${sufix}`;
      inputMessage = emailMsg;
    } else if (
      message.message.includes(ALL_AGENTS_BUSY_EST_TIME_ENG) ||
      message.message.includes(ALL_AGENTS_BUSY_EST_TIME_ESP)
    ) {
      buttons = getButtonWhilewaitingForLiveAgent(message, englishBot);
    } else if (
      message.message.includes(NEED_ANYTHING_ELSE_ENG) ||
      message.message.includes(NEED_ANYTHING_ELSE_ESP) ||
      message.message.includes(CONTACT_YOU_SOON_ENG) ||
      message.message.includes(SOON_CONTACT_YOU_ESP) ||
      message.message.includes(WILL_CONTACT_YOU_SOON_ENG) ||
      message.message.includes(WILL_CONTACT_YOU_SOON_ESP) ||
      message.message.includes(ALL_AGENTS_ARE_BUSY_ENG) ||
      message.message.includes(ALL_AGENTS_ARE_BUSY_ESP)
    ) {
      buttons = yesNoButton;
    } else if (
      message.message.includes(WANT_CONTACT_BACK_ENG) ||
      message.message.includes(WANT_CONTACT_BACK_ESP)
    ) {
      buttons = liveAgentFlowCallbackButtons;
    } else if (message.slotToElicit === TRAVEL_DATES) {
      buttons = [
        {
          title: commonCheck(englishBot, CHOOSE_START_DATE_ENG, CHOOSE_START_DATE_ESP),
        },
      ];
    } else if (message.slotToElicit === ACCOUNT_NO) {
      buttons = [
        {
          title: commonCheck(englishBot, SKIP_TEXT_ENG, SKIP_TEXT_ESP),
        },
        {
          title: commonCheck(englishBot, ENTER_ACCOUNT_NUMBER_ENG, ENTER_ACCOUNT_NUMBER_ESP),
        },
      ];
    } else if (
      message.message.includes(REFER_TO_THIS_IMAGE) ||
      message.message.includes(REFER_TO_THIS_IMAGE_ESP)
    ) {
      messageImage = {
        imgName: 'account intent image',
        imgDesc: 'account intent image',
      };
    } else {
      buttons = [];
    }
    const botButtons = message.responseCard ? message.responseCard : '';
    pushToTranscriptObject(
      message,
      englishBot,
      buttons,
      botButtons,
      inputMessage,
      messageImage,
      BotConversation
    );
    return 0;
  });
  return BotConversation;
};

const pushToTranscriptObject = (
  message,
  englishBot,
  buttons,
  botButtons,
  inputMessage,
  messageImage,
  BotConversation
) => {
  let CalendarButtons = [];
  if (botButtons && botButtons.length > 0) {
    for (const element of botButtons) {
      delete element.value;
    }
  }
  if (message.showEndDateCalendar === false) {
    CalendarButtons = [
      {
        title: englishBot ? CHOOSE_END_DATE_ENG : CHOOSE_END_DATE_ESP,
      },
    ];
    BotConversation.push({
      inputTranscript: '',
      botResponseMessage: '',
      buttonTexts: CalendarButtons,
      messageImage: {},
      messageFromLex: {
        value: `true`,
      },
    });
  }
  BotConversation.push({
    inputTranscript: message.isOwn ? inputMessage || message.message : '',
    botResponseMessage: !message.isOwn ? message.message : '',
    buttonTexts: botButtons || buttons,
    messageImage,
    messageFromLex: {
      value: `${message.msgFromLex}`,
    },
  });
};
const getButtonWhilewaitingForLiveAgent = (message, englishBot) => {
  if (message.message.includes(EST_WAIT_TIME_ENG) || message.message.includes(EST_WAIT_TIME_ESP)) {
    return [
      {
        title: englishBot ? WAIT_FOR_LIVE_AGENT_ENG : WAIT_FOR_LIVE_AGENT_ESP,
      },
      {
        title: englishBot ? GO_BACK_TO_CHATBOT_ENG : GO_BACK_TO_CHATBOT_ESP,
      },
      {
        title: englishBot ? REQUEST_CALL_BACK_ENG : REQUEST_CALL_BACK_ESP,
      },
    ];
  }
  return [
    {
      title: englishBot ? WAIT_FOR_LIVE_AGENT_ENG : WAIT_FOR_LIVE_AGENT_ESP,
    },
    {
      title: englishBot ? GO_BACK_TO_CHATBOT_ENG : GO_BACK_TO_CHATBOT_ESP,
    },
  ];
};
