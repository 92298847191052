import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import JarImage from '../../../images/cd_processing_img.svg';
import Loader from '../../Loader/Loader';
import { CDProcessingStyles } from './CDProcessingStyles';

const CDProcessingLoader = () => {
  const classes = CDProcessingStyles();
  const { t } = useTranslation();
  return (
    <Grid container className={classes.loader}>
      <Grid item lg={12} md={12} sm={12} className={classes.contentWrapper}>
        <Loader />
        <Typography variant="h2" className={classes.heading}>
          {t('We are opening your account', {
            defaultValue: 'We are opening your account',
          })}
        </Typography>
        <Typography variant="p" className={classes.subText}>
          {t('This will only take a few seconds, please do not close this window.', {
            defaultValue: 'This will only take a few seconds, please do not close this window.',
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CDProcessingLoader;
