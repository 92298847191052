// Domain error check
export const getEmailErrorType = (errorMsg) => {
  const domainErrors = [
    'Email Domain Not Found',
    'Email Server Not Found',
    'Invalid Domain',
    'Unavailable Domain',
  ];
  const mailboxErrors = ['Invalid Email', 'Unknown Email'];
  for (const error of domainErrors) {
    if (error === errorMsg) {
      return 'Domain_Error';
    }
  }
  for (const error of mailboxErrors) {
    if (error === errorMsg) {
      return 'Mailbox_Error';
    }
  }
  return null;
};
// Format phone number for PR location
export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = `${phoneNumberString.replace(/\D/g, '')}`;
  const match = cleaned.match(/^(\d{3})(\d{4})$/);
  if (match && cleaned.length === 7) {
    return `${match[1]}-${match[2]}`;
  }
  if (cleaned.length < 7) {
    return cleaned;
  }
};
// Format phone number for VI location
export const formatPhoneNumberForVI = (phoneNumberString) => {
  const cleaned = `${phoneNumberString.replace(/\D/g, '')}`;
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match && cleaned.length === 10) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  if (cleaned.length < 10) {
    return cleaned;
  }
};
