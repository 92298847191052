import { PropTypes } from 'prop-types';
import failedIcon from '../images/failed_icon.png';
import deliveredIcon from '../images/delivered_icon.png';
import sentReceivedIcon from '../images/sent_received_icon.png';
import { CommonStyles } from '../../styles/ChatBotCommonStyles';

const useCommonStyles = CommonStyles();

const MessageStatus = (props) => {
  const CommoncssStyles = useCommonStyles();

  const { message, lexError } = props;

  if (message.isOwn) {
    if (message.msgForAgent) {
      let srcImg = '';
      if (message.sentFailedStatus) {
        srcImg = failedIcon;
      } else if (message.readStatus) {
        srcImg = sentReceivedIcon;
      } else {
        srcImg = deliveredIcon;
      }
      return <img className={CommoncssStyles.msg_status} src={srcImg} alt="status icon" />;
    }
    return (
      <img
        className={CommoncssStyles.msg_status}
        src={sentReceivedIcon}
        alt={lexError ? 'failed' : 'sent'}
      />
    );
  }
  return null;
};
MessageStatus.propTypes = {
  message: PropTypes.object,
  lexError: PropTypes.bool,
};
export default MessageStatus;
