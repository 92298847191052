/* eslint-disable react/no-unstable-nested-components */
import { DesktopDatePicker, esES } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, InputLabel, OutlinedInput, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import LeadGenerationContext from '../../../context/LeadGenerationContext';
import { CustomDobStyles } from './CustomDobStyles';
import 'dayjs/locale/es';

import styles from './CustomDob.module.css';
import { commonTernary, getLang } from '../../commonCode';
import { REQUIRED } from '../../../constants';

export const CustomDob = ({
  label,
  containerClass,
  id,
  dataTestIdForLabel,
  required,
  value,
  format,
  // inputLabelAriaLabel,
  onChange,
  handleChange,
  inputLabelClassName,
  dataTestId,
  autoFocus,
  error,
  InputClassName,
  placeholder,
  fullWidth,
  disabled,
  minDatePicker,
  maxDatePicker,
  msgSelectDate,
  inputFormat,
  shouldDisableDate,
  transactionDate,
  dueDate,
  noIcon,
  isFlowTypeETB,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const context = useContext(LeadGenerationContext);
  const { language } = context;

  const classes = CustomDobStyles();
  const datePickerTheme = createTheme(
    {
      palette: {
        primary: {
          main: '#661c69',
        },
      },
    },
    esES
  );

  useEffect(() => {
    const calendarId = document.querySelector('#calendarBtn > button');
    if (calendarId) {
      calendarId.setAttribute('aria-label', language === 'EN' ? 'Calendar' : 'Elige la fecha');
    }
  }, [language]);
  return (
    <>
      <InputLabel
        htmlFor={id}
        className={`${classes.label_title}`}
        data-testid={dataTestIdForLabel || null}
        required
        aria-label={`${t('Date of Birth', { defaultValue: 'Date of Birth' })} ${t(REQUIRED, {
          defaultValue: REQUIRED,
        })}`}
      >
        {isFlowTypeETB
          ? t('Date of birth', { defaultValue: 'Date of birth' })
          : t('Date of Birth', { defaultValue: 'Date of Birth' })}
      </InputLabel>

      <ThemeProvider theme={datePickerTheme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getLang(language, true)}>
          <DesktopDatePicker
            defaultCalendarMonth={commonTernary(dueDate, dueDate, null)}
            value={!value ? null : value}
            label="dob"
            open={open}
            views={['year', 'month', 'day']}
            openTo={value ? 'day' : 'year'}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            inputFormat={inputFormat}
            onChange={(dateValue) => {
              if (!disabled) {
                setOpen(true);
              }
              setTimeout(() => {
                document.getElementById(id)?.focus();
              }, 100);
              onChange(dateValue?.$d ? dateValue.$d : dateValue);
            }}
            minDate={minDatePicker}
            maxDate={maxDatePicker}
            shouldDisableDate={shouldDisableDate}
            disabled={commonTernary(disabled, disabled, false)}
            components={{
              OpenPickerIcon: () => (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id=" calendar">
                    <path
                      id="Icon"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7 16C7 15.45 7.45 15 8 15C8.55 15 9 15.45 9 16C9 16.55 8.55 17 8 17C7.45 17 7 16.55 7 16ZM12 15H16C16.55 15 17 15.45 17 16C17 16.55 16.55 17 16 17H12C11.45 17 11 16.55 11 16C11 15.45 11.45 15 12 15ZM18 20H6C5.449 20 5 19.551 5 19V13H19V19C19 19.551 18.551 20 18 20ZM6 6H7V7C7 7.55 7.45 8 8 8C8.55 8 9 7.55 9 7V6H15V7C15 7.55 15.45 8 16 8C16.55 8 17 7.55 17 7V6H18C18.551 6 19 6.449 19 7V11H5V7C5 6.449 5.449 6 6 6ZM18 4H17V3C17 2.45 16.55 2 16 2C15.45 2 15 2.45 15 3V4H9V3C9 2.45 8.55 2 8 2C7.45 2 7 2.45 7 3V4H6C4.346 4 3 5.346 3 7V19C3 20.654 4.346 22 6 22H18C19.654 22 21 20.654 21 19V7C21 5.346 19.654 4 18 4Z"
                      fill={disabled ? '#b28db4' : '#661C69'}
                    />
                  </g>
                </svg>
              ),
            }}
            InputAdornmentProps={{ className: styles.dobPick, id: 'calendarBtn' }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <Box className={commonTernary(containerClass, containerClass, styles.dobContainer)}>
                <OutlinedInput
                  ref={inputRef}
                  {...inputProps}
                  placeholder={placeholder}
                  onClick={(e) => {
                    if (!disabled) {
                      setOpen(true);
                    }
                    setTimeout(() => {
                      document.getElementById(id).focus();
                    }, 100);
                  }}
                  id={id}
                  name={id}
                  className={
                    error
                      ? `${styles.outlined_inputs_error}  ${classes.select_inputs_err}`
                      : commonTernary(
                          disabled,
                          `${classes.dob_month_year_select_disabled}`,
                          `${styles.outlined_inputs}`
                        )
                  }
                  fullWidth
                  inputProps={{
                    'aria-label': msgSelectDate,
                    'aria-required': `${required || false}`,
                    'data-testid': dataTestId,
                  }}
                  endAdornment={disabled ? '' : InputProps?.endAdornment}
                />
              </Box>
            )}
          />
        </LocalizationProvider>
      </ThemeProvider>
      {error ? (
        <Box className={commonTernary(disabled, styles.disableError, styles.error)} role="alert">
          {t(error, { defaultValue: error })}
        </Box>
      ) : (
        ''
      )}
    </>
  );
};
CustomDob.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  containerClass: PropTypes.object,
  inputLabelClassName: PropTypes.object,
  InputClassName: PropTypes.object,
  required: PropTypes.bool,
  value: PropTypes.string,
  format: PropTypes.func,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  handleChange: PropTypes.func,
  minDatePicker: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxDatePicker: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  msgSelectDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  autoFocus: PropTypes.bool,
  dataTestId: PropTypes.string,
  shouldDisableDate: PropTypes.bool,
  transactionDate: PropTypes.string,
  dueDate: PropTypes.string,
  noIcon: PropTypes.bool,
  placeholder: PropTypes.string,

  day: PropTypes.string,
  month: PropTypes.string,
  disabled: PropTypes.bool,
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  language: PropTypes.string,
  dob: PropTypes.string,
  dataTestIdForLabel: PropTypes.string,
  error: PropTypes.string,
  inputFormat: PropTypes.string,
  isFlowTypeETB: PropTypes.bool,
};
