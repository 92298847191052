import { PropTypes } from 'prop-types';
import { IconButton } from '@livechat/ui-kit';
import {
  ENTER_ACCOUNT_NUMBER_ENG,
  ENTER_ACCOUNT_NUMBER_ESP,
  ENTER_CARD_NUMBER_ENG,
  ENTER_CARD_NUMBER_ESP,
  ENTER_CARD_NUMBER_OPTIONAL_ENG,
  ENTER_CARD_NUMBER_OPTIONAL_ESP,
  SKIP_TEXT_ENG,
  SKIP_TEXT_ESP,
} from '../../Constants';

const AccountInfo = (props) => {
  const { handleSkipThisStep, handleAccountNumber, englishBot, message } = props;
  const skipText = englishBot ? SKIP_TEXT_ENG : SKIP_TEXT_ESP;
  let accountText = '';
  if (
    message.includes(ENTER_CARD_NUMBER_OPTIONAL_ENG) ||
    message.includes(ENTER_CARD_NUMBER_OPTIONAL_ESP)
  ) {
    accountText = englishBot ? ENTER_CARD_NUMBER_ENG : ENTER_CARD_NUMBER_ESP;
  } else {
    accountText = englishBot ? ENTER_ACCOUNT_NUMBER_ENG : ENTER_ACCOUNT_NUMBER_ESP;
  }
  return (
    <div
      className="account-btn"
      style={{
        margin: '10px 0',
      }}
    >
      <IconButton onClick={handleSkipThisStep}>{skipText}</IconButton>
      <IconButton onClick={handleAccountNumber}>{accountText}</IconButton>
    </div>
  );
};
AccountInfo.propTypes = {
  englishBot: PropTypes.bool,
  handleSkipThisStep: PropTypes.func,
  handleAccountNumber: PropTypes.func,
  message: PropTypes.string,
};
export default AccountInfo;
