import { makeStyles } from '@material-ui/core';
import { COLOR1, COLOR6, RADIO_COLOR } from '../../../constants';

export const CustomDobStyles = makeStyles(
  (theme) => ({
    label_title: {
      marginBottom: '10px !important',
      font: 'normal normal 700 14px/18px Arial !important',
      letterSpacing: '0.1px',
      lineHeight: '18px',
      color: `${COLOR6} !important`,
      textAlign: 'left',
      [theme.breakpoints.down('600')]: {
        marginBottom: '10px',
      },
    },
    select_inputs_err: {
      [`& fieldset`]: {
        borderRadius: '6px',
        border: '1px solid #FD0000 !important',
      },
    },

    dob_month_year_select: {
      '& .MuiSelect-outlined': {
        paddingRight: '10px',
        paddingLeft: '10px',
      },
      '& .MuiSelect-icon': {
        [theme.breakpoints.between('960', '1030')]: {
          right: 0,
        },
        [theme.breakpoints.down('370')]: {
          right: -5,
        },
      },
    },
    dob_month_year_select_disabled: {
      background: '#F3F3F3',
      border: 'none !important',
      height: '48px',

      '& .MuiOutlinedInput-input': {
        padding: '11.5px 14px',
      },
    },

    dob_month_year_select_vi: {
      '& .MuiSelect-outlined': {
        paddingRight: '10px',
        paddingLeft: '10px',
        fontSize: '13px',
      },
      '& .MuiSelect-icon': {
        right: '5px',
        [theme.breakpoints.between('960', '1080')]: {
          right: -5,
        },
        [theme.breakpoints.down('370')]: {
          right: -5,
        },
      },
    },

    dob_day_select_vi: {
      '& .MuiSelect-outlined': {
        paddingRight: '10px',
      },
      [theme.breakpoints.down('650')]: {
        '& .MuiSelect-selectMenu': {
          overflow: 'visible',
        },
      },
    },

    dob_day_select: {
      '& .MuiSelect-outlined': {
        paddingRight: '10px',
        paddingLeft: '10px',
      },
    },
    datePickerStyle: {
      margin: '-35px 154px 26px 236px',
    },

    dob_container: {
      // textAlign: 'center',
      [theme.breakpoints.down('600')]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },

    date_of_birth_select: {
      background: 'rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box',
      opacity: 1,
      height: '41px',
      fontWeight: 300,
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      textAlign: 'left',
      letterSpacing: '0.1px',
      color: COLOR1,
      '& .MuiOutlinedInput-input': {
        paddingTop: '11.5px',
        paddingBottom: '11.5px',
      },
    },

    select_icon: {
      color: RADIO_COLOR,
      opacity: 1,
      right: '4px',
    },
  }),
  { index: 1 }
);
