import React from 'react';
import { Grid } from '@material-ui/core';
import axios from 'axios';
// import { validationSchemaForGeneralInfo } from '../balance-cancellation/FormModel/validationSchema';
// import { formInitialValuesForGeneralInfo } from '../balance-cancellation/FormModel/formInitialValues';
import { Formik, Form } from 'formik';
import { PropTypes } from 'prop-types';
import { base64Authorization } from '../../utils/CommonFunctions';
import { CommonStyles } from '../../styles/ChatBotCommonStyles';
import GeneralForm from './GeneralForm';

const useCommonStyles = CommonStyles();
const GeneralInfo = (props) => {
  const CommonCssStyles = useCommonStyles();
  const {
    englishBot,
    sessionID,
    setServiceFormTrigger,
    setGeneralFormTrigger,
    formInProcessTrigger,
    handeButtonLinkMessage,
    setCustomerDetails,
    spinnerHandler,
    setShowRegisterCustomerButton,
  } = props;

  function handlesubmit(values, actions) {
    setGeneralFormTrigger(false);
    formInProcessTrigger(false);
    setShowRegisterCustomerButton(true);
    const params = {
      sessionId: sessionID,
      customerEmail: values.personEmail,
      mobileNumber: `${values.mobileNumber}`,
      customerName: `${values.firstName} ${values.lastName}`,
      lineOfBusiness: values.accountType,
    };
    axios
      .post(
        process.env.REACT_APP_TOKEN_SERVICE_URL_CHATBOT,
        {},
        {
          headers: {
            Authorization: `Basic ${base64Authorization}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        axios
          .post(`${process.env.REACT_APP_USER_FORM_URL}`, params, {
            headers: {
              Authorization: `Bearer ${response.data.access_token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            const { data } = res;
            if (data.messages && data.messages[0].content.includes('templateType')) {
              const listPickerData = JSON.parse(data.messages[0].content);
              setCustomerDetails(listPickerData.data.content.sessionAttributes);
              setTimeout(() => {
                spinnerHandler(false);
                handeButtonLinkMessage(
                  { text: listPickerData.data.content.title, value: '', isButtonMessage: false },
                  false
                );
              }, 1000);
            }
          })
          .catch((err) => {});
      })
      .catch();
  }

  return (
    <Formik
      // initialValues={formInitialValuesForGeneralInfo}
      // validationSchema={validationSchemaForGeneralInfo}
      // eslint-disable-next-line react/jsx-no-bind
      onSubmit={handlesubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Grid item xs={12} className={CommonCssStyles.steps_grid} style={{ margin: '10px' }}>
            <GeneralForm
              englishBot={englishBot}
              setServiceFormTrigger={setServiceFormTrigger}
              setGeneralFormTrigger={setGeneralFormTrigger}
              spinnerHandler={spinnerHandler}
            />
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

GeneralInfo.propTypes = {
  englishBot: PropTypes.bool,
  sessionID: PropTypes.string,
  setServiceFormTrigger: PropTypes.func,
  setGeneralFormTrigger: PropTypes.func,
  formInProcessTrigger: PropTypes.func,
  handeButtonLinkMessage: PropTypes.func,
  setCustomerDetails: PropTypes.object,
  spinnerHandler: PropTypes.func,
  setShowRegisterCustomerButton: PropTypes.func,
};
export default GeneralInfo;
