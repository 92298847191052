import { makeStyles } from '@material-ui/core';
import { COLOR1 } from '../../../constants';

export const ProductHeadingStyles = makeStyles(
  (theme) => ({
    heading_info: {
      marginBottom: '15px',
      textAlign: 'left',
      width: 'fit-content',
      font: 'normal bold 28px TTNormsProBold',
      letterSpacing: '0.14px',
      color: COLOR1,
      opacity: 1,
      [theme.breakpoints.down('650')]: {
        font: 'normal 700 28px/33px TTNormsProBold',
        letterSpacing: '0.11px',
      },
    },

    sub_heading_info: {
      marginBottom: '20px',
      font: 'normal 700 16px/18px Arial',
      letterSpacing: '0.11px',
      opacity: 1,
      color: COLOR1,
    },
  }),
  { index: 1 }
);
