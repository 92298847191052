import axios from 'axios';
import { productMapping } from './productMapping';
import { productTypeMapping } from './productTypeMapping';

export const CDOffers = async (accessToken, location) => {
  let result = null;
  try {
    result = await axios.get(
      `${process.env.REACT_APP_CD_INTEREST_PLAN_DETAILS}?region=${
        location === 'PR' ? 'PR' : 'USVI'
      }`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (error) {
    return error.response;
  }
  return result;
};

export const CDSaveOffers = async (data, accessToken) => {
  const formData = {
    Event: 'CaptureDepositInfo',
    Application: {
      ApplicationID: null,
      ...(data.flowTypeETB === 'EtbFlow' && {
        ApplicationFlow: 'ExpressPreAuthentication',
      }),
      TemplateVersion: '2021-08-05',
      Source: {
        Language: data.language,
        Location: data.location,
        UtmSource: data.utmSource,
        UtmMedium: data.utmMedium,
        UtmCampaign: data.utmCampaign,
        UtmContent: data.utmContent,
        UtmTerm: data.utmTerm,
        BranchCode: data.branchCode,
      },
      Products: [
        {
          ProductType: productTypeMapping(data.productType),
          ProductName: productMapping(data.productName),
          Category: 'Primary',
          DepositInfo: {
            DepositAmount: data.depositAmount,
            CDTerm: data.cdTerm,
            APY: data.apy,
            InterestPlan: data.interestPlan,
            APR: data.apr,
          },
        },
      ],
      Processing: {
        CaseReference: [],
      },
    },
  };

  let result = null;
  try {
    result = await axios.post(process.env.REACT_APP_SAVE_SUBMIT_APPLICATION_URL, formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    return error.response;
  }
  return result;
};
