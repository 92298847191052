export const DIRA_ENG = [
  'Having reached required age (60 years old)',
  'Personal Matters',
  'Unemployment',
  'Disability',
  'Purchase or Construction of First Home',
  'Purchase of a computer for the use of a dependent',
  'Death of Participant',
  'Payment of debt for alimony',
  'Repair or Construction of main residence due to Fortuitous Causes',
  'University studies of direct dependents',
  'Treatment of severe, chronic, degenerative or terminal desease',
  'Transfer due to divorce',
  'Foreclousure due to reduction of income',
];

export const DIRA_ESP = [
  'Alcance la edad de retiro (60 años)',
  'Razones Personales',
  'Desempleo',
  'Incapacidad',
  'Compra o Construcción de la primera residencia',
  'Compra de Computadora para dependiente',
  'Muerte del Participante',
  'Pago de Pesión alimenticia',
  'Reparación o Construcción de residencia principal debido a causas fortuitas',
  'Gastos Universitarios de dependientes',
  'Tratamiento de condiciones severas, crónicas, degenerativas o terminales',
  'Transferencia por divorcio',
  'Ejecución de Hipoteca debido a reducción de salario',
];

export const EIRA_ENG = [
  'Pay for the costs of post secondary studies of the beneficiary',
  'Reimbursement due to death or disability of the beneficiary',
  "Reimbursement of funds due to the beneficiary's decision",
];
export const EIRA_ESP = [
  'Pagar costos de estudios post secundarios del beneficiario',
  'Devolución por muerte o incapacidad del beneficiario',
  'Devolución por determinación del beneficiario',
];

export const bankDomains = ['https://orientalbank.com', 'https://obstg2.orientalbank.com'];
export const subDomains = [
  'https://dc-ssp-ui-sandbox.orientalbank.com',
  'https://dc-service-portal-hotfix.orientalbank.com',
  'https://dc-ssp-ui-dev.orientalbank.com',
  'https://dc-service-portal-ui-dev2.orientalbank.com',
  'https://dc-ssp-ui-qa.orientalbank.com',
  'https://dc-service-portal-stag.orientalbank.com',
  'https://servicio.orientalbank.com',
  'https://orientalbank.com',
  'https://obstg2.orientalbank.com',
];
