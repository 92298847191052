import { makeStyles } from '@material-ui/core';
import { COLOR4, WHITE, RADIO_COLOR, ORIENTAL_ORANGE } from '../../constants';

export const SaveContinueModalStyles = () =>
  makeStyles(
    (theme) => ({
      button_classSimpleModal: {
        display: 'flex',
        marginBottom: '75px',
        justifyContent: 'center',
        [theme.breakpoints.down('600')]: {
          marginBottom: '31px',
        },
      },
      simpleCard_above_btn: {
        marginBottom: '41px',
        [theme.breakpoints.down('600')]: {
          marginBottom: '30px',
        },
      },
      simpleCard_below_img: {
        marginTop: '52px',
        [theme.breakpoints.down('600')]: {
          marginTop: '45px',
        },
      },
      imgSize: {
        height: '96px',
        width: '96px',
        [theme.breakpoints.down('600')]: {
          height: '72px',
          width: '72px',
        },
      },
      card_content: {
        textAlign: 'center',
        // padding: '0px !important',
        margin: '0px',
        padding: '41px 16px 0px 16px !important',
        [theme.breakpoints.down('600')]: {
          padding: '50px 16px 0px 16px !important',
        },
      },
      text_One_Space: {
        marginBottom: '30px',
        marginTop: '37px',
        [theme.breakpoints.down('600')]: {
          marginBottom: '28px',
          marginTop: '35px',
        },
      },
      text_Two_Space: {
        marginBottom: '20px',

        [theme.breakpoints.down('600')]: {
          marginBottom: '22px',
        },
      },
      text_Three_Space: {
        marginBottom: '31px',

        [theme.breakpoints.down('600')]: {
          marginBottom: '28px',
        },
      },
      btn_Space: {
        marginBottom: '50px',

        [theme.breakpoints.down('600')]: {
          marginBottom: '30px',
        },
      },
      simple_Modal: {
        display: 'flex',
        marginBottom: '75px',
        justifyContent: 'center',
        [theme.breakpoints.down('600')]: {
          marginBottom: '31px',
        },
      },
      card_content_simple: {
        textAlign: 'center',
        padding: '72px 72px 0px 75px !important',
        margin: '0px',

        [theme.breakpoints.down('600')]: {
          padding: '61px 22px 0px 23px',
        },
      },
      btnAlign: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      saveAndExit_Close: {
        background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
        color: COLOR4,
        letterSpacing: '0.1px',
        font: 'normal 700 14px/16px Arial',
        width: '190px',
        height: '48px',
        padding: '14px 15px',
        opacity: 1,
        textTransform: 'none',
        borderRadius: '23px',
        '&:hover': {
          backgroundColor: RADIO_COLOR,
        },
        [theme.breakpoints.down('600')]: {
          width: '100%',
        },
        '&:focus': {
          outline: `2px solid ${RADIO_COLOR}`,
          outlineOffset: `2px`,
        },
      },
      save_disabled_button: {
        '&.MuiButton-root.Mui-disabled': {
          background: `#E4E9F2`,
          border: `#E4E9F2`,
          borderRadius: '25px',
          color: 'rgba(143, 155, 179, 0.5)',
          width: '190px',
          height: '48px',
          font: 'normal normal bold 16px/18px Arial',
          letterSpacing: '1.01px',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#E4E9F2',
          },
          [theme.breakpoints.down('600')]: {
            width: '100%',
          },
        },
      },
      saveAndExit_Confirm: {
        background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
        color: COLOR4,

        font: 'normal 700 14px/16px Arial',
        width: '169px',
        letterSpacing: '0.1px',
        height: '48px',
        padding: '14px 15px',
        opacity: 1,
        textTransform: 'none',
        borderRadius: '56px',
        '&:hover': {
          backgroundColor: RADIO_COLOR,
        },
        '&:focus': {
          outline: `2px solid ${RADIO_COLOR}`,
          outlineOffset: `2px`,
        },
      },
      back_button: {
        border: `1px solid ${RADIO_COLOR}`,

        opacity: 1,
        borderRadius: '25px',
        maxWidth: '136px',
        maxHeight: '40px',
        padding: '12px 36px',
        margin: '0px 15px 0px 0px',
        font: 'normal 700 14px/16px Arial',
        letterSpacing: '1.01px',
        color: `${RADIO_COLOR} !important`,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: COLOR4,
        },
      },
      button_cancel: {
        width: '100%',
        height: '40px',
        borderRadius: '56px',
        fontStyle: 'normal',

        fontSize: '14px',
        fontWeight: '700',
        lineHeight: '16px',
        textAlign: 'center',
        color: '#661c69',
        textTransform: 'none',
        borderColor: '#661c69',
        outlineColor: '#661c69 ',
        background: '#ffffff',
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,

        display: 'flex',
        color: WHITE,
        justifyContent: 'center',
        alignItems: 'center',
      },
      root: {
        display: 'flex',

        maxWidth: '438px',
        alignItems: 'center',
        maxHeight: '100%',
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(62, 64, 70, 0.08)',

        [theme.breakpoints.down('400')]: {
          maxHeight: 'unset',
        },
      },
      rootConti: {
        display: 'flex',

        maxWidth: '539px',
        alignItems: 'center',
        maxHeight: '100%',
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(62, 64, 70, 0.08)',

        [theme.breakpoints.down('400')]: {
          maxHeight: 'unset',
        },
      },
      rootThankYou: {
        alignItems: 'center',
        display: 'flex',
        maxWidth: '432px',
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(62, 64, 70, 0.08)',
        padding: '35px 36px 40px 36px',
      },
      textStyleConfirmModal: {
        color: '#00000',
        textAlign: 'center',

        fontSize: '24px',
        fontFamily: 'Arial',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
      },
      textOneStyle: {
        fontStyle: 'normal',

        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '18px',
        textAlign: 'center',

        color: '#00000',
      },
      textBalckStyle: {
        color: '#00000',

        fontWeight: '700',
        fontStyle: 'normal',
        fontSize: '16px',
        lineHeight: 'normal',

        textAlign: 'center',
      },
      textOneThankyou: {
        font: 'normal 700 14px/16px Arial',
        textAlign: 'start',

        color: '#00000',
      },
      textNameSubThankyou: {
        textAlign: 'start',
        font: 'normal 400 14px/16px Arial',

        color: '#00000',
      },
      textTwoThankyou: {
        font: 'normal 400 12px/15px Arial',
        textAlign: 'start',

        color: '#00000',
      },
      imageThankyou: {
        margin: '0px 20px 0px 10px',
      },
      modal_close: {
        color: RADIO_COLOR,

        height: '35px',
        textAlign: 'right',
      },
      profilePic: {
        border: '4px solid #E37617',

        height: '90px',
        width: '90px',
        borderRadius: '50%',
        marginRight: '29px',
      },
      imageProfile: {
        height: '90px',
        overflow: ' hidden',
        borderRadius: '50%',

        width: '90px',
      },
      closeOverlayThankyou: {
        top: '42px',

        width: '28px',
        right: '19px',
        cursor: 'pointer',
        height: '28px',
        color: COLOR4,
        position: 'relative',
      },
      closeOverlay: {
        top: '29px',
        height: '28px',
        color: COLOR4,
        width: '28px',
        cursor: 'pointer',

        position: 'relative',
        background: ORIENTAL_ORANGE,
        '&:hover': {
          backgroundColor: ORIENTAL_ORANGE,
        },
      },
    }),
    { index: 1 }
  );
