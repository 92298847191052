import { v4 as uuidv4 } from 'uuid';

const CHATBOTKTC = 'chatbotktc';

export default function GenerateLexUserCookie() {
  if (localStorage.getItem(CHATBOTKTC) != null) {
    return localStorage.getItem(CHATBOTKTC);
  }
  const UUID = uuidv4();
  localStorage.setItem(CHATBOTKTC, UUID);
  return UUID;
}
