import { PropTypes } from 'prop-types';
import { CONNECTING_LIVE_AGENT, HAS_ENDED, HA_TERMINADO, LIVE_AGENT_ICON } from '../../Constants';
import liveAgentIcon from '../images/customer_service_icon.png';
import { CommonStyles } from '../../styles/ChatBotCommonStyles';

const useCommonStyles = CommonStyles();

const ChatBubbleIcon = (props) => {
  const CommoncssStyles = useCommonStyles();

  const { message } = props;
  return (
    <div className={CommoncssStyles.chatboat_bubble}>
      {!message.msgFromLex && !message.isOwn && !message.agentEnterMessage ? (
        <img
          src={liveAgentIcon}
          alt={LIVE_AGENT_ICON}
          className={CommoncssStyles.chatboat_bubble_img}
        />
      ) : (
        !message.isOwn &&
        !message.attachmentFile &&
        !message.message.includes(CONNECTING_LIVE_AGENT) &&
        !message.message.includes(HAS_ENDED) &&
        !message.message.includes(HA_TERMINADO) &&
        !message.agentEnterMessage && (
          <div>
            <svg
              id="Agent_Icon"
              data-name="Agent Icon"
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 35 35"
            >
              <circle
                id="Ellipse_1"
                data-name="Ellipse 1"
                cx="17.5"
                cy="17.5"
                r="17.5"
                fill="#f5821f"
              />
              <circle
                id="Ellipse_2"
                data-name="Ellipse 2"
                cx="9.5"
                cy="9.5"
                r="9.5"
                transform="translate(8 8)"
                fill="#fff"
              />
            </svg>
          </div>
        )
      )}
    </div>
  );
};

ChatBubbleIcon.propTypes = {
  message: PropTypes.object,
};

export default ChatBubbleIcon;
