import { IconButton } from '@livechat/ui-kit';
import { PropTypes } from 'prop-types';
import {
  ESTIMATED_WAIT_TIME_ENG,
  ESTIMATED_WAIT_TIME_ESP,
  GO_BACK_TO_CHATBOT_ENG,
  GO_BACK_TO_CHATBOT_ESP,
  REQUEST_CALL_BACK_ENG,
  REQUEST_CALL_BACK_ESP,
  TRY_SOMETHING_ELSE_ENG,
  TRY_SOMETHING_ELSE_ESP,
  WAIT_FOR_LIVE_AGENT_ENG,
  WAIT_FOR_LIVE_AGENT_ESP,
} from '../../Constants';

const WaitingTimeFlow = (props) => {
  const {
    buttonMessageWaitingForAgentTrigger,
    buttonMessageOnbackToLexTrigger,
    liveAgentTrigger,
    englishBot,
    message,
    pegaConnectTrigger,
  } = props;
  const btn1 = englishBot ? WAIT_FOR_LIVE_AGENT_ENG : WAIT_FOR_LIVE_AGENT_ESP;
  const btn2 = englishBot ? GO_BACK_TO_CHATBOT_ENG : GO_BACK_TO_CHATBOT_ESP;
  const btn3 = englishBot ? REQUEST_CALL_BACK_ENG : REQUEST_CALL_BACK_ESP;
  const btn2response = englishBot ? TRY_SOMETHING_ELSE_ENG : TRY_SOMETHING_ELSE_ESP;
  const backToChatbot = () => {
    liveAgentTrigger();
    const status = false;
    // setTimeout(() => {
    buttonMessageOnbackToLexTrigger(btn2response, status);
    // }, 5000);
  };
  const showWaitingTime = () => {
    // liveAgentTrigger();
    // setTimeout(() => {
    const status = true;
    buttonMessageWaitingForAgentTrigger(btn1, status);
    // }, 5000);
  };
  return (
    <div
      className="quick-replies"
      style={{ display: 'block', justifyContent: 'left', textAlign: 'left' }}
    >
      <IconButton onClick={showWaitingTime} tabIndex="102" data-testid="btn_testid1">
        {btn1}
      </IconButton>
      <IconButton onClick={backToChatbot} tabIndex="102" data-testid="btn_testid2">
        {btn2}
      </IconButton>
      {(message.includes(ESTIMATED_WAIT_TIME_ENG) || message.includes(ESTIMATED_WAIT_TIME_ESP)) && (
        <IconButton onClick={pegaConnectTrigger} tabIndex="102" data-testid="btn_testid3">
          {btn3}
        </IconButton>
      )}
    </div>
  );
};

WaitingTimeFlow.propTypes = {
  englishBot: PropTypes.bool,
  buttonMessageWaitingForAgentTrigger: PropTypes.func,
  buttonMessageOnbackToLexTrigger: PropTypes.func,
  liveAgentTrigger: PropTypes.func,
  message: PropTypes.array,
  pegaConnectTrigger: PropTypes.func,
};

export default WaitingTimeFlow;
