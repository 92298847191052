import { makeStyles } from '@material-ui/core';
import { COLOR2 } from '../../constants';

export const ReturnToSavedApplicationStyles = makeStyles(
  (theme) => ({
    mainContainer: {
      position: 'relative',
      marginBottom: '6px',
      marginTop: '30px',
      [theme.breakpoints.down('600')]: {
        marginBottom: '20px',
      },
    },
    imageGrid: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'white',
    },

    formContainer: {
      width: '454px',
      borderRadius: '10px',
      background: '#FFFFFF',
      padding: '30px',
      margin: '0 auto 65px auto',
      [theme.breakpoints.down('600')]: {
        width: '100%',
        margin: '0 0 30px 0',
        borderRadius: 0,
        padding: '20px 20px 0 20px',
      },
    },

    snackbarRoot: {
      width: '100%',
      height: '56px',
      position: 'relative',
      disaplay: 'flex',
      justifyContent: 'flex-end',

      [theme.breakpoints.down('1310')]: {
        height: 'auto',
      },
      [theme.breakpoints.down('600')]: {
        left: 'unset',
        right: 'unset',
      },
    },
    snackbarPosition: {
      position: 'absolute !important',
      top: '25px',
      zIndex: 1,
    },
    snackbarPositionMobile: {
      position: 'absolute !important',
      top: '25px',
      zIndex: 1,
      [theme.breakpoints.down('600')]: {
        top: 0,
      },
    },
    snackbarReturnRoot: {
      width: '100%',
      height: '56px',
      position: 'relative',
      disaplay: 'flex',
      justifyContent: 'flex-start',
    },
    snackBarBtn: {
      padding: '0px',
      // height: '20px',
      color: '#4F4F4F',
    },
    snackbarRetunStyleViaContentProps: {
      backgroundColor: '#FCF1E8',
      border: '2px solid #F5821F',
      color: '#141414',
      font: 'normal 400 16px/120% Arial',
      maxWidth: '500px',
      position: 'relative',
      left: '30px',
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%',
      [theme.breakpoints.down('995')]: {
        maxWidth: '75%',
      },
      [theme.breakpoints.down('600')]: {
        left: '-6px',
        maxWidth: '100%',
      },
    },
    snackbarStyleViaContentProps: {
      color: '#D71721',
      borderRadius: '6px',
      border: '2px solid #D71721',
      backgroundColor: '#FFF2F2',
      width: '100%',

      fontFamily: 'Arial',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '120%',

      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'nowrap',
      minWidth: 'unset',
    },

    message: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      '&.MuiSnackbarContent-message': {
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingRight: '0px',
        paddingLeft: '0px',
      },
    },
    dd: {
      '&.MuiSnackbarContent-message': {
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingRight: '0px',
        paddingLeft: '0px',
      },
    },
    gridStyles: {
      marginBottom: '20px',
    },
    error_message: {
      font: 'normal normal normal 14px/16px Arial',
      letterSpacing: '0.1px',
      color: COLOR2,
      opacity: 1,
      position: 'relative',
      top: '3px',
      display: 'block',
    },
    feeWaivedText: {
      fontStyle: 'Arial',
      marginTop: '30px',
      display: 'flex',
      gap: '13px',
    },
    feeWaivedTextNew: {
      fontStyle: 'Arial',
      marginTop: '20px',
      display: 'flex',
      gap: '13px',
    },
    imageIcon: {
      [theme.breakpoints.up('1432')]: {
        marginTop: '15px',
      },
    },
    heading: {
      fontFamily: 'Arial',
      fontSize: '36px',
      fontWeight: 700,
      textAlign: 'center',
      marginTop: '50px',
      marginBottom: '5px',
      color: '#000000',
    },
    sub_heading: {
      fontFamily: 'Arial',
      fontSize: '16px',
      fontWeight: 400,
      marginBottom: '30px',
      textAlign: 'center',
      color: '#000000',
    },
  }),
  { index: 1 }
);
