/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Card, CardContent, Typography, Modal, IconButton, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CommercialModalStyles } from '../../CommercialDeposit/CommercialModalStyles';
import { KNOCK_OFF_MSSG_HEAD } from '../../../constants';
import CloseIconIMG from '../../../images/Icon.svg';

const useStyles = CommercialModalStyles();

const KnockOffModal = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    props.setIsModal(false);
    setOpen(false);
  };
  useEffect(() => {
    props.openModal ? setOpen(true) : setOpen(false);
  }, [props.openModal]);

  return (
    <Modal
      className={classes.backdrop}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(233, 233, 235, 0.78)',
        },
      }}
      open={open}
      role="dialog"
      aria-describedby="dialog1BusinessDesc dialog2BusinessDesc"
    >
      <div style={{ outline: 'none' }}>
        {!props.message.hideClose && (
          <Grid container style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              src={CloseIconIMG}
              name="closeicon"
              onClick={handleClose}
              tabIndex="0"
              data-testid="close_id"
              id="businessAddressEdit"
              disableRipple
              className={classes.closeOverlay}
            >
              <img
                alt={t(`close`, { defaultValue: 'close' })}
                tabIndex="0"
                src={CloseIconIMG}
                width="24px"
                height="24px"
              />
            </IconButton>
          </Grid>
        )}
        <Grid container>
          <Card className={classes.root}>
            <CardContent className={classes.card_content}>
              {props.message.isKnockOff && !props.message.linkMsg && (
                <Typography className={classes.textOneStyle} id="dialog1BusinessDesc">
                  {t(KNOCK_OFF_MSSG_HEAD, { defaultValue: KNOCK_OFF_MSSG_HEAD })}
                </Typography>
              )}
              {props.message.linkMsg && (
                <Typography className={`${classes.inviTwoStyle}`} id="dialog2BusinessDesc">
                  {t(props.message.messageKey, {
                    defaultValue: props.message.messageTwo,
                    nsSeparator: '|',
                  })}
                </Typography>
              )}
              <Typography
                className={`${
                  props.message.isKnockOff ? classes.textTwoStyleKockOff : classes.textTwoStyle
                }`}
                id="dialog2BusinessDesc"
              >
                {t(props.message.message, {
                  defaultValue: props.message.message,
                  nsSeparator: '|',
                })}
              </Typography>
              {!props.message.isKnockOff && (
                <Button
                  disableFocusRipple
                  autoFocus
                  className={classes.saveAndExit}
                  data-testid="CloseButtonInBusinessModal"
                  onClick={handleClose}
                >
                  {t('Continue', { defaultValue: 'Continue' })}
                </Button>
              )}
            </CardContent>
          </Card>
        </Grid>
      </div>
    </Modal>
  );
};
export default withRouter(KnockOffModal);

KnockOffModal.propTypes = {
  setIsModal: PropTypes.func,
  openModal: PropTypes.bool,
  message: PropTypes.object,
};
