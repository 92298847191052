/* eslint-disable react/no-unused-state */
/* eslint-disable max-lines */
/* eslint-disable no-restricted-syntax */
import * as React from 'react';
import './chatbot.css';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { isMobile, isAndroid, isIOS, isWindows } from 'react-device-detect';
import { ThemeProvider, FixedWrapper } from '@livechat/ui-kit';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import Minimized from './chat/Minimized';
import {
  defaultCustomerData,
  themeCSS,
  intentArrWithPegaTrue,
  intentArrWithPegaFalse,
} from './ChatBotData';
import {
  base64Authorization,
  getParameters,
  timeConvert,
  toggleChatbotContainerSize,
  deleteSessioApiCall,
  focusInChatbot,
} from './CommonFunction';
import {
  CONNECTING_TO_LIVR_AGENT_ENG,
  CONNECTING_TO_LIVR_AGENT_ESP,
  CONTACT_US_ENG,
  CONTACT_US_ESP,
  ENG,
  ESP,
  ESTIMATED_WAIT_TIME_IS,
  ESTIMATED_WAIT_TIME_IS_ESP,
  LIVE_AGENT_CHAT_ENDED_ENG,
  LIVE_AGENT_CHAT_ENDED_ESP,
  MORE_THAN_FIVE_ENG,
  MORE_THAN_FIVE_ESP,
  NEED_ANYTHING_ELSE_ENG,
  NEED_ANYTHING_ELSE_ESP,
  SHOW_FEEDBACK,
  SOMETHING_WENT_WRONG_ENG,
  SOMETHING_WENT_WRONG_ESP,
  SORRY_AGENTS_ARE_BUSY_ENG,
  SORRY_AGENTS_BUSY_ESP,
  TRY_AGAIN_LATER_ENG,
  TRY_AGAIN_LATER_ESP,
  WAIT_FOR_LIVE_AGENT_ENG,
  WAIT_FOR_LIVE_AGENT_ESP,
} from './Constants';
import LeadGenerationContext from '../../context/LeadGenerationContext';
import Maximized from './chat/Maximized';
import { PostLextApi, setChatBotIntents } from './utils/CommonFunctions';

const urlParams = new URLSearchParams(window.location.search);
const browserLanguage = urlParams.get('dc_language');
const SspUserLogin = urlParams.get('userLogin');
const chatIntent = urlParams.get('chat_intent');
const SspSiteFlagValue = urlParams.get('showIntentAsString');
const customerGuid = urlParams.get('customerGuid');
const dobAtLogin = urlParams.get('dobAtLogin');
const deviceType = isMobile ? 'Mobile' : 'Desktop';
let deviceOS = '';
if (isMobile && isAndroid) {
  deviceOS = 'Android';
} else if (isMobile && isIOS) {
  deviceOS = 'IOS';
} else {
  deviceOS = '';
}

const customerChatSession = {};
const cusData = defaultCustomerData;
const intialWaitingTime = 0;

// const { language } = useContext(LeadGenerationContext);

class ChatBot extends React.Component {
  static contextType = LeadGenerationContext;
  theme = themeCSS;
  constructor(props) {
    super(props);
    const { language } = this.props;
    // const { language } = this.context;
    this.child = React.createRef();
    this.state = {
      messages: [],
      countriesList: [],
      englishBot: language !== 'ES',
      originalBotLanguage: language !== 'ES',
      btnIntentTrigger: chatIntent || '',
      lexError: false,
      sessionID: '',
      userEmail: '',
      pegaIntent: false,
      defaultTextContent: '',
      chatDeviceType: deviceType !== '' ? deviceType : '',
      chatDeviceOS: isWindows ? 'Windows' : deviceOS,
      popupRunning: false,
      slotToElicit: '',
      liveAgent: false,
      liveAgentJoined: false,
      liveAgentDisconnected: false,
      waitingForLiveAgent: false,
      allowUploadDoc: false,
      agentTypingFlag: false,
      awsConnectAgentName: '',
      customerDetails: {},
      askForTimeAgain: false,
      hideOnCustomerType: false,
      verfiedCustomer: false,
      connectionSucessful: false,
      gotGreeting: false,
      reConnect: false,
      formInProcess: false,
      spinnerTrigger: false,
      cardValidFlag: false,
      sspSiteFlag: SspSiteFlagValue,
      attachmentLoader: false,
      postLexToken: '',
      iraReasonsList: [],
      isEncryptionNeeded: false,
    };

    console.log({ check: this.props });
    // i18n.changeLanguage(language === 'ES' ? ESP : ENG);
  }
  componentDidMount() {
    console.log({ context: this.context, windoow: window.location });

    window.addEventListener('message', (event) => {
      if (!event.origin.includes('.orientalbank.com')) return;
      if (typeof event.data === 'object' && 'chat_intent' in event.data) {
        this.setState({
          btnIntentTrigger: event.data.chat_intent,
          sspSiteFlag: event.data.showIntentAsString,
        });
      }
      if (
        event?.data !== 'undefined' &&
        !event?.data?.includes('[iFrameSizer]') &&
        'chat_intent' in event.data === false
      ) {
        this.setState({ btnIntentTrigger: event.data });
      }
    });
    this.intervalId = window.setInterval(() => {
      let message = {};
      const { messages, customerDetails, liveAgent, askForTimeAgain, connectionSucessful } =
        this.state;
      if (messages.length > 0) {
        message = messages[messages.length - 1];
        const diff = moment().diff(moment(message.messageDate), 'seconds');
        this.wrapUpcallFunction(diff, message);
        if (
          (message.message.includes(CONNECTING_TO_LIVR_AGENT_ENG) ||
            message.message.includes(CONNECTING_TO_LIVR_AGENT_ESP)) &&
          !liveAgent &&
          !connectionSucessful
        ) {
          this.setState({
            waitingForLiveAgent: true,
            connectionSucessful: true,
            spinnerTrigger: false,
          });
          // this.deleteSessionToLex();
          this.AWSConnectInit(customerDetails);
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }
  handleEnglishBot = (isEngBot) => {
    this.setState({
      englishBot: isEngBot,
    });
  };
  handlePostLexToken = (token) => {
    this.setState({ postLexToken: token });
  };
  handleCountriesList = (dataList) => {
    const { countriesList } = this.state;
    this.setState({
      countriesList: dataList,
    });
  };

  wrapUpcallFunction = (diff, message) => {
    const { englishBot, lexError, popupRunning, liveAgentJoined, liveAgent, formInProcess } =
      this.state;
    console.log({ diff });
    if (diff > 60 && !popupRunning && !formInProcess && !liveAgentJoined) {
      if (this.child.current !== null) {
        this.child.current.closeChatbot();
      }
    }
  };

  handleLanguageChange = (chatLanguage, intentOrClick) => {
    let englishBotStatus = true;
    if (chatLanguage === ENG) {
      englishBotStatus = true;
    } else {
      englishBotStatus = false;
    }
    // getCountriesList(englishBotStatus, this.handleCountriesList);
    const { i18n } = this.props;
    const { setLanguage } = this.context;
    if (intentOrClick === 'click') {
      i18n.changeLanguage(chatLanguage);
      const lang = chatLanguage === ENG ? 'EN' : 'ES';
      setLanguage(lang);
    }
    setTimeout(() => {
      this.setState({
        englishBot: englishBotStatus,
        defaultTextContent: localStorage.getItem('textContent')
          ? localStorage.getItem('textContent')
          : '',
      });
    }, 1000);
  };

  handlePopupRunningCase = (flag) => {
    const { popupRunning } = this.state;
    this.setState(() => ({ popupRunning: flag }));
    toggleChatbotContainerSize(popupRunning);
  };

  askingForWaitingTime = () => {
    this.setState({ askForTimeAgain: true });
  };

  formInProcessTrigger = (flag) => {
    this.setState({ formInProcess: flag });
  };

  spinnerHandler = (flag) => {
    this.setState({ spinnerTrigger: flag });
  };

  ReconnectToAwsConnect = () => {
    this.AWSConnectInit(cusData);
  };

  setReconnectionStatus = () => {
    this.setState({ reConnect: true });
  };

  AWSConnectInit = async (params) => {
    // let agentTransferred = false;
    // let agentName = '';
    await PostLextApi(
      params,
      this.lexSuccessHandler,
      this.lexFailedHandler,
      this.handlePostLexToken,
      this.handlePostLexCatch
    );
  };

  handleEncryptedDataNeeded = (value) => {
    this.setState({
      isEncryptionNeeded: value,
    });
  };

  handleNewUserMessage = (newMessage, ownMessage) => {
    const {
      messages,
      liveAgent,
      englishBot,
      liveAgentDisconnected,
      agentTypingFlag,
      sspSiteFlag,
      btnIntentTrigger,
    } = this.state;
    const message = englishBot ? LIVE_AGENT_CHAT_ENDED_ENG : LIVE_AGENT_CHAT_ENDED_ESP;
    if (messages.length === 0) {
      // if(!(newMessage.text === 'PEGA_CONNECT'))
      if (sspSiteFlag === 'true' || sspSiteFlag === true) {
        this.setState({
          messages: [
            ...messages,
            {
              message: newMessage.text,
              isOwn: true,
              msgForAgent: false,
              readStatus: false,
              sentFailedStatus: false,
              showYesNo: newMessage.showYesNo,
              messageDate: new Date(),
            },
            {
              message: '',
              isOwn: ownMessage,
              showYesNo: false,
            },
          ],
        });
      } else {
        this.setState({
          messages: [
            ...messages,
            {
              message: '',
              isOwn: ownMessage,
              msgForAgent: liveAgent,
              readStatus: false,
              sentFailedStatus: false,
              messageDate: new Date(),
              showYesNo: false,
            },
          ],
        });
      }
    } else if (newMessage.value === message) {
      if (!liveAgentDisconnected) {
        this.setState({
          messages: [
            ...messages,
            {
              message: newMessage.text,
              isOwn: ownMessage,
              msgForAgent: liveAgent,
              readStatus: false,
              sentFailedStatus: false,
              showYesNo: newMessage.showYesNo,
              messageDate: new Date(),
            },
          ],
        });
      }
    } else if (newMessage.text !== 'PEGA_CONNECT') {
      liveAgent
        ? this.setState({
            messages: [
              ...messages,
              {
                message: newMessage.text,
                isOwn: ownMessage,
                msgForAgent: liveAgent,
                readStatus: !!agentTypingFlag,
                sentFailedStatus: false,
                showYesNo: newMessage.showYesNo,
                messageDate: new Date(),
              },
            ],
          })
        : this.setState({
            messages: [
              ...messages,
              {
                message: newMessage.text,
                isOwn: ownMessage,
                msgForAgent: liveAgent,
                readStatus: false,
                sentFailedStatus: false,
                showYesNo: newMessage.showYesNo,
                messageDate: new Date(),
              },
              {
                message: '',
                isOwn: ownMessage,
                showYesNo: false,
              },
            ],
          });
    } else {
      this.setState({
        messages: [
          ...messages,
          {
            message: '',
            isOwn: ownMessage,
            msgForAgent: liveAgent,
            readStatus: false,
            sentFailedStatus: false,
            showYesNo: false,
            messageDate: new Date(),
          },
        ],
      });
    }
    let isServiceCase = false;
    if (
      newMessage.type ||
      newMessage.value === 'search_by_ssn_again' ||
      newMessage.value.includes('*LoadMore')
    ) {
      isServiceCase = true;
    }
    if (liveAgent) {
      this.sendToConnect(newMessage.value);
    } else if (newMessage.value !== message) {
      setTimeout(() => {
        this.sendToLex(newMessage.value, isServiceCase);
      }, 200);
    }
  };

  handleWrapupMessage = (newMessage, ownMessage, customData = {}) => {
    const { messages, liveAgent } = this.state;
    this.setState({
      messages: [
        ...messages,
        {
          message: newMessage.text,
          isOwn: ownMessage,
          msgForAgent: liveAgent,
          msgFromLex: true,
          readStatus: false,
          sentFailedStatus: false,
          showYesNo: newMessage.showYesNo,
          showWrapupStepTwo: newMessage.showWrapupStepTwo,
          messageDate: newMessage.messageDate,
          ...customData,
        },
      ],
    });
  };

  handeLanguageChangeMessage = (newMessage, ownMessage) => {
    const { messages, englishBot, liveAgent } = this.state;
    this.setState({
      messages: [
        ...messages,
        {
          message: newMessage.text,
          isOwn: ownMessage,
          chatLanguage: englishBot ? ENG : ESP,
          msgForAgent: liveAgent,
          msgFromLex: true,
          readStatus: true,
          sentFailedStatus: false,
          languageChangeYesNo: newMessage.languageChangeYesNo,
        },
      ],
    });
  };

  handeButtonLinkMessage = (newMessage, ownMessage) => {
    const { messages, liveAgentJoined } = this.state;
    this.setState({
      messages: [
        ...messages,
        {
          message: newMessage.text,
          isOwn: ownMessage,
          msgFromLex: !liveAgentJoined,
          readStatus: true,
          sentFailedStatus: false,
          isButtonMessage: newMessage.isButtonMessage,
          qrCode: newMessage.qrCode,
          messageDate: new Date(),
        },
      ],
    });
  };

  handleButtonClickDuringAgentCallMessage = (newMessage, ownMessage) => {
    const { messages, liveAgent, liveAgentJoined } = this.state;
    this.setState({
      messages: [
        ...messages,
        {
          message: newMessage?.text,
          isOwn: ownMessage,
          msgForAgent: liveAgent,
          msgFromLex: !liveAgentJoined,
          readStatus: false,
          sentFailedStatus: false,
          showYesNo: newMessage?.showYesNo,
          showWrapupStepTwo: newMessage?.showWrapupStepTwo,
          messageDate: newMessage?.messageDate,
        },
      ],
    });
  };

  setUserEmailFromIntent = (email) => {
    this.setState({ userEmail: email });
  };

  setVerifedCustomer = (flag) => {
    this.setState({ verfiedCustomer: flag });
  };

  handlerPageIntent = () => {
    this.setState({ pegaIntent: false });
  };

  handlerIntentTrigger = () => {
    this.setState({ btnIntentTrigger: '' });
  };

  setCustomerDetails = (data) => {
    this.setState({ customerDetails: data });
  };

  handlePostLexCatch = (err) => {
    const { messages } = this.state;
    const oldMessages = [...messages];
    if (oldMessages.length > 0 && oldMessages[oldMessages.length - 1].message === '') {
      oldMessages.splice(-1, 1);
    }
    const newMessages = oldMessages;
    setTimeout(() => {
      this.setState({ messages: newMessages }, () => {
        this.lexFailedHandler(err);
      });
    }, 100);
  };
  sendToLex = async (message, isServiceCase, uuid, afterPostLexCall) => {
    const {
      slotToElicit,
      englishBot,
      sessionID,
      chatDeviceType,
      chatDeviceOS,
      isEncryptionNeeded,
    } = this.state;
    const { language, currentPage } = this.context;

    console.log({ englishBot, language });

    const botLang = language === 'EN';
    // const intentMessage = setChatBotIntents(currentPage);
    const params = getParameters(
      message,
      slotToElicit,
      englishBot,
      sessionID,
      chatDeviceType,
      chatDeviceOS,
      SspUserLogin,
      uuid,
      isEncryptionNeeded,
      customerGuid,
      dobAtLogin
    );
    await PostLextApi(
      params,
      this.lexSuccessHandler,
      this.lexFailedHandler,
      this.handlePostLexToken,
      this.handlePostLexCatch
    );
  };

  lexSuccessHandler = (data, isServiceCase) => {
    const { messages } = this.state;
    if (data) {
      this.spinnerHandler(false);
      let mesg = {};
      if (data.sessionState.sessionAttributes.validDebitCard === 'true') {
        this.setState({ cardValidFlag: true });
      } else {
        this.setState({ cardValidFlag: false });
      }
      if (data.messages && data.messages[0].content.includes('templateType')) {
        const listPickerData = JSON.parse(data.messages[0].content);
        this.setState({ sessionID: listPickerData.data.content.sessionId });
        if (listPickerData.data.content.intentName !== null) {
          if (
            listPickerData.data.content.intentName === 'CB_LiveAgent_Intent' ||
            listPickerData.data.content.intentName === 'CB_LiveAgent_Intent_esp'
          ) {
            this.setState({ pegaIntent: true });
          } else if (
            intentArrWithPegaTrue.includes(listPickerData.data.content.intentName) &&
            listPickerData.data.content.slotToElicit &&
            listPickerData.data.content.slotToElicit !== null
          ) {
            this.setState({ pegaIntent: true });
            this.setState({ slotToElicit: listPickerData.data.content.slotToElicit });
          } else if (
            intentArrWithPegaFalse.includes(listPickerData.data.content.intentName) &&
            listPickerData.data.content.slotToElicit &&
            listPickerData.data.content.slotToElicit !== null
          ) {
            this.setState({ slotToElicit: listPickerData.data.content.slotToElicit });
          } else {
            this.setState({ pegaIntent: false });
            this.setState({ slotToElicit: '' });
          }
        }
        if (listPickerData.data.content.iraReasonsList !== null) {
          this.setState({ iraReasonsList: listPickerData.data.content.iraReasonsList });
        }
        if (listPickerData.data.content.title.includes('Your request is processing.')) {
          this.handleNewUserMessage({ text: 'Inprogress', value: 'Inprogress' }, true);
        } else if (
          listPickerData.data.content.title !== 'Thank you!' &&
          listPickerData.data.content.title !== '¡Gracias!'
        ) {
          mesg = {
            message: listPickerData.data.content.title,
            msgFromLex: true,
            isOwn: false,
            intentName: listPickerData.data.content.intentName,
            slotToElicit: listPickerData.data.content.slotToElicit,
            messageDate: new Date(),
          };
          if (listPickerData.data.content.elements[0].title !== ' ') {
            mesg.responseCard = listPickerData.data.content.elements;
            mesg.responseCard.isServiceCase = isServiceCase;
          }
        }
      } else {
        mesg = {
          message: data.Content,
          isOwn: false,
          msgFromLex: true,
          messageDate: new Date(),
        };
      }
      const oldMessages = [...messages];
      if (oldMessages.length > 0 && oldMessages[oldMessages.length - 1].message === '') {
        oldMessages.splice(-1, 1);
      }
      const newMessages = oldMessages;
      if (Object.keys(mesg).length !== 0) {
        newMessages.push(mesg);
      }
      setTimeout(() => {
        this.setState({ messages: newMessages, isEncryptionNeeded: false }, () => {
          focusInChatbot();
        });
      }, 100);
    }
  };

  lexFailedHandler = (err) => {
    const { englishBot, messages } = this.state;
    if (err.message === 'Request failed with status code 504') {
      this.handleNewUserMessage({ text: 'Inprogress', value: 'Inprogress' }, true);
      return;
    }
    if (err && err.message !== 'Request failed with status code 504') {
      const botMessage = englishBot ? TRY_AGAIN_LATER_ENG : TRY_AGAIN_LATER_ESP;
      const mesg = {
        message: botMessage,
        msgFromLex: true,
        isOwn: false,
        messageDate: new Date(),
      };
      const buttonText = englishBot ? CONTACT_US_ENG : CONTACT_US_ESP;
      const buttonValue = englishBot
        ? 'https://orientalbank.com/en/contactus/'
        : 'https://orientalbank.com/es/contactanos/';
      mesg.responseCard = [{ title: buttonText, subtitle: buttonValue }];
      const newMessages = [...messages];
      if (
        newMessages[newMessages.length - 1] &&
        newMessages[newMessages.length - 1].message === ''
      ) {
        newMessages.splice(-1, 1);
      }
      newMessages.push(mesg);
      this.setState({ messages: newMessages, lexError: true, isEncryptionNeeded: false }, () => {
        focusInChatbot();
      });
    } else {
      this.setState({ lexError: false, isEncryptionNeeded: false });
    }
  };

  deleteSessionToLex = () => {
    const { englishBot, sessionID } = this.state;
    deleteSessioApiCall(englishBot, sessionID);
    this.setState({ sessionID: '', spinnerTrigger: false });
  };

  sendToConnect = async (message) => {
    this.setState({ hideOnCustomerType: false });
    await customerChatSession.controller.sendMessage({
      message,
      contentType: 'text/plain',
      options: {
        waitInQ: message,
      },
    });
  };

  addMessages = (data) => {
    const { messages } = this.state;
    this.setState({
      messages: [...messages, { message: data, isOwn: true }, { message: '', isOwn: false }],
    });
    this.sendToLex(data);
  };

  clearMessage = () => {
    const { originalBotLanguage } = this.state;
    this.setState({
      englishBot: originalBotLanguage,
      messages: [],
      pegaIntent: false,
      slotToElicit: '',
      btnIntentTrigger: '',
    });
  };
  handleClearMessages = (newMessages) => {
    this.setState({ messages: newMessages });
  };
  handleMessages = (newMessages, err) => {
    this.setState({ messages: newMessages }, () => {
      this.lexFailedHandler(err);
    });
  };
  handleLanguage = (lang) => {
    const { i18n } = this.props;
    this.setState({
      englishBot: lang !== 'ES',
      originalBotLanguage: lang !== 'ES',
    });
    // i18n.changeLanguage(lang === 'ES' ? ESP : ENG);
  };
  render() {
    const {
      sessionID,
      reConnect,
      gotGreeting,
      connectionSucessful,
      btnIntentTrigger,
      verfiedCustomer,
      userEmail,
      hideOnCustomerType,
      liveAgentJoined,
      agentTypingFlag,
      customerDetails,
      awsConnectAgentName,
      allowUploadDoc,
      liveAgent,
      waitingForLiveAgent,
      pegaIntent,
      slotToElicit,
      defaultTextContent,
      englishBot,
      lexError,
      messages,
      countriesList,
      spinnerTrigger,
      cardValidFlag,
      sspSiteFlag,
      iraReasonsList,
      chatDeviceType,
      chatDeviceOS,
      uuid,
      isEncryptionNeeded,
    } = this.state;
    const { openInactivityModal } = this.context;
    return (
      <ThemeProvider theme={this.theme}>
        <div role="main">
          <FixedWrapper.Root>
            <FixedWrapper.Maximized
              className={`main-wrapper ${deviceOS === 'IOS' ? 'device-iphone' : ''}`}
            >
              <Maximized
                ref={this.child}
                state={this.state}
                lexSuccessHandler={this.lexSuccessHandler}
                lexFailedHandler={this.lexFailedHandler}
                handlePostLexToken={this.handlePostLexToken}
                handlePostLexCatch={this.handlePostLexCatch}
                SspUserLogin={SspUserLogin}
                customerGuid={customerGuid}
                setState={this.setState}
                afterPostLexCall={this.afterPostLexCall}
                params={this.params}
                sessionID={sessionID}
                cardValidFlag={cardValidFlag}
                sendToLex={this.sendToLex}
                setReconnectionStatus={this.setReconnectionStatus}
                reConnect={reConnect}
                gotGreeting={gotGreeting}
                spinnerTrigger={spinnerTrigger}
                connectionSucessful={connectionSucessful}
                btnIntentTrigger={btnIntentTrigger}
                verfiedCustomer={verfiedCustomer}
                setVerifedCustomer={this.setVerifedCustomer}
                userEmail={userEmail}
                setUserEmailFromIntent={this.setUserEmailFromIntent}
                hideOnCustomerType={hideOnCustomerType}
                sendTypingEventToConnect={this.sendTypingEventToConnect}
                liveAgentJoined={liveAgentJoined}
                askingForWaitingTime={this.askingForWaitingTime}
                handleButtonClickDuringAgentCallMessage={
                  this.handleButtonClickDuringAgentCallMessage
                }
                agentTypingFlag={agentTypingFlag}
                customerDetails={customerDetails}
                awsConnectAgentName={awsConnectAgentName}
                allowUploadDoc={allowUploadDoc}
                runningAWSConnect={liveAgent}
                waitingForLiveAgent={waitingForLiveAgent}
                sendDocToConnect={this.sendDocToConnect}
                ReconnectToAwsConnect={this.ReconnectToAwsConnect}
                handlerLiveAgentTrigger={this.handlerLiveAgentTrigger}
                pegaIntent={pegaIntent}
                slotToElicit={slotToElicit}
                handlerPageIntent={this.handlerPageIntent}
                handlerIntentTrigger={this.handlerIntentTrigger}
                defaultTextareaContent={defaultTextContent}
                englishBot={englishBot}
                lexError={lexError}
                messages={messages}
                countriesList={countriesList}
                sspSiteFlag={sspSiteFlag}
                handleCountriesList={this.handleCountriesList}
                deleteSessionToLex={this.deleteSessionToLex}
                handleLanguageChange={this.handleLanguageChange}
                handleMessage={this.handleNewUserMessage}
                handleWrapupMessage={this.handleWrapupMessage}
                handlePopupRunningCase={this.handlePopupRunningCase}
                handeLanguageChangeMessage={this.handeLanguageChangeMessage}
                handeButtonLinkMessage={this.handeButtonLinkMessage}
                handleDateMessage={this.handleDateMessage}
                addMessages={this.addMessages}
                uploadDocuments={this.uploadDocuments}
                cancelUploadDocuments={this.cancelUploadDocuments}
                onCloseClick={this.clearMessage}
                formInProcessTrigger={this.formInProcessTrigger}
                setCustomerDetails={this.setCustomerDetails}
                spinnerHandler={this.spinnerHandler}
                sendToConnect={this.sendToConnect}
                // eslint-disable-next-line react/destructuring-assignment
                attachmentLoader={this.state.attachmentLoader}
                iraReasonsList={iraReasonsList}
                handleEncryptedDataNeeded={this.handleEncryptedDataNeeded}
                handleMessages={this.handleMessages}
                handleClearMessages={this.handleClearMessages}
                handleLanguage={this.handleLanguage}
                isModalOpen={openInactivityModal}
                chatDeviceType={chatDeviceType}
                chatDeviceOS={chatDeviceOS}
                uuid={uuid}
                isEncryptionNeeded={isEncryptionNeeded}
                dobAtLogin={dobAtLogin}
                handleEnglishBot={this.handleEnglishBot}
                {...this.props}
              />
            </FixedWrapper.Maximized>
            <FixedWrapper.Minimized className="main-wrapper-minimize">
              <Minimized
                {...this.props}
                btnIntentTrigger={btnIntentTrigger}
                handlerIntentTrigger={this.handlerIntentTrigger}
              />
            </FixedWrapper.Minimized>
          </FixedWrapper.Root>
        </div>
      </ThemeProvider>
    );
  }
}
export default withTranslation()(ChatBot);

ChatBot.propTypes = {
  i18n: PropTypes.object,
  language: PropTypes.string,
};
