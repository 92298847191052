import {
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ORIENTAL_ORANGE, REQUIRED, VALIDATION_BACKSPACE } from '../../../constants';
import showIcon from '../../../images/Show_Eye.svg';
import hideIcon from '../../../images/hideIconssn.svg';
import { CommonStyles } from '../CommonStyles';
import { commonTernary } from '../../commonCode';
import LeadGenerationContext from '../../../context/LeadGenerationContext';
import CustomTooltip from '../../Tooltip/CustomTooltip';

export const SSN = (props) => {
  const { t } = useTranslation();
  const commonStyles = CommonStyles();
  const [ssnVal, setSsnVal] = useState('true');
  const [ssnFakeValue, setSsnFakeValue] = useState('');
  const [showHideIcon, setShowHideIcon] = useState(hideIcon);
  const [ssnAltText, setSsnAltText] = useState(hideIcon);
  const leadGenerationContext = useContext(LeadGenerationContext);

  const { handleKeyDown, error, ssn, isCommercialPage, id, disabled, isFlowTypeETB, whySSN } =
    props;

  const showHideHandler = (e) => {
    e.preventDefault();
    setSsnVal((ssnValue) => !ssnValue);
    const icon = showHideIcon === hideIcon ? showIcon : hideIcon;
    setShowHideIcon(icon);
  };

  React.useEffect(() => {
    const len = ssn?.length;
    let stars;
    switch (len) {
      case 1:
        stars = 'X';
        break;
      case 2:
        stars = 'XX';
        break;
      case 3:
        stars = 'XXX';
        break;
      case 4:
        stars = 'XXX-';
        break;
      case 5:
        stars = 'XXX-X';
        break;
      case 6:
        stars = 'XXX-XX';
        break;
      case 7:
        stars = 'XXX-XX-';
        break;
      case 0:
        stars = '';
        break;
      default:
        stars = 'XXX-XX-';
        break;
    }
    const ssnSubString = ssn?.substring(7);
    const result = stars + ssnSubString;
    setSsnFakeValue(result);
  }, [ssn]);

  React.useEffect(() => {
    if (showHideIcon === hideIcon) {
      setSsnAltText('Show SSN number');
    } else setSsnAltText('Hide SSN number');
  }, [showHideIcon]);

  const handleSSNValue = (currentKey, selectionStart, selectionEnd) => {
    const startSubstring = ssn?.substring(0, selectionStart);
    const endSubstring = ssn?.substring(selectionEnd);
    const enterValue = startSubstring + currentKey + endSubstring;
    let ssnValue = ssn !== '' ? enterValue : currentKey;
    ssnValue = ssnValue.replace(/\D/g, '');
    if (ssnValue.toString().length >= 4) {
      ssnValue = `${ssnValue.slice(0, 3)}-${ssnValue.slice(3, ssnValue.toString().length)}`;
    }
    if (ssnValue.toString().length >= 7) {
      ssnValue = `${ssnValue.slice(0, 6)}-${ssnValue.slice(6, ssnValue.toString().length)}`;
    }
    setTimeout(() => {
      const input = document.getElementById('ssn');
      if (input?.setSelectionRange) {
        input.focus();
        if (selectionStart + 1 === 4 || selectionStart + 1 === 7) {
          input.setSelectionRange(selectionStart + 2, selectionStart + 2);
        } else {
          input.setSelectionRange(selectionStart + 1, selectionStart + 1);
        }
      }
    }, 10);
    handleKeyDown(ssnValue);
    return false;
  };

  const handleDelValue = (e, selectionStart, selectionEnd) => {
    let delValue;
    if (selectionEnd === 0) {
      return;
    }
    if (selectionStart === selectionEnd) {
      const startDel = ssn?.slice(0, selectionStart - 1);
      const endDel = ssn?.slice(selectionEnd, ssn?.length);
      delValue = startDel + endDel;
    } else {
      const startDel = ssn?.substring(0, selectionStart);
      const endDel = ssn?.substring(selectionEnd);
      delValue = startDel + endDel;
    }
    delValue = delValue.replace(/\D/g, '');
    if (delValue.toString().length >= 4) {
      delValue = `${delValue.slice(0, 3)}-${delValue.slice(3, delValue.toString().length)}`;
    }
    if (delValue.toString().length >= 7) {
      delValue = `${delValue.slice(0, 6)}-${delValue.slice(6, delValue.toString().length)}`;
    }
    setTimeout(() => {
      if (selectionStart !== selectionEnd) {
        e.target.selectionEnd = selectionStart;
      } else {
        e.target.selectionEnd = selectionStart - 1;
      }
    }, 10);
    handleKeyDown(delValue);
  };
  function areAllNumbersSameInSSN(ssnWithHyphens) {
    const newSSNNumber = ssnWithHyphens.replace(/-/g, '');
    if (newSSNNumber && newSSNNumber.length === 9) {
      const numbersArray = Array.from(newSSNNumber);
      const firstNumber = numbersArray[0];
      return numbersArray.every((number) => number === firstNumber);
    }
    return false;
  }

  const onBlurSSN = (e) => {
    const result = areAllNumbersSameInSSN(ssn);
    if (result) {
      leadGenerationContext.setBusinessAccountInfoError((prevState) => ({
        ...prevState,
        ssn: '* Invalid Format',
      }));
      leadGenerationContext.setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        ssn: '* Invalid Format',
      }));
      leadGenerationContext.setPersonalInformationErrors((prevState) => ({
        ...prevState,
        ssn: '* Invalid Format',
      }));
    }
    // else {
    //   error = '';
    // }
  };
  const maskValueChange = (e) => {
    const currentCode = e.which || e.code;
    let currentKey = e.key;
    if (currentKey === 'Unidentified') {
      currentKey = String.fromCharCode(currentCode);
    }
    if (currentKey === 'Tab') {
      return;
    }
    e.preventDefault();
    const numkeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    const navkeys = [
      VALIDATION_BACKSPACE,
      'Delete',
      'Shift',
      'ArrowUp',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      '',
    ];
    const { selectionStart } = e.target;
    const { selectionEnd } = e.target;
    if (currentKey === 'ArrowLeft') {
      if (selectionStart === 0) return;
      setTimeout(() => {
        e.target.selectionEnd = selectionStart - 1;
      }, 10);
    } else if (currentKey === 'ArrowRight') {
      setTimeout(() => {
        e.target.selectionStart = selectionStart + 1;
      }, 10);
    } else if (!numkeys.includes(currentKey) && !navkeys.includes(currentKey)) {
      e.preventDefault();
    } else if (ssn?.length < 11 && numkeys.includes(currentKey)) {
      handleSSNValue(currentKey, selectionStart, selectionEnd);
    } else if (currentKey === VALIDATION_BACKSPACE || currentKey === 'Delete') {
      handleDelValue(e, selectionStart, selectionEnd);
    }
    return false;
  };

  const ssnField = () => {
    let jsx;
    if (isCommercialPage) {
      jsx = (
        <span>
          {t('Social Security Number Commercial', {
            defaultValue: 'Social Security Number',
          })}

          <span
            style={{
              color: ORIENTAL_ORANGE,
              position: 'absolute',
              fontSize: '22px',
              marginLeft: '4px',
            }}
          >
            *
          </span>
        </span>
      );
    } else if (isFlowTypeETB) {
      jsx = (
        <span>
          {t('Social security number', {
            defaultValue: 'Social Security Number',
          })}

          <span
            style={{
              color: ORIENTAL_ORANGE,
              position: 'absolute',
              fontSize: '22px',
              marginLeft: '4px',
            }}
          >
            *
          </span>
        </span>
      );
    } else {
      jsx = (
        <span>
          {t('Social Security Number', {
            defaultValue: 'Social Security Number',
          })}

          <span
            style={{
              color: ORIENTAL_ORANGE,
              position: 'absolute',
              fontSize: '22px',
              marginLeft: '4px',
            }}
          >
            *
          </span>
        </span>
      );
    }
    return jsx;
  };

  return (
    <>
      <InputLabel
        htmlFor="ssn"
        aria-label={`${t('Social Security Number', {
          defaultValue: 'Social Security Number',
        })} ${t(REQUIRED, { defaultValue: REQUIRED })}`}
        // required
        className={commonStyles.label_title}
      >
        {whySSN ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              {t('Social Security Number', {
                defaultValue: 'Social Security Number',
              })}

              <span
                style={{
                  color: ORIENTAL_ORANGE,
                  position: 'absolute',
                  fontSize: '22px',
                  marginLeft: '4px',
                }}
              >
                *
              </span>
            </span>
            <span className={`${commonStyles.ssnTooltip}`}>
              <CustomTooltip
                custom
                customAriaLabel={`${t('ssnTooltip', {
                  defaultValue: 'ssnTooltip',
                })} ${t('More Info', { defaultValue: 'More Info' })}`}
                placement="top"
                text={t(`ssn Tooltip`, {
                  defaultValue: `SSN tooltip`,
                })}
              />
              <Typography className={`${commonStyles.ssnTextTooltip}`}>
                {t('Why do we need your SSN?', {
                  defaultValue: 'Why do we need your SSN?',
                })}
              </Typography>
            </span>
          </div>
        ) : (
          ssnField()
        )}
      </InputLabel>
      <OutlinedInput
        id={id}
        name="ssn"
        className={commonTernary(
          disabled,
          `${commonStyles.outlined_inputs} ${commonStyles.outlined_inputs_disabled}`,
          `${commonStyles.outlined_inputs}`
        )}
        placeholder={
          !isCommercialPage
            ? t('Enter social security number', {
                defaultValue: 'Enter social security number',
              })
            : t('Enter social security number commercial', {
                defaultValue: 'Enter social security number',
              })
        }
        fullWidth
        autoComplete="off"
        disabled={!!disabled}
        onBlur={onBlurSSN}
        value={ssnVal ? ssnFakeValue : ssn}
        onKeyDown={maskValueChange}
        inputProps={{ maxLength: 11, inputMode: 'tel', 'data-testid': 'ssn' }}
        style={{
          notchedOutline: !error ? commonStyles.notchedOutline : commonStyles.notchedOutlineErr,
        }}
        // background: '#5558582B 0% 0% no-repeat padding-box'
        endAdornment={
          <InputAdornment
            position="end"
            style={{ marginLeft: '0', marginRight: '-5px', maxWidth: '35px' }}
          >
            <IconButton
              disableFocusRipple
              onClick={showHideHandler}
              tabIndex="0"
              data-testid="show-hide"
              className="ssnEyeIcon"
            >
              <img alt={ssnAltText} src={showHideIcon} style={{ marginTop: '2px' }} />
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
};

SSN.propTypes = {
  handleKeyDown: PropTypes.func,
  error: PropTypes.string,
  ssn: PropTypes.string,
  isCommercialPage: PropTypes.bool,
  isFlowTypeETB: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  whySSN: PropTypes.bool,
};
