import { IconButton } from '@livechat/ui-kit';
import { PropTypes } from 'prop-types';
import {
  GO_BACK_TO_CHATBOT_ENG,
  GO_BACK_TO_CHATBOT_ESP,
  REQUEST_CALL_BACK_ENG,
  REQUEST_CALL_BACK_ESP,
  TRY_SOMETHING_ELSE_ENG,
  TRY_SOMETHING_ELSE_ESP,
} from '../../Constants';

const ShowWaitTimeAgain = (props) => {
  const { buttonMessageOnbackToLexTrigger, liveAgentTrigger, englishBot, pegaConnectTrigger } =
    props;
  const btn2 = englishBot ? GO_BACK_TO_CHATBOT_ENG : GO_BACK_TO_CHATBOT_ESP;
  const btn3 = englishBot ? REQUEST_CALL_BACK_ENG : REQUEST_CALL_BACK_ESP;
  const btn2response = englishBot ? TRY_SOMETHING_ELSE_ENG : TRY_SOMETHING_ELSE_ESP;
  const backToChatbot = () => {
    liveAgentTrigger();
    setTimeout(() => {
      const status = false;
      buttonMessageOnbackToLexTrigger(btn2response, status);
    }, 5000);
  };
  return (
    <div
      className="quick-replies"
      style={{ display: 'block', justifyContent: 'left', textAlign: 'left' }}
    >
      <IconButton onClick={backToChatbot} tabIndex="102" data-testid="button_id2">
        {btn2}
      </IconButton>
      <IconButton onClick={pegaConnectTrigger} tabIndex="102" data-testid="button_id3">
        {btn3}
      </IconButton>
    </div>
  );
};
ShowWaitTimeAgain.propTypes = {
  englishBot: PropTypes.bool,
  buttonMessageOnbackToLexTrigger: PropTypes.func,
  liveAgentTrigger: PropTypes.func,
  pegaConnectTrigger: PropTypes.func,
};
export default ShowWaitTimeAgain;
