/* eslint-disable max-lines */
import { makeStyles } from '@material-ui/core';

export const CommonStyles = () =>
  makeStyles((theme) => ({
    main_modal_container: {
      padding: '15px 40px',
      [theme.breakpoints.down('650')]: {
        padding: '10px',
      },
    },
    msg_status: {
      paddingLeft: '5px',
      verticalAlign: 'bottom',
      marginBottom: '5px',
    },
    ssn_wrapper: {
      // border: '0.51px solid #555858',
      borderRadius: '2px',
      font: 'normal normal 300 14px / 16px Arial',
      color: '#000000',
      height: '41px',
      opacity: '1',
      background: 'rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box',
      textAlign: 'left',
      letterSpacing: '0.1px',
    },
    validation_design: { display: 'block', width: '100%' },
    validation_align: { display: 'block', textAlign: 'center' },
    progress_row: { display: 'flex', width: '100%', justifyContent: 'center' },
    progress_design: { position: 'absolute', zIndex: '100', marginLeft: '-20px' },
    chat_msg_design_img: { display: 'inline', marginLeft: '3px' },
    chat_msg_align: { textAlign: 'center' },
    icon_button: { padding: '2px' },
    sup_design: { fontSize: '11px' },
    chat_head_img: { verticalAlign: 'middle' },
    chat_header_icon: {
      padding: '0 !important',
      width: '55px',
      flexShrink: '0',
    },
    chat_header_right_icon: {
      display: 'inline-flex',
    },
    chatboat_bubble: { marginRight: '0.3em', width: '12%' },
    chatboat_bubble_img: { display: 'inline', marginLeft: '3px' },
    button_text: { verticalAlign: 'middle', marginRight: '25px' },
    date_icon: { marginLeft: '1px', verticalAlign: 'middle', outline: 'none' },
    minimized: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      padding: '0',
    },
    main_header: {
      // padding: "15px 53px 8px 44px",
      // alignItems: "center",
      justifyContent: 'center',
      margin: '60px 0',
      [theme.breakpoints.down('650')]: {
        // padding: "0 0 0 12px",
        marginTop: '5px',
      },
    },

    oriental: {
      width: '156px',
      height: '41px',
      opacity: 1,
    },
    thank_you_header: {
      // width: "100%"
      [theme.breakpoints.down('740')]: {
        width: '100%',
      },
    },
    service_acc_margin: { marginBottom: '10px' },
    gen_form_contain: { margin: '7.5px' },
    service_acc_font: { fontWeight: 'bold' },
    steps_grid: {
      // display: "inline-block",
      // textAlign: "right",
      marginTop: '30px',
      marginBottom: '30px',
      [theme.breakpoints.down('650')]: {
        marginTop: '10px',
        marginBottom: '20px',
      },
    },
    fields_grid: {
      margin: '5px 0',
      [theme.breakpoints.down('960')]: {
        margin: '0',
      },
    },
    buttons_grid: {
      display: 'inline-block',
      textAlign: 'right',
      marginTop: '30px',
      marginBottom: '30px',
      [theme.breakpoints.down('650')]: {
        marginTop: '20px',
        marginBottom: '20px',
        textAlign: 'center',
      },
    },
    continue_button: {
      background: '#661c69 0% 0% no-repeat padding-box',
      borderRadius: '25px',
      width: '147px',
      height: '45px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      letterSpacing: '0.11px',
      color: '#ffffff',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#661c69',
      },
      marginRight: '6%',
      [theme.breakpoints.down('1280')]: {
        marginRight: '1%',
      },
      [theme.breakpoints.down('940')]: {
        marginRight: '2%',
      },
      '&.Mui-disabled span': {
        opacity: 0.6,
      },
      [theme.breakpoints.down('425')]: {
        width: '125px',
      },
    },
    back_button: {
      border: '1px solid #661C69',
      borderRadius: '25px',
      opacity: 1,
      width: '147px',
      height: '45px',
      marginRight: '20px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      letterSpacing: '0.11px',
      color: '#661C69',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#ffffff',
      },
      [theme.breakpoints.down('650')]: {
        // display: "inline-block",
        // width: "110px",
        marginRight: '10px',
      },
      [theme.breakpoints.down('425')]: {
        width: '125px',
      },
    },
    pdf_button: {
      border: '1px solid #661C69',
      borderRadius: '25px',
      opacity: 1,
      width: '240px',
      height: '45px',
      marginRight: '20px',
      letterSpacing: '0.11px',
      color: '#661C69',
      textDecoration: 'none',
      font: 'normal normal medium 16px/18px Arial',
      padding: '13px 35px',
      '&:hover': {
        backgroundColor: '#ffffff',
      },
      // [theme.breakpoints.down("650")]: {
      //     marginRight: "0",
      //     width: "225px",
      // },
      [theme.breakpoints.down('570')]: {
        width: '190px',
        display: 'block',
        height: 'auto',
        marginRight: '0',
      },
    },
    bank_site_btn: {
      background: '#661c69 0% 0% no-repeat padding-box',
      borderRadius: '67px',
      width: '240px',
      height: '45px',
      letterSpacing: '0.3px',
      color: '#ffffff',
      textTransform: 'none',
      font: 'normal normal medium 16px/18px Arial',
      padding: '13px 38px',
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: '#661c69',
      },
      [theme.breakpoints.down('960')]: {
        marginRight: 0,
      },
      // [theme.breakpoints.down("650")]: {
      //     width: "225px",
      // },
      [theme.breakpoints.down('570')]: {
        width: '190px',
        display: 'block',
        height: 'auto',
        marginTop: '10px',
      },
      '&.Mui-disabled span': {
        opacity: 0.6,
      },
    },
    checkbox_label_style: {
      marginLeft: '2px',
      textAlign: 'left',
      font: 'normal normal 300 16px/18px Arial',
      letterSpacing: '0.11px',
      color: '#000000',
      opacity: 1,
    },

    checkbox_content_position: {
      display: 'flex',
      position: 'relative',
      top: '-23px',
      left: '32px',
      width: 'fit-content',
      marginRight: '30px',
    },
    form_heading_info: {
      textAlign: 'left',
      width: 'fit-content',
      font: 'normal normal bold 18px/21px Arial',
      letterSpacing: '0.12px',
      color: '#000000',
      opacity: 1,
      padding: '10px 0',
      [theme.breakpoints.down('650')]: {
        font: 'normal normal bold 16px/21px Arial',
        margin: '0',
        marginBottom: '5px',
      },
    },
    form_heading_desc_info: {
      textAlign: 'left',
      width: 'fit-content',
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0.11px',
      color: '#000000',
      opacity: 1,
      padding: '10px 0',
      [theme.breakpoints.down('650')]: {
        font: 'normal normal normal 16px/21px Arial',
        margin: '0',
        marginBottom: '5px',
      },
    },
    thankyou_heading: {
      textAlign: 'left',
      width: 'fit-content',
      font: 'normal normal bold 26px/29px Arial',
      letterSpacing: '0.18px',
      color: '#000000',
      opacity: 1,
      [theme.breakpoints.down('650')]: {
        font: 'normal normal 300 20px/23px Arial',
        margin: '0',
        marginBottom: '5px',
      },
    },
    email_padding: { padding: '0 7px' },
    stepper_margin: { margin: '10px' },
    ssn_mark_input: { marginLeft: '-20px', marginRight: '-7px', maxWidth: '35px' },
    ssn_mark_margin: { marginTop: '2px' },
    date_input: { textAlign: 'end' },
    date_input_icon: { cursor: 'pointer', marginLeft: '1px' },
    thankyou_info: {
      textAlign: 'left',
      width: 'fit-content',
      font: 'normal normal normal 20px/23px Arial',
      letterSpacing: '0.14px',
      color: '#000000',
      opacity: 1,
      [theme.breakpoints.down('650')]: {
        font: 'normal normal 300 16px/20px Arial',
        margin: '0',
        marginBottom: '5px',
      },
    },
    buttons_grid_return: {
      display: 'inline-block',
      textAlign: 'left',
      margin: '40px 15px',
      [theme.breakpoints.down('650')]: {
        textAlign: 'left',
      },
    },
    buttons_grid_error: {
      display: 'inline-block',
      textAlign: 'left',
      margin: '40px 15px',
      [theme.breakpoints.down('570')]: {
        textAlign: 'center',
        margin: '40px 0px',
        justifyContent: 'center',
        display: 'flex',
      },
    },
    return_button: {
      background: '#661c69 0% 0% no-repeat padding-box',
      width: '240px',
      borderRadius: '67px',
      height: '45px',
      font: 'normal normal medium 16px/18px Arial',
      letterSpacing: '0.3px',
      color: '#ffffff',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#661c69',
      },
      padding: '0 35px',
      [theme.breakpoints.down('960')]: {
        marginRight: 0,
      },
      '&.Mui-disabled span': {
        opacity: 0.6,
      },
    },
    return_button_site: {
      background: '#661c69 0% 0% no-repeat padding-box',
      borderRadius: '67px',
      width: '240px',
      height: '45px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      letterSpacing: '0.3px',
      color: '#ffffff',
      textTransform: 'none',
      font: 'normal normal medium 16px/18px Arial',
      '&:hover': {
        backgroundColor: '#661c69',
      },
      padding: '0 35px',
      [theme.breakpoints.down('960')]: {
        marginRight: 0,
      },
      [theme.breakpoints.down('650')]: {
        width: '225px',
      },
      [theme.breakpoints.down('425')]: {
        width: '115px',
        height: 'auto',
      },
      '&.Mui-disabled span': {
        opacity: 0.6,
      },
    },
    label_title: {
      marginBottom: '4px',
      font: 'normal normal 300 14px/16px Arial',
      letterSpacing: '0.1px',
      color: '#000000',
      textAlign: 'left',
      [theme.breakpoints.down('650')]: {
        margin: '7px 0',
      },
    },
    inputs_grid_margin: {
      marginTop: '28px',
      [theme.breakpoints.down('650')]: {
        marginTop: '10px',
      },
    },
    date_of_birth_select: {
      background: 'rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box',
      opacity: 1,
      height: '41px',
      fontWeight: 300,
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      textAlign: 'left',
      letterSpacing: '0.1px',
      color: '#000000',
      '& .MuiOutlinedInput-input': {
        paddingTop: '11.5px',
        paddingBottom: '11.5px',
      },
    },
    dob_day_select: {
      '& .MuiSelect-outlined': {
        paddingRight: '10px',
      },
    },
    dob_month_year_select: {
      '& .MuiSelect-outlined': {
        paddingRight: '10px',
        paddingLeft: '0',
      },
    },
    input_disabled_style: {
      '&.MuiInputBase-root.Mui-disabled': {
        color: 'rgba(0, 0, 0, 1)',
      },
    },
    error_message_input: {
      display: 'block',
      font: 'normal normal normal 14px/16px Arial',
      letterSpacing: '0.1px',
      color: '#DF0000',
      opacity: 1,
      textAlign: 'right',
      height: '16px',
      boxSizing: 'border-box',
      marginTop: '3px',
    },
    error_message: {
      font: 'normal normal normal 14px/16px Arial',
      letterSpacing: '0.1px',
      color: '#DF0000',
      opacity: 1,
    },
    select_icon: {
      color: '#661C69',
      opacity: 1,
      [theme.breakpoints.down('340')]: {
        right: 0,
      },
    },
    disabled_dob_picker: {
      pointerEvents: 'none',
      border: '1px #00000045 solid',
      borderLeft: 0,
    },
    outlined_inputs: {
      background: 'rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box',
      textAlign: 'left',
      color: '#000000',
      font: 'normal normal 300 14px/16px Arial',
      letterSpacing: '0.1px',
      opacity: 1,
      height: '41px',
      '& .MuiOutlinedInput-input': {
        padding: '11.5px 14px',
      },
      [`& fieldset`]: {
        borderRadius: '2px',
        border: '0.51px solid #555858',
      },
      '& ::-webkit-input-placeholder': {
        opacity: 1,
      },
      '& ::-moz-placeholder': {
        opacity: 1,
      },
      '& :-ms-input-placeholder': {
        opacity: 1,
      },
    },
    mobileFormatStyle: {
      paddingLeft: 0,
    },
    ErrorEnableGrid: {
      // outlined_inputs: {
      // background: "rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box",
      [`& fieldset`]: {
        // borderRadius: "2px",
        border: '0.51px solid #FD0000',
        // },
      },
    },
    area_code_input: {
      textAlign: 'left',
      color: '#000000',
      font: 'normal normal 300 14px/16px Arial',
      opacity: 1,
      height: '41px',
      '& .MuiOutlinedInput-input': {
        padding: '11.5px 14px',
      },
      [`& fieldset`]: {
        borderRadius: '2px',
        border: '0.5px solid #555858',
      },
      '& ::-webkit-input-placeholder': {
        opacity: 1,
      },
      '& ::-moz-placeholder': {
        opacity: 1,
      },
      '& :-ms-input-placeholder': {
        opacity: 1,
      },
      background: '#F2F2F2 0% 0% no-repeat padding-box',
      letterSpacing: '0.1px',
    },
    select_inputs: {
      background: 'rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box',
      opacity: 1,
      height: '41px',
      fontWeight: 300,
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      textAlign: 'left',
      letterSpacing: '0.1px',
      color: '#000000',
      '& .MuiOutlinedInput-input': {
        padding: '11.5px 14px',
      },
      [`& fieldset`]: {
        borderRadius: '2px',
        border: '0.51px solid #555858',
      },
    },

    select_style: {
      background: 'rgba(85, 88, 88, 0.02) 0% 0% no-repeat padding-box',
      textAlign: 'left',
      fontWeight: 300,
      lineHeight: '16px',
      fontStyle: 'normal',
      fontSize: '14px',
      letterSpacing: '0.1px',
      color: '#000000',
      opacity: 1,
    },
    date_tooltip: {
      alignItems: 'center',
      marginLeft: '15px',
      [theme.breakpoints.down('650')]: {
        marginLeft: '0',
      },
    },
    modal_close: {
      color: '#F5821F',
      position: 'absolute',
      right: 0,
      zIndex: 1300,
    },

    close_icon: {
      width: '23px',
      height: '23px',
      margin: '6px',
      padding: '1px',
      cursor: 'pointer',
      border: '2px solid #F5821F',
      borderRadius: '50%',
      backgroundColor: '#fff',
    },
    progress_icon: {
      width: '85vw',
      height: '50vh',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('1100')]: {
        width: '90vw',
      },
      [theme.breakpoints.up('1400')]: {
        width: '75vw',
      },
      [theme.breakpoints.up('1650')]: {
        width: '60vw',
      },
    },
  }));
