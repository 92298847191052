import { makeStyles } from '@material-ui/core';
import { COLOR1, COLOR2, COLOR3, GRAY44 } from '../../../constants';

export const FundingScreenStyles = makeStyles(
  (theme) => ({
    readMore: {
      display: 'block',
      textDecoration: 'none',
      color: '#661C69',
      fontFamily: 'Arial',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
    },
    outerDiv: {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: '30px',
      marginBottom: '17px',
      marginRight: '14px',
    },
    outerDivAnother: {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: '20px',
      marginBottom: '47px',
      marginRight: '16px',
      [theme.breakpoints.down('600')]: {
        marginTop: '10px',
        marginBottom: '28px',
        marginRight: '0px',
      },
    },
    imgDivDesign: {
      width: '24px',
      height: '24px',
      marginRight: '13px',
    },
    mainHeading: {
      color: '#661C69',
      fontFamily: 'Arial',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '20px',
      marginBottom: '18px',
    },
    subHeading: {
      color: '#000000',
      fontFamily: 'Arial',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '10px',
    },
    chkAccountHeading: {
      color: '#000000',
      fontFamily: 'Arial',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '10px',
    },
    fund_stepper: {
      width: '100%',
      margin: '37px 30px 40px 50px',
      [theme.breakpoints.down('sm')]: {
        margin: '37px 50px 45px 50px',
      },
      [theme.breakpoints.down('650')]: {
        margin: '25px',
      },
    },
    toggleStyle: {
      marginBottom: '10px',
      [theme.breakpoints.down('960')]: {
        marginBottom: '10px',
      },
      [theme.breakpoints.down('600')]: {
        marginBottom: '0px',
      },
    },
    disableSwitch: {
      cursor: 'unset !important',
    },
    input_grid_padding: {
      [theme.breakpoints.down('600')]: {
        marginBottom: '10px',
      },
    },
    gridSpace: { marginTop: '20px' },
    gridBorder: {
      paddingTop: '30px',
      display: 'grid',
      width: 'inherit',
      borderTop: '1px solid #E6E6E6',
      [theme.breakpoints.down('650')]: {
        marginTop: '10px',
        paddingTop: '20px',
      },
    },
    radioRoot: {
      width: 0,
      height: '19px',
      paddingTop: 0,
      paddingBottom: 0,
      color: '#661c69',
    },
    radioLabelStyle: {
      marginLeft: '8px',
      marginRight: '20px',
      font: 'normal 700 16px/19px Arial',
      color: '#000000',
      cursor: 'pointer',
      [theme.breakpoints.down('600')]: {
        font: '700 16px/18px Arial',
      },
    },
    toggleGridStyle: {
      marginTop: '38px',
      marginBottom: '10px',
      [theme.breakpoints.down('600')]: {
        marginTop: '20px',
      },
    },
    radio_text: {
      font: 'normal 700 16px/18px Arial',

      color: '#661C69',
      [theme.breakpoints.down('600')]: {
        marginBottom: '10px',
      },
    },
    oriLabel: {
      marginRight: '7px',
      font: 'normal 700 16px/18px Arial',
      color: '#000000',
      [theme.breakpoints.down('600')]: {
        marginRight: '15px',
      },
    },
    fund_grid_margin: {
      background: '#FFFFFF',
      border: ' 1px solid #D0D0D0',
      boxShadow: '0px 4px 4px rgb(0 0 0 / 21%)',
      borderRadius: '6px',
      // padding: '25px 30px 35px',
      paddingTop: '25px',
      paddingRight: '30px',
      paddingBottom: '35px',
      paddingLeft: '30px',
      opacity: 1,
      [theme.breakpoints.between('601', '1200')]: {
        // padding: '25px 25px 30px',
        paddingTop: '25px',
        paddingRight: '25px',
        paddingBottom: '25px',
        paddingLeft: '30px',
      },
      [theme.breakpoints.down('600')]: {
        border: 'none',
        boxShadow: 'none',
        borderRadius: '0px',
        // padding: '0px',
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        paddingLeft: 0,
      },
    },

    fund_container: {
      padding: '20px',
      // display: 'flex',
      // alignItems: 'center',
      background: `${COLOR3} 0% 0% no-repeat padding-box`,
      border: `.25px solid ${GRAY44}`,
      borderRadius: '10px',
      opacity: 1,
      marginBottom: '20px',
      [theme.breakpoints.down('960')]: {
        padding: '20px 25px',
      },
    },

    fund_container_oriental: {
      background: '#FFFFFF',
      border: ' 1px solid #D0D0D0',
      boxShadow: '0px 4px 4px rgb(0 0 0 / 21%)',
      borderRadius: '6px',
      padding: '25px 30px 0px',
      opacity: 1,
      [theme.breakpoints.down('1200')]: {
        padding: '25px 25px 0px',
      },
    },

    statement_upload_style_adjust: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      paddingLeft: '10px',
      [theme.breakpoints.down('960')]: {
        marginTop: '10px',
      },
      [theme.breakpoints.down('600')]: {
        paddingLeft: '0',
      },
    },

    statement_upload_text_desc: {
      font: 'normal normal 300 16px/18px Arial',
      letterSpacing: '0.11px',
      width: '218px',
      marginTop: '20px',
      opacity: '0.8',
      marginBottom: '10px',
      [theme.breakpoints.down('320')]: {
        width: 'auto',
      },
    },

    fund_heading: {
      textAlign: 'left',
      width: 'fit-content',
      font: 'normal normal bold 20px/23px Arial',
      letterSpacing: '0.14px',
      color: COLOR1,
      opacity: 1,
      [theme.breakpoints.down('650')]: {
        font: 'normal normal bold 16px/18px Arial',
        letterSpacing: '0.11px',
        color: COLOR1,
        margin: '0',
        marginBottom: '5px',
      },
      [theme.breakpoints.down('600')]: {
        font: 'normal 700 22px/32px Arial',
        marginBottom: '0px',
        marginTop: '-10px',
      },
    },

    inputs_grid_margin: {
      marginTop: '10px',
      marginBottom: '10px',
      [theme.breakpoints.down('950')]: {
        marginTop: '10px',
        marginBottom: '0',
      },
    },
    inputs_grid_margin_another: {
      marginTop: '10px',
      marginBottom: '10px',
      [theme.breakpoints.down('950')]: {
        marginTop: '10px',
        marginBottom: '10px',
      },
    },

    margin_adjust_for_top_grid: {
      marginTop: '21px',
      [theme.breakpoints.down('960')]: {
        marginTop: '20px',
      },
    },

    field_padding_right: {
      paddingRight: '25px',
      [theme.breakpoints.down('960')]: {
        paddingRight: '0',
      },
    },

    field_padding_left: {
      paddingLeft: '25px',
      [theme.breakpoints.down('960')]: {
        paddingLeft: '0',
      },
    },

    field_padding_oriental: {
      paddingRight: '20px',
      [theme.breakpoints.down('960')]: {
        paddingRight: '0',
      },
    },

    field_padding_oriental_DGI: {
      paddingRight: '25px',
      [theme.breakpoints.down('960')]: {
        paddingRight: '0',
      },
    },

    toggle_mobile_top: {
      [theme.breakpoints.down('550')]: {
        display: 'block',
        width: '100%',
        maxWidth: '100%',
        flexBasis: '100%',
      },
      [theme.breakpoints.between('550', '600')]: {
        maxWidth: '50%',
      },
    },
    resp_margin: {
      marginLeft: '20px',
      [theme.breakpoints.down('991')]: {
        marginLeft: 0,
      },
    },

    switch_toggle: {
      margin: '25px 0 0',
      [theme.breakpoints.up('1300')]: {
        maxWidth: '75%',
        flexBasis: '75%',
      },
      [theme.breakpoints.down('550')]: {
        maxWidth: '100%',
        flexBasis: '100%',
      },
      '& .MuiFormControl-root': {
        display: 'block',
        width: '100%',
      },
      '& .MuiFormControlLabel-root': {
        display: 'flex',
        justifyContent: 'flex-end',
        // justifyContent: 'space-between',
        [theme.breakpoints.down('550')]: {
          justifyContent: 'space-between',
        },
      },
    },

    switch_toggle_top: {
      '& toggle_mobile_top:nth-child(1) .MuiFormControlLabel-root div': {
        marginLeft: '15px',
      },
    },

    align_toggle_btn: {
      [theme.breakpoints.between('850', '960')]: {
        maxWidth: '30%',
      },
      [theme.breakpoints.between('750', '850')]: {
        maxWidth: '35%',
      },
      [theme.breakpoints.between('700', '750')]: {
        maxWidth: '40%',
      },
    },

    toggle_mobile_debitcard: {
      '& .MuiFormControlLabel-root div': {
        marginLeft: '40px',
        [theme.breakpoints.down('800')]: {
          marginLeft: '0px',
        },
      },
    },

    // another_account: {
    //     '& .MuiFormControlLabel-root div': {
    //         marginLeft: '23px',
    //         [theme.breakpoints.down('800')]: {
    //             marginLeft: '0px',
    //         },
    //     }
    // },

    // button_grid: {
    //   padding: '30px',
    //   justifyContent: 'flex-end',
    //   textAlign: 'right',
    //   [theme.breakpoints.down('650')]: {
    //     paddingTop: '35px',
    //     paddingBottom: '20px',
    //     justifyContent: 'center',
    //   },
    //   [theme.breakpoints.up('650')]: {
    //     paddingRight: '45px',
    //   },
    // },

    fund_button_container: {
      [theme.breakpoints.down('650')]: {
        justifyContent: 'center',
        display: 'flex',
      },
    },

    // back_button: {
    //   border: `1px solid ${RADIO_COLOR}`,
    //   borderRadius: '25px',
    //   opacity: 1,
    //   width: '147px',
    //   textTransform: 'none',
    //   height: '45px',
    //   margin: '0px 15px 0px 0px',
    //   font: 'normal normal bold 16px/18px Arial',
    //   letterSpacing: '1.01px',
    //   color: `${RADIO_COLOR} !important`,
    //   '&:hover': {
    //     backgroundColor: COLOR4,
    //   },
    //   [theme.breakpoints.down('650')]: {
    //     width: '48%',
    //     maxWidth: '160px',
    //   },
    //   [theme.breakpoints.down('350')]: {
    //     width: '45%',
    //   },
    // },

    // disabled_button: {
    //   '&.MuiButton-root.Mui-disabled': {
    //     background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
    //     borderRadius: '25px',
    //     width: '147px',
    //     height: '45px',
    //     font: 'normal normal bold 16px/18px Arial',
    //     letterSpacing: '1.01px',
    //     color: COLOR4,
    //     textTransform: 'none',
    //     '&:hover': {
    //       backgroundColor: RADIO_COLOR,
    //     },
    //     [theme.breakpoints.down('960')]: {
    //       marginRight: 0,
    //     },
    //     [theme.breakpoints.down('650')]: {
    //       width: '45%',
    //       maxWidth: '160px',
    //     },
    //     [theme.breakpoints.down('350')]: {
    //       width: '45%',
    //     },
    //   },
    // },

    // continue_button: {
    //   border: `1px solid ${RADIO_COLOR}`,
    //   background: `${RADIO_COLOR} 0% 0% no-repeat padding-box`,
    //   borderRadius: '25px',
    //   opacity: 1,
    //   width: '147px',
    //   height: '45px',
    //   font: 'normal normal bold 16px/18px Arial',
    //   textTransform: 'none',
    //   letterSpacing: '1.01px',
    //   color: COLOR4,
    //   '&:hover': {
    //     backgroundColor: RADIO_COLOR,
    //   },
    //   [theme.breakpoints.down('650')]: {
    //     width: '45%',
    //     maxWidth: '160px',
    //   },
    //   [theme.breakpoints.down('350')]: {
    //     width: '45%',
    //   },
    //   '&.Mui-disabled span': {
    //     opacity: 0.6,
    //   },
    // },
    radioStyle: { marginTop: '10px', marginBottom: '30px', display: 'inline-flex' },
    fundingFlex: { display: 'flex', alignItems: 'center' },
    error_message: {
      font: 'normal normal normal 14px/16px Arial',
      letterSpacing: '0.1px',
      color: COLOR2,
      opacity: 1,
      position: 'relative',
      top: '3px',
      display: 'block',
    },

    label_formControl: {
      marginLeft: '2px',
      font: 'normal normal 300 16px/18px Arial',
      letterSpacing: '0.11px',
      color: COLOR1,
      opacity: 1,
      textAlign: 'left',
    },

    id_upload_module_grid: {
      marginTop: '55px',
      [theme.breakpoints.down('960')]: {
        maxWidth: 'none',
        marginTop: '10px',
        marginBottom: '20px',
      },

      [theme.breakpoints.down('650')]: {
        marginTop: '20px',
        marginBottom: '20px',
      },
    },

    switch_toggle_second: {
      marginTop: '25px',
    },

    consent_message: {
      marginTop: '20px',
      marginBottom: '26px',
      [theme.breakpoints.down('960')]: {
        marginBottom: '5px',
      },
    },

    consent_fund_orientalDgi: {
      marginTop: '35px',
      marginBottom: '-31px',
      [theme.breakpoints.down('960')]: {
        marginBottom: '5px',
      },
    },

    consent_fund_oriental: {
      marginBottom: '-31px',
      [theme.breakpoints.down('960')]: {
        marginBottom: '5px',
      },
    },

    contact_terms: {
      font: 'normal 400 14px/16px Arial',
      color: COLOR1,
      opacity: 1,
      letterSpacing: '0.1px',
      [theme.breakpoints.down('600')]: {
        font: '400 16px/18px Arial',
      },
    },
  }),
  { index: 1 }
);
