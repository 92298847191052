import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const mobileValidation = async (phone, accessToken) => {
  let response = null;
  const uuid = uuidv4();
  const data = [
    {
      recordId: uuid,
      phoneNumber: phone,
    },
  ];
  try {
    response = await axios.post(`${process.env.REACT_APP_MOBILE_URL}`, data, {
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (e) {
    return e.response;
  }
  return response;
};

export const getEmailVerificationData = async (email, accessToken) => {
  let response = null;
  const uuid = uuidv4();
  const data = [
    {
      recordId: uuid,
      email,
    },
  ];
  try {
    response = await axios.post(`${process.env.REACT_APP_EMAIL_VERIFICATION_URL}`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (e) {
    return e.response;
  }
  return response;
};
export const mobileValidationExpress = async (phone, accessToken, customerAppId) => {
  let response = null;
  const uuid = uuidv4();
  const data = [
    {
      recordId: uuid,
      phoneNumber: phone,
    },
  ];
  try {
    response = await axios.post(process.env.REACT_APP_EXPRESS_PHONE_VALIDATION, data, {
      headers: {
        'Application-Id': customerAppId,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } catch (e) {
    return e.response;
  }
  return response;
};

export const getEmailVerificationDataExpress = async (email, accessToken, customerAppId) => {
  let response = null;
  const uuid = uuidv4();
  const data = [
    {
      recordId: uuid,
      email,
    },
  ];
  try {
    response = await axios.post(process.env.REACT_APP_EXPRESS_EMAIL_VALIDATION, data, {
      headers: {
        'Application-Id': customerAppId,
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
  } catch (e) {
    return e.response;
  }
  return response;
};
