import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MANDATORY_FIELD_ERR } from '../../../constants';
import LeadGenerationContext from '../../../context/LeadGenerationContext';
import { CaptureLoanDetailsStyles } from '../../Consumer/CaptureLoanDetailsStyles';
import { commonUtility } from '../UtilityFunctions';
import { CDProcessingStyles } from './CDProcessingStyles';

export const FundingScreenCards = ({ accountsResponse, setIsSubmitClicked, isSubmitClicked }) => {
  const {
    setOrientalAccountCardValue,
    orientalAccountCardValue,
    setFundData,
    setFundDataErrors,
    fundData,
    fundDataErrors,
    customerNumber,
    setIsFundingScreenDirty,
    setIsScreenOneDirty,
    setIsScreenTwoDirty,
    setIsScreenThreeDirty,
    setIsScreenFourDirty,
    setIsScreenFiveDirty,
  } = useContext(LeadGenerationContext);
  const classes = CDProcessingStyles();
  const radioClass = CaptureLoanDetailsStyles();
  const { t } = useTranslation();

  const [radioValue, setRadioValue] = useState('');

  useEffect(() => {
    setRadioValue(orientalAccountCardValue?.Id);
  }, []);

  const handleRadioChange = (e) => {
    if (e.target.value) {
      setIsFundingScreenDirty(true);
      setRadioValue(e.target.value);
      setIsScreenOneDirty(true);
      setIsScreenTwoDirty(true);
      setIsScreenThreeDirty(true);
      setIsScreenFourDirty(true);
      setIsScreenFiveDirty(true);
      const accountVal = accountsResponse.find((x) => x.Id === e.target.value);
      // setIsFundingScreenDirty
      console.log({ accountVal });
      setFundData({ ...fundData, accountNumber: commonUtility(accountVal.AccountNumber) });
      setOrientalAccountCardValue(accountVal);
      setIsSubmitClicked(false);
      setFundDataErrors({ ...fundDataErrors, accountNumber: '', depositAmount: '' });
    } else if (e.target.value === '') {
      setFundData({ ...fundData, accountNumber: '' });
      setFundDataErrors({ ...fundDataErrors, accountNumber: MANDATORY_FIELD_ERR });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid lg={12} md={12} xs={12}>
        <div className={`${radioClass.radioContainer} ${classes.radioLabelContainer}`}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="accountNumber"
            id="accountNumber_id"
            value={radioValue.toString()}
            style={{ width: '100%', flexDirection: 'row' }}
            onChange={handleRadioChange}
          >
            {accountsResponse?.map((accountData) => (
              <Grid lg={4} md={6} xs={12}>
                <FormControlLabel
                  control={
                    <Radio
                      color="default"
                      classes={{ root: `${radioClass.radioRoot} ${classes.radioMargin}` }}
                      size="large"
                      value={accountData.Id.toString()}
                      inputProps={{
                        'data-testid': 'debtConsolidation_testid',
                      }}
                      id={accountData.Id}
                      name={accountData.Id}
                      onClick={handleRadioChange}
                      disabled={
                        isSubmitClicked &&
                        fundDataErrors?.accountNumber?.includes('available balance') &&
                        accountData.Id === orientalAccountCardValue.Id
                      }
                    />
                  }
                  className={classes.radioCard}
                  label={
                    <InputLabel htmlFor="test" className={radioClass.radioLabelStyle}>
                      <Typography className={classes.radioBtnLabel1}>
                        {t(accountData?.AccountType, {
                          defaultValue: accountData?.AccountType,
                          nsSeparator: '|',
                        })}
                      </Typography>
                      <Typography className={classes.radioBtnLabel2}>
                        XXXX-XXXX-{`${accountData?.AccountNumber?.slice(-4)}`}
                        {customerNumber !== '' && (
                          <span className={classes.radioBtnLabel3}>
                            {' '}
                            - ${accountData?.Balance || 0}
                          </span>
                        )}
                      </Typography>
                    </InputLabel>
                  }
                />
              </Grid>
            ))}
          </RadioGroup>
        </div>
      </Grid>
    </Grid>
  );
};

FundingScreenCards.propTypes = {
  accountsResponse: PropTypes.object,
  setIsSubmitClicked: PropTypes.func,
  isSubmitClicked: PropTypes.bool,
};
