import { makeStyles } from '@material-ui/core';

export const ChatWrapperCss = () =>
  makeStyles((theme) => ({
    bubbleOwnStyle: {
      display: 'block',
      background: '#ffffff !important',
      color: '#000',
      border: '0.5px solid #555858',
      borderRadius: '13px 13px 13px 4px !important',
      padding: '10px 13px 9px 10px !important',
      maxWidth: '100%',
    },

    agentchatStyle: {
      display: 'block',
      border: 'none',
      borderRadius: '0',
      borderBottom: '2px dashed #555858',
      color: '#F5821F',
      background: '#ffffff',
      textAlign: 'center',
      padding: '10px 13px 12px 10px',
      font: 'normal normal normal 18px/17px Arial !important',
    },
    agentEnteredTextStyle: {
      display: 'block',
      border: 'none',
      borderRadius: '0',
      color: '#F5821F',
      background: '#ffffff',
      textAlign: 'center',
      padding: '10px 13px 12px 10px',
      font: 'normal normal normal 18px/17px Arial !important',
    },
    agentchatFontStyle: {
      font: 'normal normal normal 18px/17px Arial !important',
    },
    noAgentchatFontStyle: {},
    agentEnteredStyle: {
      display: 'inline-block',
      border: 'none',
      borderRadius: '0',
      color: '#6b5b5b',
      background: '#ffffff',
      textAlign: 'center',
      padding: '10px 13px 12px 10px',
      verticalAlign: 'bottom',
      font: 'normal normal normal 16px/17px Arial !important',
    },
    agentWaitStyle: {
      display: 'block',
      border: '0',
      background: '#ffffff',
      textAlign: 'center',
      padding: '10px 13px 9px 10px !important',
    },
    agentFileStyle: {
      display: 'block',
      border: '0',
      background: '#ffffff',
      padding: '10px 13px 9px 10px !important',
    },
    bubbleBotStyle: {
      background: '#555858',
      border: '1px solid #555858',
      opacity: '1',
      marginBottom: '4px',
      display: 'inline-block',
      borderRadius: '13px 13px 4px 13px',
      color: '#ffffff',
      textAlign: 'left',
      padding: '10px 12px 9px 15px !important',
    },
    bubbleBotContainerStyle: {
      width: '88%',
    },
    bubbleOwnContainerStyle: {
      width: '100%',
      textAlign: 'right',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    bubbleOwnContainerStyle2: {
      width: '100%',
      textAlign: 'right',
      display: 'inline-block',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    },
    errorMessageNoDisplay: {
      display: 'none',
    },
    cancelBtnDisplay: {
      display: 'block',
      alignSelf: 'center',
      width: '100%',
    },
    cancelBtnNoDisplay: {
      display: 'none',
    },
    errorMessageDisplay: {
      margin: '0 auto',
      color: '#DF0000',
      marginTop: '5px',
    },
    button_width_IOS: {
      width: '83%',
    },
    button_width_Windows: {},
    sendButton: {
      width: '18px',
      height: '18px',
      alignSelf: 'center',
      padding: '0',
      margin: '0.5em',
    },
    attachIcon: {
      color: '#fff',
      fontSize: '20px',
      marginTop: '3px',
    },
    textareaWrapper: {
      width: '30px',
      height: '30px',
      background: '#661C69',
      borderRadius: '50%',
      display: 'table-cell',
      verticalAlign: 'middle',
      textAlign: 'center',
    },
    textComposerWrapper: {
      flexShrink: 0,
      padding: '5px 20px 15px 20px',
    },
    today_date: {
      textAlign: 'center',
      font: 'normal normal normal 12px/15px Arial',
      letterSpacing: '0',
      color: '#000000',
      opacity: '1',
    },
    message_list_container: {
      flexGrow: 1,
      minHeight: 0,
      height: '100%',
    },
    logo_container: {
      background: '#ffffff',
      textAlign: 'center',
      padding: '5px',
    },
    test_design: {
      textDecoration: 'underline',
    },
    hidden_design: { display: 'none' },
    fill_design: { alignSelf: 'flex-end', marginTop: '2px' },
  }));
