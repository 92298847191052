/* eslint-disable max-lines */
import { useContext, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  Card,
  CardContent,
  Typography,
  Modal,
  IconButton,
  Grid,
  Snackbar,
  Link,
} from '@material-ui/core';
import { isAndroid, osVersion } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import snackbarInfo from '../../images/alert-triangle.svg';
import SnackbarCloseIcon from '../../images/Close.svg';
import LeadGenerationContext from '../../context/LeadGenerationContext';
import {
  COLOR1,
  DOB_DAY,
  EMAIL,
  INVALID_DATE,
  INVALID_FORMAT_ERR,
  KNOCK_OFF_MSSG_BODY,
  LAST_NAME,
  MANDATORY_FIELD_ERR,
  PERSONAL_INFO,
  PLEASE_VERIFY_YOUR_DETAILS,
  PROGRESA_CONTIGO,
  REQUIRED,
  SAVE_APP_CONTACT_DISCLAIMER,
  SAVE_YOUR_PROGRESS,
  DATE_FORMAT_EN,
  DATE_FORMAT_ES,
  EN,
  ELITE_ACCOUNT_EN,
} from '../../constants';
import { SaveAppContactInfoStyles } from './SaveAppContactInfoStyles';
import CloseIconIMG from '../../images/Icon.svg';
import saveLogoIMG from '../../images/saveLogo.svg';
import saveAppDisclaimerIcon from '../../images/saveAppDisclaimerIcon.svg';
import { CustomInput } from '../LeadGeneration/CustomInput/CustomInput';
import { CustomDob } from '../LeadGeneration/CustomDob/CustomDob';
import {
  commonTernary,
  emailChange,
  handleDOBchangeSaveAppContact,
  saveAppcontactNameChange,
} from '../commonCode';
import { SSNAndroid } from '../LeadGeneration/SSN/SSNAndroid';
import { SSN } from '../LeadGeneration/SSN/SSN';
import { commonUtility, getProductForBusinessMapping } from '../LeadGeneration/UtilityFunctions';
import { dgiMapping } from '../../services/dgiMapping';
import { productMapping } from '../../services/productMapping';
import { getEmailVerificationData } from '../../services/validationService';
import { getEmailErrorType } from '../LeadGeneration/StepOne/ContactUtils';
import { productNameURLMapping } from '../../services/productNameURLMapping';
import { getCustomerValidation, saveContinueInformation } from '../../services/formService';
import Loader from '../Loader/Loader';
import { CommonStyles } from '../LeadGeneration/CommonStyles';
import { saveKnockOffData } from '../DGI/CommonFunctions';
import KnockOffModal from '../LeadGeneration/StepTwo/KnockOffModal';
import { businessMapping } from '../../services/businessMapping';

const useStyles = SaveAppContactInfoStyles();

const SaveAppContactInfo = (props) => {
  const { t } = useTranslation();
  const [isValidating, setIsValidating] = useState(false);
  const classes = useStyles();
  const styledClasses = CommonStyles();
  const leadGenerationContext = useContext(LeadGenerationContext);
  const { history, currentPage } = props;
  const [isModal, setIsModal] = useState(false);
  const [dobDisable, setDobDisable] = useState(false);

  const [disableModalCondition, setDisableModalCondition] = useState(false);
  const [snackbarstate, setSnackbarstate] = useState(false);
  const {
    saveAppContactInfo,
    saveAppContactInfoErrors,
    setContinueModal,
    setSaveAppContactInfo,
    setSaveAppContactInfoErrors,
    location,
    language,
    saveAppContactScreenDirty,
    setIsModalSaveLater,
    productName,
    vouchedData,
    setIsApplicationSaved,
    idUploadStatus,
    setSaveAppContactScreenDirty,
    setIsScreenTwoDirty,
    setIsScreenThreeDirty,
    setIsScreenFourDirty,
    setIsScreenFiveDirty,
    setIsFundingScreenDirty,
    setCurrentPage,
    setCustomerType,
    contactInformation,
    personalInformation,
    firstName,
    savedDataFlow,
    setPersonalInformation,
    setPersonalInformationErrors,
    personalInformationErrors,
    businessContactInformation,
    businessContactInformationErrors,
    businessAccountInfo,
    businessAccountInfoError,
    setFirstName,
    ownership,
    signerCheck,
    setBusinessAccountInfoError,
    setBusinessAccountInfo,
  } = leadGenerationContext;

  useEffect(() => {
    setCurrentPage('saveApp-contact-info');
    if (!getProductForBusinessMapping(productName)) {
      setSaveAppContactInfo((prevState) => ({
        ...prevState,
        email: contactInformation.email,
        dob: personalInformation.dob,
      }));
    }
  }, [personalInformation.lastName, personalInformation.dob, personalInformation.ssn]);

  useEffect(() => {
    if (
      getProductForBusinessMapping(productName)
        ? businessAccountInfo?.dob &&
          !businessAccountInfoError?.dob &&
          saveAppContactInfo.dob !== 'Invalid date' &&
          saveAppContactInfo.dob !== ''
        : personalInformation.dob &&
          !personalInformationErrors.dob &&
          saveAppContactInfo.dob !== 'Invalid date' &&
          saveAppContactInfo.dob !== ''
    ) {
      setDobDisable(true);
    }
  }, []);
  useEffect(() => {
    if (!personalInformationErrors.dob) {
      setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        dob: '',
      }));
    }
    if (!(personalInformation?.vouchedJobId && idUploadStatus === true)) {
      setFirstName(commonUtility(personalInformation.firstName));
    }
    if (
      !(businessAccountInfo?.vouchedJobId && idUploadStatus === true) &&
      businessMapping(productName)
    ) {
      setFirstName(commonUtility(businessContactInformation.firstName));
    }
  }, []);
  useEffect(() => {
    if (dobDisable) {
      setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        dob: '',
      }));
    }
  }, [dobDisable]);
  const handleCloseBAR = () => {
    setSnackbarstate(false);
  };

  /** SSN Handle change
   */
  const handleKeyDownSSN = (value) => {
    setSaveAppContactScreenDirty(true);
    setIsScreenTwoDirty(true);
    setIsScreenThreeDirty(true);
    setIsScreenFourDirty(true);
    setIsScreenFiveDirty(true);
    setIsFundingScreenDirty(true);
    if (
      productMapping(productName) === ELITE_ACCOUNT_EN ||
      productMapping(productName) === 'Ahorra'
    ) {
      setIsScreenTwoDirty(true);
      setIsScreenThreeDirty(true);
      setIsScreenFourDirty(true);
      setIsScreenFiveDirty(true);
      leadGenerationContext.setIsFeatureScreenDirty(true);
      setIsFundingScreenDirty(true);
    }
    if (value.match(/^\d/)) {
      if (value.length <= 11) {
        setSaveAppContactInfo((prevState) => ({ ...prevState, ssn: value }));
        setSaveAppContactInfoErrors((prevState) => ({
          ...prevState,
          ssn: '',
        }));
        setBusinessAccountInfo((prevState) => ({
          ...prevState,
          ssn: value,
        }));
        setBusinessAccountInfoError((prevState) => ({ ...prevState, ssn: '' }));

        setPersonalInformation((prevState) => ({ ...prevState, ssn: value }));
        setPersonalInformationErrors((prevState) => ({
          ...prevState,
          ssn: '',
        }));
        if (value.length < 11) {
          setSaveAppContactInfoErrors((prevState) => ({
            ...prevState,
            ssn: '* Social Security Number should have 9 digits',
          }));

          setBusinessAccountInfoError((prevState) => ({
            ...prevState,
            ssn: '* Social Security Number should have 9 digits',
          }));

          setPersonalInformationErrors((prevState) => ({
            ...prevState,
            ssn: '* Social Security Number should have 9 digits',
          }));
        } else {
          setSaveAppContactInfoErrors((prevState) => ({
            ...prevState,
            ssn: '',
          }));
          setPersonalInformationErrors((prevState) => ({
            ...prevState,
            ssn: '',
          }));

          setBusinessAccountInfoError((prevState) => ({ ...prevState, ssn: '' }));
        }
      }
    } else if (value === '') {
      setSaveAppContactInfo((prevState) => ({ ...prevState, ssn: '' }));
      setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        ssn: MANDATORY_FIELD_ERR,
      }));
      setPersonalInformation((prevState) => ({ ...prevState, ssn: '' }));
      setPersonalInformationErrors((prevState) => ({
        ...prevState,
        ssn: MANDATORY_FIELD_ERR,
      }));

      setBusinessAccountInfo((prevState) => ({
        ...prevState,
        ssn: '',
      }));
      setBusinessAccountInfoError((prevState) => ({
        ...prevState,
        ssn: MANDATORY_FIELD_ERR,
      }));
    } else {
      setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        ssn: INVALID_FORMAT_ERR,
      }));
      setPersonalInformationErrors((prevState) => ({
        ...prevState,
        ssn: INVALID_FORMAT_ERR,
      }));

      setBusinessAccountInfoError((prevState) => ({
        ...prevState,
        ssn: INVALID_FORMAT_ERR,
      }));
    }
  };

  /** SSN Handle change for android
   */
  const handleKeyDownSSNAndroid = (e) => {
    let { value } = e.target;
    if (value.match(/^[0-9-]*$/)) {
      value = formatSSN(e);
      if (value.length <= 11) {
        setSaveAppContactInfo((prevState) => ({ ...prevState, ssn: value }));
        setSaveAppContactInfoErrors((prevState) => ({
          ...prevState,
          ssn: '',
        }));
        setPersonalInformation((prevState) => ({ ...prevState, ssn: value }));
        setPersonalInformationErrors((prevState) => ({
          ...prevState,
          ssn: '',
        }));

        setBusinessAccountInfo((prevState) => ({
          ...prevState,
          ssn: value,
        }));
        setBusinessAccountInfoError((prevState) => ({
          ...prevState,
          ssn: '',
        }));

        if (value.length < 11) {
          setSaveAppContactInfoErrors((prevState) => ({
            ...prevState,
            ssn: '* Social Security Number should have 9 digits',
          }));
          setPersonalInformationErrors((prevState) => ({
            ...prevState,
            ssn: '* Social Security Number should have 9 digits',
          }));

          setBusinessAccountInfoError((prevState) => ({
            ...prevState,
            ssn: '* Social Security Number should have 9 digits',
          }));
        } else {
          setSaveAppContactInfoErrors((prevState) => ({
            ...prevState,
            ssn: '',
          }));
          setPersonalInformationErrors((prevState) => ({
            ...prevState,
            ssn: '',
          }));

          setBusinessAccountInfoError((prevState) => ({
            ...prevState,
            ssn: '',
          }));
        }
      }
    } else if (value === '') {
      setSaveAppContactInfo((prevState) => ({ ...prevState, ssn: '' }));
      setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        ssn: MANDATORY_FIELD_ERR,
      }));
      setPersonalInformation((prevState) => ({ ...prevState, ssn: '' }));
      setPersonalInformationErrors((prevState) => ({
        ...prevState,
        ssn: MANDATORY_FIELD_ERR,
      }));

      setBusinessAccountInfo((prevState) => ({
        ...prevState,
        ssn: '',
      }));
      setBusinessAccountInfoError((prevState) => ({
        ...prevState,
        ssn: MANDATORY_FIELD_ERR,
      }));
    } else {
      setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        ssn: INVALID_FORMAT_ERR,
      }));
      setPersonalInformationErrors((prevState) => ({
        ...prevState,
        ssn: INVALID_FORMAT_ERR,
      }));
      setBusinessAccountInfoError((prevState) => ({
        ...prevState,
        ssn: INVALID_FORMAT_ERR,
      }));
    }
  };
  /** SSN format
   */
  const formatSSN = (e) => {
    const { selectionStart } = e.target;
    let ssnValue = e.target.value;
    ssnValue = ssnValue?.replace(/\D/g, '');
    if (ssnValue?.toString().length >= 6) {
      ssnValue = `${ssnValue.slice(0, 5)}-${ssnValue.slice(5, ssnValue.toString().length)}`;
    }
    if (ssnValue?.toString().length >= 4) {
      ssnValue = `${ssnValue.slice(0, 3)}-${ssnValue.slice(3, ssnValue.toString().length)}`;
    }

    setTimeout(() => {
      const input = document.getElementById('ssn');
      if (input?.setSelectionRange) {
        if (selectionStart + 1 === 4 || selectionStart + 1 === 7) {
          input.setSelectionRange(selectionStart + 2, selectionStart + 2);
        } else {
          if (ssnValue.length < saveAppContactInfo?.ssn?.length) {
            return input.setSelectionRange(selectionStart, selectionStart);
          }
          input.setSelectionRange(selectionStart + 1, selectionStart + 1);
        }
      }
    }, 10);
    return ssnValue;
  };

  const lastNamechange = (e, leadGenerationCont) => {
    if (e.target.value.length <= 15) {
      saveAppcontactNameChange(
        e,
        leadGenerationCont,
        /^[A-Za-zÑñÁáÉéÍíÓóÚúü-]+( [A-Za-zÑñÁáÉéÍíÓóÚúü-]+)*$/
      );
    }
  };

  const handleChange = (e) => {
    if (
      productMapping(productName) !== 'Progresa Contigo' &&
      dgiMapping(productName) !== 'DGI Contribution'
    ) {
      setSaveAppContactScreenDirty(true);
      setIsScreenTwoDirty(true);
      setIsScreenThreeDirty(true);
      setIsScreenFourDirty(true);
      setIsScreenFiveDirty(true);
      setIsFundingScreenDirty(true);
    }
    if (
      productMapping(productName) === ELITE_ACCOUNT_EN ||
      productMapping(productName) === 'Libre' ||
      productMapping(productName) === 'Ahorra'
    ) {
      setIsScreenTwoDirty(true);
      setIsScreenThreeDirty(true);
      setIsScreenFourDirty(true);
      setIsScreenFiveDirty(true);
      leadGenerationContext.setIsFeatureScreenDirty(true);
      setIsFundingScreenDirty(true);
    }
    switch (e.target.name) {
      case 'lastName':
        lastNamechange(e, leadGenerationContext);
        break;
      case 'email':
        emailChange(e, leadGenerationContext);
        break;
      default:
    }
  };

  const handleClose = () => {
    // if (getProductForBusinessMapping(productName)) {
    //   setSaveAppContactInfo((prevState) => ({ ...prevState, ssn: '', dob: '' }));
    //   setSaveAppContactInfoErrors((prevState) => ({
    //     ...prevState,
    //     ssn: '',
    //     dob: '',
    //   }));
    // }
    setIsApplicationSaved(false);
    setIsModalSaveLater(false);
  };

  /**
   * Display invalid age error message to the date of birth field if user age less than 18 */
  const handleAgeRuleValidation = (message) => {
    if (message && message.length > 0) {
      setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        dob: `* ${message}`,
      }));
      setDobDisable(false);
      const element = document.getElementById(DOB_DAY);
      if (element) {
        element.focus();
      }
    } else {
      setSaveAppContactInfoErrors((prevState) => ({ ...prevState, dob: '' }));
    }
  };

  let emailValid = false;
  let emailErrorType = null;
  let emailValidationData = null;
  const emailVaildCheck = (data) => {
    if (data.status === 'Valid') {
      emailValid = true;
      setSaveAppContactInfoErrors((prevState) => ({ ...prevState, email: '' }));
    } else {
      setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        email: `* ${data.message}`,
      }));
      emailErrorType = getEmailErrorType(data.message);
    }
  };

  /**
   * email melisa validation and invalid format message check */
  const emailValidation = async () => {
    if (saveAppContactInfoErrors?.email === INVALID_FORMAT_ERR) {
      const emailElement = document.getElementById('email');
      emailElement.setSelectionRange(0, saveAppContactInfo?.email?.length);
      emailElement.focus();
    } else if (saveAppContactInfo?.email) {
      const response = await getEmailVerificationData(
        saveAppContactInfo?.email,
        leadGenerationContext.accessToken
      );
      if (response && response.status === 200 && response.data[0]) {
        const data = response.data[0];
        emailVaildCheck(data);
        emailValidationData = data;
      }
      /**
       * email field domain and mailbox error message check */
      if (emailErrorType) {
        let emailhighlightStartIndex = 0;
        let emailhighlightEndIndex = 0;
        const emailElement = document.getElementById('email');
        if (emailErrorType === 'Mailbox_Error') {
          emailhighlightEndIndex = saveAppContactInfo?.email.indexOf('@');
          emailElement.setSelectionRange(emailhighlightStartIndex, emailhighlightEndIndex);
          emailElement.focus();
        }
        if (emailErrorType === 'Domain_Error') {
          emailhighlightStartIndex = saveAppContactInfo.email.indexOf('@') + 1;
          emailhighlightEndIndex = saveAppContactInfo?.email.length;
          emailElement.setSelectionRange(emailhighlightStartIndex, emailhighlightEndIndex);
          emailElement.focus();
        }
      }
    }
    return { valid: emailValid, validationData: emailValidationData };
  };
  const isFormValid = () => {
    let valid = true;
    if (!saveAppContactInfo?.lastName) {
      valid = false;
      setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        lastName: MANDATORY_FIELD_ERR,
      }));
    }
    if (saveAppContactInfo?.dob?.length < 10) {
      valid = false;
      setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        dob: MANDATORY_FIELD_ERR,
      }));
    }
    if (!saveAppContactInfo?.email) {
      valid = false;
      setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        email: MANDATORY_FIELD_ERR,
      }));
    }
    if (!saveAppContactInfo?.ssn) {
      valid = false;
      setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        ssn: MANDATORY_FIELD_ERR,
      }));
    }
    for (const key in saveAppContactInfoErrors) {
      if (
        saveAppContactInfoErrors[key] &&
        saveAppContactInfoErrors[key] !== '* You must be 18 years or older to open this account'
      ) {
        valid = false;
        break;
      }
    }
    return valid;
  };
  const saveAppCheck = (fields) => {
    for (const field of fields) {
      if (!saveAppContactInfo[field]) {
        const element = document.getElementById(field);
        if (element) {
          element.focus();
          break;
        }
      }
    }
  };
  const saveAppErrorFocus = () => {
    let isElementFound = false;
    const fields = ['lastName', 'email', 'dob_day', 'ssn'];
    for (const field of fields) {
      if (saveAppContactInfoErrors[field]) {
        const element = document.getElementById(field);
        if (element) {
          isElementFound = true;
          element.focus();
          break;
        }
      }
    }
    if (!isElementFound) {
      saveAppCheck(fields);
    }
  };

  const validateResult = (result) => {
    if (result && result.status === 200) {
      validateResult1(result);
    } else if (
      result.status === 400 &&
      result.data ===
        'Duplicate Application!. Please submit your previous application to save a new one'
    ) {
      setIsValidating(false);
      setSnackbarstate(true);
      setDisableModalCondition(true);
    } else if (result.status === 400 && result.data && result.data.MinAge) {
      if (result.data.MinAge[0]?.message) {
        setIsValidating(false);
        handleAgeRuleValidation(result.data.MinAge[0].message);
      }
    } else {
      if (saveAppContactInfoErrors?.email === INVALID_FORMAT_ERR) {
        const emailElement = document.getElementById('email');
        emailElement.setSelectionRange(0, saveAppContactInfo?.email?.length);
        emailElement.focus();
      } else {
        saveAppErrorFocus();
      }
      setIsValidating(false);
    }
  };
  const saveFinalData = async (validation) => {
    const result = await saveData(validation?.validationData);
    validateResult(result);
  };

  const handleCustomerValidation = async (validCheck, customerValidation, emailvalid) => {
    if (customerValidation && customerValidation.status === 200) {
      if (customerValidation.data.Status === 'Success') {
        if (customerValidation.data.InvalidCustomer === 'false') {
          setIsModal(false);
          await saveFinalData(emailvalid);
        } else if (customerValidation.data.InvalidCustomer === 'true') {
          setIsModal(true);
          if (productMapping(productName) !== PROGRESA_CONTIGO) {
            const usersToString = customerValidation.data.ExceptionCode;
            await saveKnockOffData(leadGenerationContext, KNOCK_OFF_MSSG_BODY, '', usersToString);
          }
          setIsValidating(false);
        }
      }
    } else if (customerValidation?.status === 500) {
      setIsValidating(false);
      await saveFinalData(emailvalid);
    } else {
      setIsValidating(false);
    }
  };
  const saveData = async (emailvalidation) => {
    const data = {};
    data.applicationId = leadGenerationContext.applicationId;
    data.firstName = leadGenerationContext.firstName;
    data.lastName = saveAppContactInfo.lastName;
    data.dob = saveAppContactInfo.dob;
    data.email = saveAppContactInfo.email;
    data.ssn = saveAppContactInfo.ssn;
    data.language = leadGenerationContext.language;
    data.location = leadGenerationContext.location;
    data.emailVerificationResponse = emailvalidation;

    let response = null;

    response = await saveContinueInformation(data, leadGenerationContext.accessToken);

    return response;
  };

  const validateResult1 = (result) => {
    setIsValidating(false);
    setSaveAppContactScreenDirty(false);
    if (!savedDataFlow) {
      setIsApplicationSaved(true);
    }
    setContinueModal(true);
    setIsModalSaveLater(false);
    history.push(
      `/${location?.toLowerCase()}/${language?.toLowerCase()}/retail-form/${productNameURLMapping(
        productName
      )}/${currentPage}`
    );
  };

  const validateEmail = async (valid, validation) => {
    if (valid && validation && validation?.valid === true) {
      const ssnId = saveAppContactInfo.ssn;
      const ssnId2 = ssnId.replace(/-/g, '');
      if (productMapping(productName) && currentPage === PERSONAL_INFO) {
        const customerValidation = await getCustomerValidation(ssnId2, leadGenerationContext);
        handleCustomerValidation(valid, customerValidation, validation);
        setCustomerType(customerValidation?.data?.CustomerType);
      } else {
        await saveFinalData(validation);
      }
    }
  };

  const populateDobSSN = () => {
    setPersonalInformation((prevState) => ({
      ...prevState,
      dob: saveAppContactInfo.dob,
    }));
    setPersonalInformationErrors((prevState) => ({
      ...prevState,
      dob: '',
    }));
    if (!personalInformation.ssn) {
      setPersonalInformation((prevState) => ({
        ...prevState,
        ssn: saveAppContactInfo.ssn,
      }));
    }
  };
  const handleLinkClick = (e) => {
    setIsModalSaveLater(false);
    history.push(`/${location?.toLowerCase()}/${language?.toLowerCase()}/verification-screen`);
  };
  const handleClickDis = (e) => {
    e.preventDefault();
  };
  const handleSaveContinue = async (e) => {
    e.preventDefault();
    if (saveAppContactScreenDirty) {
      const valid = isFormValid();

      if (valid) {
        populateDobSSN();
        setIsValidating(true);
        const validation = await emailValidation();
        await validateEmail(valid, validation);
      } else {
        const fields = ['lastName', 'email', 'dob_day', 'ssn'];
        saveAppCheck(fields);
      }
    }
  };

  return (
    <Modal
      className={classes.backdrop}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(233, 233, 235, 0.78)',
        },
      }}
      role="region"
      aria-labelledby="dialog1BusinessDesc dialog2BusinessDesc"
      open={setIsModalSaveLater}
    >
      <div style={{ outline: 'none', margin: 'auto' }}>
        <Grid container>
          <Card className={classes.root}>
            <CardContent className={classes.card_content}>
              <Grid container style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                  disableFocusRipple
                  src={CloseIconIMG}
                  size="small"
                  name="closeicon"
                  onClick={handleClose}
                  autoFocus
                  width="30px"
                  height="30px"
                  data-testid="close_id"
                  id="close_id"
                >
                  <img
                    id="closeImg_id"
                    alt={t(`close`, { defaultValue: 'close' })}
                    src={CloseIconIMG}
                    width="30px"
                    height="30px"
                    className={classes.closeStyle}
                  />
                </IconButton>
              </Grid>
              <span className={classes.saveImg}>
                <img src={saveLogoIMG} width="42px" height="54px" />
              </span>
              <Grid item className={classes.textTitlee}>
                <span className={classes.textTitle}>
                  {t(SAVE_YOUR_PROGRESS, { defaultValue: SAVE_YOUR_PROGRESS })}

                  <span className={classes.textTitleFirstName}>
                    {firstName ? t(`${firstName}!`) : ''}
                  </span>
                </span>
              </Grid>

              <Grid container className={classes.contact_container} spacing={0}>
                <Grid
                  container
                  role="region"
                  aria-labelledby="PleaseVerifyYourDetails"
                  aria-describedby="saveAppContactDisclaimerId"
                >
                  <Grid container xs={12} className={classes.subTitle}>
                    {snackbarstate ? (
                      <Snackbar
                        id="snackbarId"
                        data-testid="snackbar_testid"
                        open={snackbarstate}
                        className={classes.mainContainer}
                        classes={{ root: classes.snackbarRoot }}
                        ContentProps={{
                          className: classes.snackbarStyleViaContentProps,
                        }}
                        style={{ zIndex: 1 }}
                        action={
                          <IconButton
                            id="closeIconId"
                            size="small"
                            style={{
                              position: 'absolute',
                              left: '803px',
                              top: '21px',
                            }}
                            color={COLOR1}
                            onClick={handleCloseBAR}
                            data-testid="snackbarclose_testid"
                          >
                            <img
                              id="closeImgId"
                              src={SnackbarCloseIcon}
                              height="16px"
                              width="16px"
                              alt={t('close')}
                            />
                          </IconButton>
                        }
                        message={
                          <div className={`${classes.message} ${classes.dd}`}>
                            <span style={{ marginRight: '12px' }}>
                              <IconButton
                                classes={{ sizeSmall: classes.snackBarBtn }}
                                style={{ marginTop: 0 }}
                                size="small"
                                color={COLOR1}
                              >
                                <img src={snackbarInfo} alt="Icon" height="24px" width="24px" />
                              </IconButton>
                            </span>
                            <Typography
                              style={{
                                textAlign: 'left',
                                color: '#D71721',
                                fontFamily: 'Arial',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '120%',
                              }}
                            >
                              {t(
                                'There is already a saved application with the given information. Please continue your saved application by',
                                {
                                  defaultValue:
                                    'There is already a saved application with the given information. Please continue your saved application by',
                                }
                              )}
                              <Link
                                name="Link"
                                style={{ textDecoration: 'underline', color: '#D71721' }}
                                data-testid="needLink_testId"
                                onClick={handleLinkClick}
                                href="javascript:void(0)"
                                onContextMenu={handleClickDis}
                              >
                                {t(' clicking here ', {
                                  defaultValue: ' clicking here ',
                                })}
                              </Link>
                              {t('or continue this application without saving.', {
                                defaultValue: 'or continue this application without saving.',
                              })}
                            </Typography>
                          </div>
                        }
                      />
                    ) : (
                      <Typography
                        className={`${classes.verify_details}`}
                        id="PleaseVerifyYourDetails"
                        variant="h2"
                        aria-label={t(PLEASE_VERIFY_YOUR_DETAILS, {
                          defaultValue: PLEASE_VERIFY_YOUR_DETAILS,
                        })}
                      >
                        {t(PLEASE_VERIFY_YOUR_DETAILS, {
                          defaultValue: PLEASE_VERIFY_YOUR_DETAILS,
                        })}
                      </Typography>
                    )}
                  </Grid>
                  <Grid container className={classes.margin_adjust_for_top_grid}>
                    <Grid item className={classes.gridStyles} xs={12} md={6}>
                      <CustomInput
                        id="lastName"
                        dataTestId="lastName_id"
                        required
                        inputLabelAriaLabel={`${t(LAST_NAME, {
                          defaultValue: LAST_NAME,
                        })} ${t(REQUIRED, { defaultValue: REQUIRED })}`}
                        label={t(LAST_NAME, { defaultValue: LAST_NAME })}
                        handleChange={handleChange}
                        placeholder={t('Enter Last Name', {
                          defaultValue: 'Enter Last Name',
                        })}
                        fullWidth
                        disabled={
                          getProductForBusinessMapping(productName)
                            ? (!!idUploadStatus && vouchedData?.lastName !== null) ||
                              (businessContactInformation.lastName !== '' &&
                                businessContactInformationErrors.lastName === '' &&
                                saveAppContactInfo?.lastName !== '' &&
                                saveAppContactInfo?.lastName?.length === 15 &&
                                saveAppContactInfoErrors?.lastName === '')
                            : (!!idUploadStatus && vouchedData?.lastName !== null) ||
                              (personalInformation?.lastName !== '' &&
                                saveAppContactInfo?.lastName !== '' &&
                                saveAppContactInfo?.lastName?.length === 15 &&
                                saveAppContactInfoErrors?.lastName === '' &&
                                personalInformationErrors?.lastName === '')
                        }
                        inputProps={{ maxLength: 15, 'data-testid': 'last-name' }}
                        value={saveAppContactInfo?.lastName ? saveAppContactInfo.lastName : ''}
                        error={
                          saveAppContactInfoErrors?.lastName
                            ? saveAppContactInfoErrors.lastName
                            : ''
                        }
                      />
                      <span
                        className={classes.error_message}
                        role="alert"
                        data-testid="last-name-error"
                      >
                        {t(saveAppContactInfoErrors?.lastName, {
                          defaultValue: saveAppContactInfoErrors?.lastName,
                        })}
                      </span>
                    </Grid>
                    <Grid item className={classes.gridStyles} xs={12} md={6}>
                      <CustomInput
                        id="email"
                        dataTestId="email_id"
                        required
                        inputLabelAriaLabel={`${t(EMAIL, {
                          defaultValue: EMAIL,
                        })} ${t(REQUIRED, { defaultValue: REQUIRED })}`}
                        label={t(EMAIL, {
                          defaultValue: EMAIL,
                        })}
                        handleChange={handleChange}
                        placeholder={t('Enter Email Address', {
                          defaultValue: 'Enter Email Address',
                        })}
                        fullWidth
                        disabled={
                          getProductForBusinessMapping(productName)
                            ? businessContactInformation.email &&
                              businessContactInformationErrors.email === ''
                            : contactInformation?.email
                        }
                        inputProps={{ 'data-testid': 'email', maxLength: 40 }}
                        value={saveAppContactInfo?.email ? saveAppContactInfo.email : ''}
                        error={
                          saveAppContactInfoErrors?.email ? saveAppContactInfoErrors.email : ''
                        }
                      />
                      <span
                        role="alert"
                        className={classes.error_message}
                        data-testid="email-error"
                      >
                        {t(saveAppContactInfoErrors?.email, {
                          defaultValue: saveAppContactInfoErrors?.email,
                        })}
                      </span>
                    </Grid>

                    <Grid item className={classes.gridStyles} xs={12} md={6}>
                      <CustomDob
                        label={t(`Date of birth`, {
                          defaultValue: `Date of birth`,
                        })}
                        id="dob_saveapp"
                        maxDatePicker={new Date()}
                        placeholder={commonTernary(
                          language === EN,
                          t(DATE_FORMAT_EN, { defaultValue: DATE_FORMAT_EN }),
                          t(DATE_FORMAT_ES, { defaultValue: DATE_FORMAT_ES })
                        )}
                        inputFormat={commonTernary(
                          language === EN,
                          t('MM/DD/YYYY', { defaultValue: 'MM/DD/YYYY' }),
                          t('DD/MM/YYYY', { defaultValue: 'DD/MM/YYYY' })
                        )}
                        dataTestIdForLabel="dobTestId"
                        dataTestId="date-of-birth"
                        value={saveAppContactInfo.dob}
                        onChange={(newValue) =>
                          handleDOBchangeSaveAppContact(newValue, leadGenerationContext)
                        }
                        fullWidth
                        required
                        location={location}
                        language={language}
                        disabled={
                          getProductForBusinessMapping(productName)
                            ? dobDisable || (!!idUploadStatus && vouchedData?.birthDate !== null)
                            : dobDisable || (!!idUploadStatus && vouchedData?.birthDate !== null)
                        }
                        dob={commonTernary(saveAppContactInfo?.dob, saveAppContactInfo?.dob, '')}
                      />

                      <span
                        role="alert"
                        tabIndex="-1"
                        className={classes.error_message}
                        data-testid="dobError"
                      >
                        {t(saveAppContactInfoErrors?.dob, {
                          defaultValue: saveAppContactInfoErrors?.dob,
                        })}
                      </span>
                    </Grid>

                    <Grid item className={`${classes.gridStyles} dob-input-adjust`} xs={12} md={6}>
                      <Grid item xs={12}>
                        {isAndroid && osVersion?.split('.')[0] <= 10 ? (
                          <SSNAndroid
                            id="ssn"
                            handleKeyDown={handleKeyDownSSNAndroid}
                            error={commonUtility(saveAppContactInfoErrors?.ssn)}
                            ssn={saveAppContactInfo?.ssn}
                            disabled={
                              getProductForBusinessMapping(productName)
                                ? (ownership || signerCheck) &&
                                  businessAccountInfo.ssn !== '' &&
                                  businessAccountInfoError.ssn === ''
                                : personalInformation?.ssn !== '' &&
                                  personalInformationErrors?.ssn === '' &&
                                  saveAppContactInfoErrors?.ssn === ''
                            }
                          />
                        ) : (
                          <SSN
                            id="ssn"
                            autoFocus="autoFocus"
                            disabled={
                              getProductForBusinessMapping(productName)
                                ? (ownership || signerCheck) &&
                                  businessAccountInfo.ssn !== '' &&
                                  businessAccountInfoError.ssn === ''
                                : personalInformation?.ssn !== '' &&
                                  personalInformationErrors?.ssn === '' &&
                                  saveAppContactInfoErrors?.ssn === ''
                            }
                            handleKeyDown={handleKeyDownSSN}
                            error={commonUtility(saveAppContactInfoErrors?.ssn)}
                            ssn={saveAppContactInfo?.ssn}
                          />
                        )}

                        <span className={classes.error_message} role="alert">
                          {t(saveAppContactInfoErrors?.ssn, {
                            defaultValue: saveAppContactInfoErrors?.ssn,
                          })}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} className={classes.consent_message}>
                    <div style={{ display: 'flex' }}>
                      <img
                        src={saveAppDisclaimerIcon}
                        width="15px"
                        height="15px"
                        tabIndex="0"
                        alt={t(SAVE_APP_CONTACT_DISCLAIMER, {
                          defaultValue: SAVE_APP_CONTACT_DISCLAIMER,
                        })}
                        className={classes.tooltipmsg}
                      />
                      <Typography
                        id="saveAppContactDisclaimerId"
                        className={classes.contact_disclaimer}
                        style={{ marginLeft: '13px' }}
                      >
                        {t('modaltext', {
                          defaultValue: SAVE_APP_CONTACT_DISCLAIMER,
                        })}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}
                xs={12}
                className={classes.backContinue}
              >
                <Grid
                  item
                  className={`${styledClasses.mobileButtons} ${styledClasses.backBtnGrid}`}
                >
                  <Button
                    disableFocusRipple
                    className={classes.back_button}
                    data-testid="backButtonId"
                    onClick={handleClose}
                  >
                    {t('Back', { defaultValue: 'Back' })}
                  </Button>
                </Grid>
                <Grid
                  item
                  className={`${styledClasses.mobileButtons} ${styledClasses.ContinueBtnGrid}`}
                >
                  <Button
                    disableFocusRipple
                    disabled={disableModalCondition}
                    classes={{ disabled: classes.disabled_button }}
                    className={classes.saveAndExit}
                    data-testid="SaveAndContinueButtonId"
                    onClick={handleSaveContinue}
                  >
                    {t('Save & Continue', { defaultValue: 'Save & Continue' })}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {isValidating && (
          <div className="loader-saveApp-style">
            <Loader />
          </div>
        )}
        <KnockOffModal
          message={{ message: KNOCK_OFF_MSSG_BODY, isKnockOff: true }}
          setIsModal={setIsModal}
          openModal={isModal}
        />
      </div>
    </Modal>
  );
};
export default withRouter(SaveAppContactInfo);

SaveAppContactInfo.propTypes = {
  currentPage: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};
