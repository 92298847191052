import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import LeadGenerationContext from '../../../context/LeadGenerationContext';
import { businessMapping } from '../../../services/businessMapping';
import { ProductHeadingStyles } from './ProductHeadingStyles';

export const ProductHeading = ({ productName, stepDescription, id, ariaDescribedbyId }) => {
  const classes = ProductHeadingStyles();
  const leadGenerationContext = useContext(LeadGenerationContext);

  const changeProductNmIncomingRTT = () => {
    let product;
    if (businessMapping(productName) && leadGenerationContext.language === 'EN') {
      switch (productName) {
        case 'Prestamo Comercial':
          product = 'Commercial Loan';
          break;
        case 'Tarjeta de Credito Comercial':
          product = 'Commercial Credit Card';
          break;

        case 'Linea de Credito Comercial':
          product = 'Commercial Line of Credit';
          break;

        case 'CD Comercial':
          product = 'Commercial CD';
          break;

        case 'SIN FINES DE LUCRO':
          product = 'NON-PROFIT';
          break;

        default:
          product = productName;
      }
    } else {
      switch (productName) {
        case 'Prestamo Comercial':
          product = 'Préstamo Comercial';
          break;
        case 'Tarjeta de Credito Comercial':
          product = 'Tarjeta de Crédito Comercial';
          break;

        case 'Linea de Credito Comercial':
          product = 'Línea de Crédito Comercial';
          break;

        case 'CD Comercial':
          product = 'Comercial CD';
          break;

        case 'SIN FINES DE LUCRO':
          product = 'SIN FINES DE LUCRO';
          break;

        default:
          product = productName;
      }
    }
    return product;
  };

  return (
    <>
      <Typography
        id={id}
        data-testid="heading_testid"
        role="heading"
        variant="h1"
        className={classes.heading_info}
      >
        {businessMapping(productName) ? changeProductNmIncomingRTT() : productName}
      </Typography>
      <Typography
        id={ariaDescribedbyId}
        data-testid="desc_testid"
        variant="h2"
        role="heading"
        aria-label={stepDescription}
        className={classes.sub_heading_info}
      >
        {stepDescription}
      </Typography>
    </>
  );
};

ProductHeading.propTypes = {
  productName: PropTypes.string,
  stepDescription: PropTypes.string,
  id: PropTypes.string,
  ariaDescribedbyId: PropTypes.string,
};
