import {
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonStyles } from '../CommonStyles';
import showIcon from '../../../images/Show_Eye.svg';
import hideIcon from '../../../images/hideIconssn.svg';
import { ORIENTAL_ORANGE, REQUIRED } from '../../../constants';
import { commonTernary } from '../../commonCode';
import LeadGenerationContext from '../../../context/LeadGenerationContext';
import CustomTooltip from '../../Tooltip/CustomTooltip';

export const SSNAndroid = (props) => {
  const { t } = useTranslation();
  const classes = CommonStyles();
  const [fakeVal, setFakeVal] = useState('');
  const [showFakeVal, setShowFakeVal] = useState(false);
  const [ssnAltText, setSsnAltText] = useState(hideIcon);
  const leadGenerationContext = useContext(LeadGenerationContext);
  const { handleKeyDown, error, ssn, isCommercialPage, id, disabled, isFlowTypeETB, whySSN } =
    props;

  const showHideHandler = (handler) => {
    const len = ssn?.length;
    let stars;
    switch (len) {
      case 1:
        stars = 'X';
        break;
      case 2:
        stars = 'XX';
        break;
      case 3:
        stars = 'XXX';
        break;
      case 4:
        stars = 'XXX-';
        break;
      case 5:
        stars = 'XXX-X';
        break;
      case 6:
        stars = 'XXX-XX';
        break;
      case 7:
        stars = 'XXX-XX-';
        break;
      case 0:
        stars = '';
        break;

      default:
        stars = 'XXX-XX-';
        break;
    }
    const ssnSubstring = ssn?.substring(7);
    const result = stars + ssnSubstring;
    setFakeVal(result);
    if (showFakeVal === false) {
      setShowFakeVal(true);
    } else if (handler !== 'blur') {
      setShowFakeVal(false);
    }
  };

  useEffect(() => {
    if (showFakeVal) {
      setSsnAltText('Show SSN number');
    } else setSsnAltText('Hide SSN number');
  }, [showFakeVal]);

  useEffect(() => {
    if (ssn?.length === 11) {
      showHideHandler('click');
    }
  }, []);

  function areAllNumbersSameInSSN(ssnWithHyphens) {
    const newSSNNumber = ssnWithHyphens.replace(/-/g, '');
    if (newSSNNumber && newSSNNumber.length === 9) {
      const numbersArray = Array.from(newSSNNumber);
      const firstNumber = numbersArray[0];
      return numbersArray.every((number) => number === firstNumber);
    }
    return false;
  }

  const handleKeyBlur = (e) => {
    if (e?.relatedTarget === null || e?.relatedTarget?.id !== 'ssnHideShowBtn') {
      showHideHandler('blur');
    }
    const result = areAllNumbersSameInSSN(ssn);
    if (result) {
      leadGenerationContext.setBusinessAccountInfoError((prevState) => ({
        ...prevState,
        ssn: '* Invalid Format',
      }));
      leadGenerationContext.setSaveAppContactInfoErrors((prevState) => ({
        ...prevState,
        ssn: '* Invalid Format',
      }));
      leadGenerationContext.setPersonalInformationErrors((prevState) => ({
        ...prevState,
        ssn: '* Invalid Format',
      }));
    }
  };

  const ssnField = () => {
    let jsx;
    if (!isCommercialPage) {
      jsx = (
        <span>
          {t('Social Security Number Commercial', {
            defaultValue: 'Social Security Number',
          })}

          <span
            style={{
              color: ORIENTAL_ORANGE,
              position: 'absolute',
              fontSize: '22px',
              marginLeft: '4px',
            }}
          >
            *
          </span>
        </span>
      );
    } else if (isFlowTypeETB) {
      jsx = (
        <span>
          {t('Social security number', {
            defaultValue: 'Social Security Number',
          })}

          <span
            style={{
              color: ORIENTAL_ORANGE,
              position: 'absolute',
              fontSize: '22px',
              marginLeft: '4px',
            }}
          >
            *
          </span>
        </span>
      );
    } else {
      jsx = (
        <span>
          {t('Social Security Number', {
            defaultValue: 'Social Security Number',
          })}

          <span
            style={{
              color: ORIENTAL_ORANGE,
              position: 'absolute',
              fontSize: '22px',
              marginLeft: '4px',
            }}
          >
            *
          </span>
        </span>
      );
    }
    return jsx;
  };
  return (
    <>
      <InputLabel
        htmlFor="ssn"
        aria-label={`${t('Social Security Number', {
          defaultValue: 'Social Security Number',
        })} ${t(REQUIRED, { defaultValue: REQUIRED })}`}
        className={classes.label_title}
      >
        {whySSN ? (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>
              {t('Social Security Number', {
                defaultValue: 'Social Security Number',
              })}

              <span
                style={{
                  color: ORIENTAL_ORANGE,
                  position: 'absolute',
                  fontSize: '22px',
                  marginLeft: '4px',
                }}
              >
                *
              </span>
            </span>
            <span className={`${classes.ssnTooltip}`}>
              <CustomTooltip
                custom
                customAriaLabel={`${t('ssnTooltip', {
                  defaultValue: 'ssnTooltip',
                })} ${t('More Info', { defaultValue: 'More Info' })}`}
                placement="top"
                text={t(`Politically Exposed Tooltip`, {
                  defaultValue: `The term "politically exposed person" generally includes a current or former senior foreign political figure, their immediate family, and their close associates.`,
                })}
              />
              <Typography className={`${classes.ssnTextTooltip}`}>
                {t('Why do we need your SSN?', {
                  defaultValue: 'Why do we need your SSN?',
                })}
              </Typography>
            </span>
          </div>
        ) : (
          ssnField()
        )}
      </InputLabel>
      <OutlinedInput
        id={id}
        name="ssn"
        className={commonTernary(
          disabled,
          `${classes.outlined_inputs} ${classes.outlined_inputs_disabled}`,
          `${classes.outlined_inputs}`
        )}
        placeholder={
          !isCommercialPage
            ? t('Enter social security number', {
                defaultValue: 'Enter social security number',
              })
            : t('Enter social security number commercial', {
                defaultValue: 'Enter social security number',
              })
        }
        fullWidth
        autoComplete="off"
        disabled={!!disabled}
        value={showFakeVal ? fakeVal : ssn}
        onFocus={() => {
          if (showFakeVal) {
            setShowFakeVal(false);
          }
        }}
        onChange={handleKeyDown}
        onBlur={handleKeyBlur}
        inputProps={{
          maxLength: 11,
          inputMode: 'numeric',
          'data-testid': 'SSNAndroid',
        }}
        style={
          error?.ssn
            ? { background: '#5558582B 0% 0% no-repeat padding-box', border: '1px solid red' }
            : {}
        }
        endAdornment={
          <InputAdornment
            position="end"
            style={{ marginLeft: '0', marginRight: '-7px', maxWidth: '35px' }}
          >
            <IconButton
              id="ssnHideShowBtn"
              data-testid="shouHideBtn_id"
              onClick={() => {
                showHideHandler('click');
                document.getElementById('ssn')?.focus();
              }}
              tabIndex="0"
            >
              <img
                alt={ssnAltText}
                src={showFakeVal ? hideIcon : showIcon}
                style={{ marginTop: '2px' }}
              />
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
};

SSNAndroid.propTypes = {
  handleKeyDown: PropTypes.func,
  error: PropTypes.string,
  ssn: PropTypes.string,
  isCommercialPage: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  isFlowTypeETB: PropTypes.bool,
  whySSN: PropTypes.bool,
};
