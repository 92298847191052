import moment from 'moment';
import { PropTypes } from 'prop-types';
import { getWeekday } from '../../utils/CommonFunctions';
import logo from '../images/logo.png';
import { ChatWrapperCss } from '../../utils/WrapperCss';
import { ORIENTAL_BANK_LOGO } from '../../Constants';

const useChatWrapperCss = ChatWrapperCss();
const ChatLogo = (props) => {
  const WrapperCss = useChatWrapperCss();
  const { englishBot } = props;
  return (
    <>
      <div className={WrapperCss.logo_container}>
        <img src={logo} alt={ORIENTAL_BANK_LOGO} width="70" height="70" tabIndex="101" />
      </div>
      <div className={WrapperCss.today_date}>
        <span>
          {getWeekday(englishBot)}
          {moment().format(', h:mm A')}
        </span>
      </div>
    </>
  );
};
ChatLogo.propTypes = {
  englishBot: PropTypes.bool,
};
export default ChatLogo;
