import { IconButton } from '@livechat/ui-kit';
import { PropTypes } from 'prop-types';
import {
  CONNECT_LIVE_AGENT_ENG,
  CONNECT_LIVE_AGENT_ESP,
  CONTINUE_WITH_CHATBOT_ENG,
  CONTINUE_WITH_CHATBOT_ESP,
  TRY_SOMETHING_ELSE_ENG,
  TRY_SOMETHING_ELSE_ESP,
} from '../../Constants';

const ReconnectFlow = (props) => {
  const { buttonMessageOnbackToLexTrigger, englishBot, pegaConnectTrigger, ReconnectToAwsConnect } =
    props;
  const btn1 = englishBot ? 'Yes' : 'Sí';
  const btn2 = englishBot ? CONNECT_LIVE_AGENT_ENG : CONNECT_LIVE_AGENT_ESP;
  const btn3 = englishBot ? CONTINUE_WITH_CHATBOT_ENG : CONTINUE_WITH_CHATBOT_ESP;
  const btn3response = englishBot ? TRY_SOMETHING_ELSE_ENG : TRY_SOMETHING_ELSE_ESP;
  const backToChatbot = () => {
    buttonMessageOnbackToLexTrigger(btn3response, false);
  };
  return (
    <div
      className="quick-replies"
      style={{ display: 'block', justifyContent: 'left', textAlign: 'left' }}
    >
      <IconButton onClick={pegaConnectTrigger} tabIndex="102" data-testid="button_testid1">
        {btn1}
      </IconButton>
      <IconButton onClick={ReconnectToAwsConnect} tabIndex="102" data-testid="button_testid2">
        {btn2}
      </IconButton>
      <IconButton onClick={backToChatbot} tabIndex="102" data-testid="button_testid3">
        {btn3}
      </IconButton>
    </div>
  );
};
ReconnectFlow.propTypes = {
  englishBot: PropTypes.bool,
  buttonMessageOnbackToLexTrigger: PropTypes.func,
  pegaConnectTrigger: PropTypes.func,
  ReconnectToAwsConnect: PropTypes.func,
};
export default ReconnectFlow;
