import { makeStyles } from '@material-ui/core';
import {
  COLOR4,
  COLOR1,
  WHITE,
  RADIO_COLOR,
  COLOR5,
  FONT_BOLD_16_18,
  COLOR2,
} from '../../constants';

export const SaveAppContactInfoStyles = () =>
  makeStyles(
    (theme) => ({
      disabled_button: {
        '&.MuiButton-root.Mui-disabled': {
          background: `#E4E9F2`,
          border: `#E4E9F2`,
          borderRadius: '25px',
          color: 'rgba(143, 155, 179, 0.5)',
          width: '228px',
          height: '48px',
          font: 'normal normal bold 16px/18px Arial',
          letterSpacing: '1.01px',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: '#E4E9F2',
          },
          [theme.breakpoints.down('600')]: {
            width: '100%',
          },
        },
      },
      mainContainer: {
        position: 'relative',
        paddingRight: '20px',
      },
      snackbarRoot: {
        width: '100%',
        height: '70px',
        position: 'relative',
        disaplay: 'flex',
        justifyContent: 'flex-end',
        // zIndex: '10',
        // top: '-10px',
        [theme.breakpoints.down('1310')]: {
          height: 'auto',
        },
        [theme.breakpoints.down('600')]: {
          left: 'unset',
          right: 'unset',
        },
      },
      snackBarBtn: {
        padding: '0px',
        // height: '20px',
        color: '#4F4F4F',
      },

      snackbarStyleViaContentProps: {
        color: '#D71721',
        borderRadius: '6px',
        border: '2px solid #D71721',
        backgroundColor: '#FFF2F2',
        width: '100%',

        fontFamily: 'Arial',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '120%',

        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'nowrap',
        minWidth: 'unset',
      },
      message: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '&.MuiSnackbarContent-message': {
          paddingTop: '0px',
          paddingBottom: '0px',
          paddingRight: '0px',
          paddingLeft: '0px',
        },
      },
      dd: {
        '&.MuiSnackbarContent-message': {
          paddingTop: '0px',
          paddingBottom: '0px',
          paddingRight: '0px',
          paddingLeft: '0px',
        },
      },
      card_content: {
        textAlign: 'center',
        padding: '0px !important',
        // marginTop: '30px',
      },
      editIcon: {
        paddingBottom: '5px',
        textTransform: 'none',
      },
      saveAndExit: {
        background: `#661C69`,
        color: COLOR4,
        font: 'normal 700 16px/18px Arial',
        width: '170px',
        height: '45px',
        margin: '0 15px 0 0',
        textTransform: 'none',
        borderRadius: '56px',
        '&:hover': {
          backgroundColor: RADIO_COLOR,
        },
        '&:focus': {
          outline: `2px solid ${RADIO_COLOR}`,
          outlineOffset: '2px',
        },
        [theme.breakpoints.down('600')]: {
          width: '90%',
          marginBottom: '20px',
        },
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: WHITE,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px',
        overflowY: 'scroll',
      },
      root: {
        // display: 'flex',
        alignItems: 'center',
        // maxWidth: '332px',
        maxWidth: '779px',
        // maxHeight: '246px',
        background: '#FFFFFF',
        boxShadow: '0px 4px 12px rgba(62, 64, 70, 0.08)',
        borderRadius: '10px',
        [theme.breakpoints.down('400')]: {
          maxHeight: 'unset',
        },
      },
      textTitle: {
        font: 'normal 700 32px/38px Arial',
        color: COLOR1,
        letterSpacing: '0.12px',
        // marginTop: '42px',
        marginLeft: '26px',
        marginRight: '26px',
        // fontFeatureSettings: 'liga' off;
        [theme.breakpoints.down('560')]: {
          marginLeft: '0px',
          marginRight: '0px',
        },
      },
      textTitlee: {
        marginTop: '15px',
        [theme.breakpoints.down('960')]: {
          marginTop: '10px',
        },
      },
      subTitle: {
        marginTop: '5px',
        [theme.breakpoints.down('960')]: {
          marginTop: '3px',
        },
      },

      textTitleFirstName: {
        font: 'normal 700 32px/38px Arial',
        color: COLOR5,
        letterSpacing: '0.12px',
        marginTop: '42px',
        marginLeft: '8px',
        marginRight: '26px',
        [theme.breakpoints.down('400')]: {
          marginLeft: '5px',
          marginRight: '8px',
        },
      },

      verify_details: {
        textAlign: 'left',
        width: 'fit-content',
        font: 'normal 700 24px/28px Arial',
        letterSpacing: '0.14px',
        color: COLOR1,
        opacity: 1,
        // marginTop: '25px',
        [theme.breakpoints.down('600')]: {
          font: 'normal 700 24px/28px Arial',
          letterSpacing: '0.11px',
          color: COLOR1,
          margin: '0',
          marginBottom: '5px',
          marginTop: 0,
        },
      },
      margin_adjust_for_top_grid: {
        marginTop: '21px',
        [theme.breakpoints.down('960')]: {
          marginTop: '20px',
        },
      },
      gridStyles: {
        paddingRight: '20px',
        marginBottom: '20px',
        [theme.breakpoints.down('960')]: {
          paddingRight: '0px',
        },
      },
      contact_container: {
        padding: '20px 0px 0px 20px',
        [theme.breakpoints.down('960')]: {
          padding: '20px 25px',
        },
        [theme.breakpoints.down('600')]: {
          padding: '20px',
        },
      },
      contact_disclaimer: {
        font: 'normal normal 400 16px/18px Arial',
        color: COLOR1,
        opacity: 1,
        letterSpacing: '0.1px',
        padding: '5px 9px 0px 0px',
        textAlign: 'left',
      },
      tooltipmsg: { marginBottom: '15px' },

      back_button: {
        border: `1.6px solid ${RADIO_COLOR}`,
        borderRadius: '25px',
        width: '170px',
        height: '45px',
        margin: '0px 20px 0px 0px',
        font: FONT_BOLD_16_18,
        color: `${RADIO_COLOR} !important`,
        textTransform: 'none',
        '&:hover': {
          backgroundColor: COLOR4,
        },
        '&:focus': {
          outline: `2px solid ${RADIO_COLOR}`,
          outlineOffset: '2px',
        },
        [theme.breakpoints.down('600')]: {
          width: '90%',
          marginBottom: '20px',
        },
        // [theme.breakpoints.down('360')]: {
        //   width: '45%',
        // },
      },
      closeStyle: {
        margin: ' 15px',
      },
      backContinue: {
        width: '100%',
        margin: '30px 0',
        [theme.breakpoints.down('900')]: {
          margin: '10px 0 30px 0',
        },
        [theme.breakpoints.down('600')]: {
          margin: '20px',
        },
      },

      saveImg: {
        top: '9px',
      },

      textTwoStyle: {
        font: 'normal 400 16px/18px Arial',
        color: COLOR1,
        letterSpacing: '0.1px',
        marginTop: '20px',
        marginLeft: '26px',
        marginRight: '26px',
      },
      inviTwoStyle: {
        font: 'normal 400 16px/18px Arial',
        color: COLOR1,
        letterSpacing: '0.1px',
        marginTop: '42px',
        marginLeft: '26px',
        marginRight: '26px',
      },
      textTwoStyleKockOff: {
        font: 'normal normal normal 14px/17px Arial',
        color: COLOR1,
        letterSpacing: '0.1px',
        margin: '10px 25px 25px 25px',
      },
      close_icon: {
        width: '34px',
        height: '34px',
        cursor: 'pointer',
        margin: '5px 5px 0 0',
      },

      modal_close: {
        color: RADIO_COLOR,
        textAlign: 'right',
        height: '35px',
      },

      closeOverlay: {
        padding: '30px 30px 0px 0px',
        color: COLOR4,
        width: '44px',
        cursor: 'pointer',
        height: '28px',
        position: 'absolute',
      },
      error_message: {
        font: 'normal normal normal 14px/16px Arial',
        textAlign: 'left',
        letterSpacing: '0.1px',
        color: COLOR2,
        opacity: 1,
        position: 'relative',
        top: '3px',
        display: 'block',
      },
      personal_button_container: {
        [theme.breakpoints.down('650')]: {
          justifyContent: 'center',
          display: 'flex',
        },
      },
    }),
    { index: 1 }
  );
