import { isMacOs } from 'react-device-detect';
import { PropTypes } from 'prop-types';
import { Row, IconButton } from '@livechat/ui-kit';
import { ChatWrapperCss } from '../../utils/WrapperCss';
import ProgressBar from './ProgressBar';
import {
  CANCEL_THIS_PROCESS_ENG,
  CANCEL_THIS_PROCESS_ESP,
  END_CHAT_ENG,
  END_CHAT_ESP,
  END_CHAT_FEEDBACK_ENG,
  END_CHAT_FEEDBACK_ESP,
  REGISTER_AS_CUSTOMER_ENG,
  REGISTER_AS_CUSTOMER_ESP,
} from '../../Constants';
import { CommonStyles } from '../../styles/ChatBotCommonStyles';

const useCommonStyles = CommonStyles();

const useChatWrapperCss = ChatWrapperCss();
const ValidationProcess = (props) => {
  const WrapperCss = useChatWrapperCss();
  const CommoncssStyles = useCommonStyles();

  const {
    englishBot,
    runningAWSConnect,
    showUploadingBar,
    liveAgentJoined,
    isFeedbackProcess,
    setAgentFeedbackTrigger,
    onCancelProcessHandler,
    isValid,
    isCancelProcess,
    errorMessage,
    waitingForLiveAgent,
    setServiceFormTrigger,
    formInProcessTrigger,
    showRegisterCustomerButton,
  } = props;

  const registerCustomer = () => {
    setServiceFormTrigger(true);
    formInProcessTrigger(true);
  };
  return (
    <>
      {isFeedbackProcess && runningAWSConnect && !showUploadingBar && liveAgentJoined && (
        <div
          className={
            isFeedbackProcess ? WrapperCss.cancelBtnDisplay : WrapperCss.cancelBtnNoDisplay
          }
        >
          <Row align="center" className={CommoncssStyles.validation_design}>
            {showRegisterCustomerButton && (
              <IconButton
                data-testid="validation_id"
                className={`cancel-process-btn ${
                  isMacOs ? WrapperCss.button_width_IOS : WrapperCss.button_width_Windows
                }`}
                onClick={registerCustomer}
                aria-label={englishBot ? REGISTER_AS_CUSTOMER_ENG : REGISTER_AS_CUSTOMER_ESP}
                tabIndex="104"
              >
                {englishBot ? REGISTER_AS_CUSTOMER_ENG : REGISTER_AS_CUSTOMER_ESP}
              </IconButton>
            )}
            <IconButton
              className={`cancel-process-btn ${
                isMacOs ? WrapperCss.button_width_IOS : WrapperCss.button_width_Windows
              }`}
              onClick={setAgentFeedbackTrigger}
              aria-label={englishBot ? END_CHAT_FEEDBACK_ENG : END_CHAT_FEEDBACK_ESP}
              tabIndex="104"
            >
              {englishBot ? END_CHAT_ENG : END_CHAT_ESP}
            </IconButton>
          </Row>
        </div>
      )}
      <div
        className={
          isValid && isCancelProcess ? WrapperCss.cancelBtnDisplay : WrapperCss.cancelBtnNoDisplay
        }
      >
        <Row align="center" className={CommoncssStyles.validation_design}>
          <IconButton
            className={`cancel-process-btn ${
              isMacOs ? WrapperCss.button_width_IOS : WrapperCss.button_width_Windows
            }`}
            onClick={onCancelProcessHandler}
            aria-label={englishBot ? CANCEL_THIS_PROCESS_ENG : CANCEL_THIS_PROCESS_ESP}
            data-testid="onCancelProcessHandler_testid"
            tabIndex="104"
            disabled={waitingForLiveAgent}
          >
            {englishBot ? CANCEL_THIS_PROCESS_ENG : CANCEL_THIS_PROCESS_ESP}
          </IconButton>
        </Row>
      </div>
      <ProgressBar runningAWSConnect={runningAWSConnect} showUploadingBar={showUploadingBar} />
      <div className={isValid ? WrapperCss.errorMessageNoDisplay : WrapperCss.errorMessageDisplay}>
        <Row align="center" className={CommoncssStyles.validation_align}>
          <span role="alert">{errorMessage}</span>
        </Row>
      </div>
    </>
  );
};

ValidationProcess.propTypes = {
  englishBot: PropTypes.bool,
  runningAWSConnect: PropTypes.bool,
  showUploadingBar: PropTypes.bool,
  liveAgentJoined: PropTypes.bool,
  isFeedbackProcess: PropTypes.bool,
  setAgentFeedbackTrigger: PropTypes.func,
  onCancelProcessHandler: PropTypes.func,
  isValid: PropTypes.bool,
  isCancelProcess: PropTypes.bool,
  errorMessage: PropTypes.string,
  waitingForLiveAgent: PropTypes.bool,
  setServiceFormTrigger: PropTypes.func,
  formInProcessTrigger: PropTypes.func,
  showRegisterCustomerButton: PropTypes.bool,
};

export default ValidationProcess;
