import { makeStyles } from '@material-ui/core';
import { COLOR4, COLOR1, WHITE, RADIO_COLOR } from '../../constants';

export const CommercialModalStyles = () =>
  makeStyles(
    (theme) => ({
      card_content: {
        textAlign: 'center',
        padding: '0px !important',
        margin: '0px',
      },
      editIcon: {
        paddingBottom: '5px',
        textTransform: 'none',
      },
      saveAndExit: {
        background: `#661C69`,
        color: COLOR4,
        letterSpacing: '0.1px',
        font: 'normal 700 14px/16px Arial',
        width: '136px',
        height: '44px',
        margin: '17px 0 30px 0',
        opacity: 1,
        textTransform: 'none',
        borderRadius: '56px',
        '&:hover': {
          backgroundColor: RADIO_COLOR,
        },
        '&:focus': {
          outline: '2px solid #661c69',
          outlineOffset: '2px',
        },
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: WHITE,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px',
      },
      root: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '332px',
        maxHeight: '246px',
        background: '#FFFFFF',
        boxShadow: '0px 4px 12px rgba(62, 64, 70, 0.08)',
        borderRadius: '10px',
        [theme.breakpoints.down('400')]: {
          maxHeight: 'unset',
        },
      },
      textOneStyle: {
        font: 'normal 700 16px/19px Arial',
        color: COLOR1,
        letterSpacing: '0.12px',
        marginTop: '33px',
        marginLeft: '26px',
        marginRight: '26px',
      },

      textTwoStyle: {
        font: 'normal 400 16px/18px Arial',
        letterSpacing: '0.1px',
        marginTop: '20px',
        marginLeft: '26px',
        color: COLOR1,
        marginRight: '26px',
      },
      inviTwoStyle: {
        font: 'normal 400 16px/18px Arial',
        color: COLOR1,
        marginTop: '42px',
        letterSpacing: '0.1px',

        marginLeft: '26px',
        marginRight: '26px',
      },
      textTwoStyleKockOff: {
        color: COLOR1,
        font: 'normal normal normal 14px/17px Arial',
        letterSpacing: '0.1px',
        margin: '10px 25px 25px 25px',
      },
      close_icon: {
        height: '34px',
        cursor: 'pointer',
        width: '34px',
        margin: '5px 5px 0 0',
      },

      modal_close: {
        textAlign: 'right',
        height: '35px',
        color: RADIO_COLOR,
      },

      closeOverlay: {
        top: '35px',

        color: COLOR4,
        width: '44px',
        cursor: 'pointer',
        height: '28px',
        right: '0px',
        position: 'relative',
        '&:hover': {
          background: 'none',
        },
      },
    }),
    { index: 1 }
  );
