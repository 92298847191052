import { makeStyles } from '@material-ui/core';
import { PURE_BLACK } from '../../constants';

export const customTooltipStyles = () =>
  makeStyles(
    () => ({
      tooltipmsg: { cursor: 'pointer', position: 'relative', top: '2px' },
      tooltip: {
        color: PURE_BLACK,
        borderRadius: '10px',
        maxWidth: '280px',
        width: 'fit-content',
        padding: '8px 11px 8px 11px',
        font: 'normal normal 400 13px/14px Arial',
        letterSpacing: '0.09px',
        lineHeight: '16px',
        opacity: 1,
        background: '#F4EDF4',
      },

      // arrow: {
      //   fontSize: 18,
      //   width: 20,
      //   '&::before': {
      //     border: `0.5px solid ${ORIENTAL_ORANGE}`,
      //     backgroundColor: SEASHELL_COLOR,
      //     boxSizing: 'border-box',
      //   },
      // },
    }),
    { index: 1 }
  );
