/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Card, CardContent, Typography, Modal, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import LeadGenerationContext from '../../context/LeadGenerationContext';
import modalIcon from '../../images/SimpleModalDone.svg';
import { SaveContinueModalStyles } from './SaveContinueModalStyles';

const useStyles = SaveContinueModalStyles();

const SimpleTextModal = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const leadGenerationContext = useContext(LeadGenerationContext);

  const { continueModal, setContinueModal } = leadGenerationContext;
  const [open, setOpen] = React.useState(true);
  useEffect(() => {
    continueModal ? setOpen(true) : setOpen(false);
  }, [continueModal]);

  const handleConti = () => {
    setContinueModal(false);
    setOpen(false);
  };

  return (
    <Modal
      className={classes.backdrop}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(233, 233, 235, 0.78)',
        },
      }}
      open={open}
      role="region"
      aria-describedby="dialog1BusinessDesc dialog2BusinessDesc"
    >
      <div style={{ outline: 'none' }} id="modalSave">
        <Card className={classes.rootConti}>
          <CardContent className={classes.card_content_simple}>
            <>
              <span className={classes.imgSize}>
                <img src={modalIcon} alt="modal icon" />
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                }}
              >
                <Typography
                  className={`${classes.textStyleConfirmModal} ${classes.simpleCard_below_img}`}
                  id="dialog1BusinessDesc"
                >
                  {t('Your application is saved.', {
                    defaultValue: 'Your application is saved.',
                  })}
                </Typography>
                <Typography
                  className={`${classes.textStyleConfirmModal} ${classes.simpleCard_above_btn}`}
                  id="dialog1BusinessDesc"
                >
                  {t('You can continue your process.', {
                    defaultValue: 'You can continue your process.',
                    nsSeparator: '|',
                  })}
                </Typography>
              </div>
              <Grid container className={classes.simple_Modal}>
                <Grid item xs={12}>
                  <Button
                    disableFocusRipple
                    variant="outlined"
                    className={classes.saveAndExit_Confirm}
                    data-testid="CloseTryButtonInBusinessModal"
                    onClick={handleConti}
                  >
                    {t('Continue', { defaultValue: 'Continue' })}
                  </Button>
                </Grid>
              </Grid>
            </>
          </CardContent>
        </Card>
      </div>
    </Modal>
  );
};
export default withRouter(SimpleTextModal);
