import { Box, Grid, Typography } from '@material-ui/core';
import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ReviewLayoutImage from '../../../images/EliteAcCImg3.png';
import { CDProcessingStyles } from './CDProcessingStyles';

import DollarImage from '../../../images/dollar-review.svg';
import PiggySavngsImage from '../../../images/piggy-savings.svg';
import PercentageImage from '../../../images/percentage.svg';
import InterestValueImage from '../../../images/interest-value.svg';
import CDInterestImage from '../../../images/cd-interest.svg';
import { ProductHeadingStyles } from '../ProductHeading/ProductHeadingStyles';
import Layout from '../../Layout';
import { FundingScreenCards } from './FundingScreenCards';

import LeadGenerationContext from '../../../context/LeadGenerationContext';
import { productNameURLMapping } from '../../../services/productNameURLMapping';
import { productMapping } from '../../../services/productMapping';
import SaveAppContactInfo from '../../SaveApp/SaveAppContactInfo';
import { handleETBFlow, isEtbFlow } from '../../commonCode';

const ReviewCD = (props) => {
  const classes = CDProcessingStyles();
  const Productclasses = ProductHeadingStyles();
  const leadGenerationContext = useContext(LeadGenerationContext);
  const {
    cdFlowOffers,
    interestPaymentMethod,
    location,
    language,
    productName,
    setIsModalSaveLater,
    isModalSaveLater,
  } = leadGenerationContext;
  const { history } = props;
  const { t } = useTranslation();

  const certificateOfDeposit =
    productMapping(productName) === 'Personal' || productMapping(productName) === 'Personal CD';
  const handleSaveApplication = (e) => {
    e.preventDefault();
    setIsModalSaveLater(true);
  };

  const handleContinue = () => {
    history?.push(
      `/${location?.toLowerCase()}/${language?.toLowerCase()}${
        isEtbFlow(leadGenerationContext) ? '/express' : ''
      }/retail-form/${productNameURLMapping(productName)}/consent-info`
    );
  };

  const handleBack = () => {
    history?.push(
      `/${location?.toLowerCase()}/${language?.toLowerCase()}${
        isEtbFlow(leadGenerationContext) ? '/express' : ''
      }/retail-form/${productNameURLMapping(productName)}/funding-info`
    );
  };
  return (
    <Layout
      handleSaveApplication={handleSaveApplication}
      ariaDescribedby="ReviewCDHeading"
      headingId="ReviewCDHeading"
      ariaDescribedbyId="reviewCDDesc"
      classes={classes}
      descriptionKey={t('Its time to review your application.')}
      descriptionValue={t('Its time to review your application.')}
      stepValue={certificateOfDeposit ? 3 : 1}
      handleBack={handleBack}
      handleContinue={handleContinue}
      // disableCondition={isValidating}
      backBtnTestid="back_button_testid"
      continueBtnTestid="continue_testId"
      // screenLoading={isValidating}
      layoutImage={ReviewLayoutImage}
    >
      <div className={classes.review_grid_margin}>
        <Typography
          id="heading"
          data-testid="heading_testid"
          role="heading"
          variant="h1"
          className={Productclasses.heading_info}
        >
          {t('Review you CD terms!', {
            defaultValue: 'Review you CD terms!',
          })}
        </Typography>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} className={classes.review_content}>
            <Box display="flex">
              <img src={DollarImage} alt="Dollar Symbol" />
              <Box className={classes.contextBox}>
                <Typography className={classes.cardHeading}>
                  {t('CD Initial Amount', { defaultValue: 'CD Initial Amount' })}:
                </Typography>
                <Typography style={{ fontSize: '32px', fontWeight: '700' }}>
                  ${cdFlowOffers.depositAmount}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid container className={classes.reviewCardStyle} spacing={3}>
            <Grid item lg={5} md={5} sm={12}>
              <Box display="flex">
                <img src={PiggySavngsImage} alt="Piggy Savings Image" />
                <Box className={classes.contextBox}>
                  <Typography className={classes.cardHeading}>
                    {t('CD Terms', { defaultValue: 'CD Terms' })}:
                  </Typography>
                  <Typography className={classes.cardValue}>
                    {' '}
                    {cdFlowOffers?.offers?.term}{' '}
                    {cdFlowOffers?.offers?.term === '1' ? 'MONTH' : 'MONTHS'}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={5} md={5} sm={12}>
              <Box display="flex">
                <img src={PercentageImage} alt="Percentage Image" />
                <Box className={classes.contextBox}>
                  <Typography className={classes.cardHeading}>
                    {t('Interest Rate', { defaultValue: 'Interest Rate' })}:
                  </Typography>
                  <Typography className={classes.cardValue}>
                    {cdFlowOffers?.offers?.apr}%
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={5} md={5} sm={12}>
              <Box display="flex">
                <img src={InterestValueImage} alt="Interest Image" />
                <Box className={classes.contextBox}>
                  <Typography className={classes.cardHeading}>
                    {t('Accrued Interest Method', { defaultValue: 'Accrued Interest Method' })}:
                  </Typography>
                  <Typography className={classes.cardValue}>
                    {interestPaymentMethod?.accuredIntMethod === 'Add-on'
                      ? 'ADD ON'
                      : interestPaymentMethod?.accuredIntMethod?.toUpperCase()}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={5} md={5} sm={12}>
              <Box display="flex">
                <img src={CDInterestImage} alt="Interest Image" />
                <Box className={classes.contextBox}>
                  <Typography className={classes.cardHeading}>
                    {t('CD Interest (APY)', { defaultValue: 'CD Interest (APY)' })}:{' '}
                  </Typography>
                  <Typography className={classes.cardValue}>
                    {' '}
                    {cdFlowOffers?.offers?.apy}%
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {isModalSaveLater && (
        <SaveAppContactInfo openModal={isModalSaveLater} currentPage="cd-review" />
      )}
    </Layout>
  );
};

export default withRouter(ReviewCD);

ReviewCD.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};
