import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { customTooltipStyles } from './CustomTooltipStyles';
import InfoIcon from '../../images/suri_tooltip.svg';
import { TOOLTIP_ALTMSG, TOOLTIP_POSITION_RIGHT, TOOLTIP_POSITION_TOP } from '../../constants';
import { commonTernary } from '../commonCode';

const useStyles = customTooltipStyles();

function CustomTooltip({ text, customAriaLabel, placement, custom, widthConsumer }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Tooltip
      title={text || ''}
      arrow={false}
      placement={isMobile ? TOOLTIP_POSITION_TOP : placement || TOOLTIP_POSITION_RIGHT}
      leaveTouchDelay={10000}
      enterTouchDelay={50}
      leaveDelay={500}
      TransitionComponent={Zoom}
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      <img
        src={InfoIcon}
        width={widthConsumer ? '24px' : commonTernary(custom, '13px', '20px')}
        height={widthConsumer ? '24px' : commonTernary(custom, '13px', '20px')}
        tabIndex="0"
        alt={customAriaLabel || t(TOOLTIP_ALTMSG, { defaultValue: TOOLTIP_ALTMSG })}
      />
    </Tooltip>
  );
}

export default CustomTooltip;

CustomTooltip.propTypes = {
  text: PropTypes.string,
  customAriaLabel: PropTypes.string,
  placement: PropTypes.string,
  custom: PropTypes.bool,
  widthConsumer: PropTypes.bool,
};
