import { InputLabel, OutlinedInput } from '@material-ui/core';
import PropTypes from 'prop-types';
import { COLOR4 } from '../../../constants';
import { CommonStyles } from '../CommonStyles';
import { commonUtility } from '../UtilityFunctions';
import { commonTernary } from '../../commonCode';

export const CustomInput = ({
  id,
  dataTestId,
  required,
  inputLabelAriaLabel,
  inputLabelClassName,
  label,
  autoFocus,
  InputClassName,
  handleChange,
  placeholder,
  fullWidth,
  handleBlur,
  inputProps,
  value,
  error,
  dataTestIdForLabel,
  endAdornment,
  disabled,
  onKeyDown,
  multiline,
  rows,
  startAdornment,
  handleFocus,
}) => {
  const classes = CommonStyles();
  const getLabel = (fieldLabel) => {
    if (fieldLabel) {
      return fieldLabel;
    }
    return '';
  };
  return (
    <>
      <InputLabel
        htmlFor={commonUtility(id)}
        data-testid={commonUtility(dataTestIdForLabel)}
        required={required || false}
        aria-label={commonUtility(inputLabelAriaLabel)}
        className={`${classes.label_title} ${inputLabelClassName || ''}`}
      >
        {getLabel(label)}
      </InputLabel>
      <OutlinedInput
        data-testid={dataTestId || null}
        id={id || null}
        name={commonUtility(id)}
        autoFocus={autoFocus || false}
        className={commonTernary(
          disabled,
          `${classes.outlined_inputs} ${classes.outlined_inputs_disabled} ${InputClassName || ''}`,
          `${classes.outlined_inputs} ${InputClassName || ''}`
        )}
        classes={{
          adornedEnd: classes.adorned_end,
          notchedOutline: !error ? classes.notchedOutline : classes.notchedOutlineErr,
        }}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        fullWidth={fullWidth || false}
        onBlur={handleBlur}
        inputProps={inputProps || {}}
        value={value || ''}
        autoComplete="off"
        style={error ? { background: `${COLOR4}` } : {}}
        endAdornment={endAdornment || null}
        startAdornment={startAdornment || null}
        disabled={!!disabled}
        rows={rows || 0}
        multiline={multiline || false}
      />
    </>
  );
};

CustomInput.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  inputLabelAriaLabel: PropTypes.string,
  inputLabelClassName: PropTypes.string,
  label: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  InputClassName: PropTypes.string,
  onKeyDown: PropTypes.func,
  handleChange: PropTypes.func,
  handleFocus: PropTypes.func,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  handleBlur: PropTypes.func,
  inputProps: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  dataTestId: PropTypes.string,
  dataTestIdForLabel: PropTypes.string,
  endAdornment: PropTypes.object,
  rows: PropTypes.number,
  multiline: PropTypes.bool,
  startAdornment: PropTypes.object,
};
