import { makeStyles } from '@material-ui/core';
import { ORIENTAL_D_GRAY, WHITE } from '../../constants';

export const stepperStyles = () =>
  makeStyles(
    (theme) => ({
      root: {
        width: '100%',
      },

      zero_Padding: {
        padding: '0',
        margin: '0px 10px 12px',
        [theme.breakpoints.down('650')]: {
          width: '100%',
          margin: '0',
        },
      },
      stepperWrapperEng: {
        position: 'relative',
        height: '65px',
        [theme.breakpoints.down('650')]: {
          height: '90px',
        },
        [theme.breakpoints.down('600')]: {
          marginRight: '30px',
        },
      },
      stepperWrapperEsp: {
        position: 'relative',
        height: '65px',
        [theme.breakpoints.down('650')]: {
          height: '90px',
        },
        [theme.breakpoints.down('600')]: {
          marginRight: '50px',
        },
      },
      label: {
        marginTop: '7px',
        display: 'block',
        font: 'normal normal normal 12px Arial',
        fontWeight: '400',
        letterSpacing: '0.1px',
        color: ORIENTAL_D_GRAY,
        opacity: 1,
      },
      labelCompleted: {
        marginTop: '7px',
        display: 'block',
        font: 'normal normal normal 12px Arial',
        fontWeight: '700',
        letterSpacing: '0.1px',
        color: '#661C69',
        opacity: 1,
      },
      businessLabel: {
        marginTop: '7px',
        display: 'block',
        font: 'normal normal normal 12px Arial',
        fontWeight: '400',
        letterSpacing: '0.1px',
        color: '#727578',
        opacity: 1,
        width: 'min-content',
        [theme.breakpoints.down('600')]: {
          marginTop: '7px',
          display: 'block',
          font: 'normal normal 700 12px/14px Arial',
          fontWeight: '400',
          letterSpacing: '0.1px',
          color: '#727578',
          opacity: 1,
          width: 'min-content',
        },
      },
      businessLabelCompleted: {
        marginTop: '7px',
        display: 'block',
        font: 'normal normal normal 12px Arial',
        fontWeight: '700',
        letterSpacing: '0.1px',
        color: '#661C69',
        opacity: 1,
        width: 'min-content',
        [theme.breakpoints.down('600')]: {
          marginTop: '7px',
          display: 'block',
          font: 'normal normal 700 12px/14px Arial',
          fontWeight: '700',
          letterSpacing: '0.1px',
          color: '#661C69',
          opacity: 1,
          width: 'min-content',
        },
        // [theme.breakpoints.down('450')]: {
        //   fontSize: '9px',
        //   fontWeight: 'bolder',
        // },
      },
      businessLabelConsent: {
        marginTop: '7px',
        display: 'block',
        font: 'normal normal normal 14px/17px Arial',
        letterSpacing: '0.1px',
        color: '#555858',
        opacity: 1,
        [theme.breakpoints.down('600')]: {
          fontSize: '9px',
          fontWeight: 'bolder',
        },
      },

      iconWrapper: {
        width: '100%',
        position: 'relative',
      },
      icon: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 'auto',
        textAlign: 'center',
      },
      inviStyle: {
        display: 'none',
      },
      steps: {
        height: '36px',
        width: '36px',
        font: 'normal normal bold 16px Arial',
        letterSpacing: '0.08px',
        color: '#727578',
        backgroundColor: '#F3F3F3',
        display: 'block',
        lineHeight: '36px',
        textAlign: 'center',
        border: `2px solid #D0D0D0`,
        borderRadius: '50%',
      },
      completed: {
        border: `3px solid #661C69`,
        backgroundColor: WHITE,
        color: '#661C69',
      },
      current: {
        border: `3px solid #661C69`,
        backgroundColor: '#FFFFFF',
      },
      upcoming: {
        border: `3px solid #D0D0D0`,
        backgroundColor: '#FFFFFF',
      },
      fullycompleted: {
        border: `3px solid #661C69`,
        color: '#FFFFFF',
        backgroundColor: '#661C69',
      },
    }),
    { index: 1 }
  );
