/* eslint-disable no-await-in-loop */
import axios from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Buffer } from 'buffer';
import SparkMD5 from 'spark-md5';
import { isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import GenerateLexUserCookie from './connectivity/GenerateLexUserCookie';
import GenerateLexUserId from './connectivity/GenerateLexUserId';
import { ADDITIONAL_DETAILS, CUSTOMER_MESSAGE, MESSAGE_BODY } from './Constants';
import { GenerateUuid, encryptData } from './utils/CommonFunctions';

const lexUserId = GenerateLexUserId();
const lexUserCookie = GenerateLexUserCookie();
export const base64Authorization = Buffer.from(
  `${process.env.REACT_APP_CLIENT_ID_CHATBOT}:${process.env.REACT_APP_CLIENT_SECRET_CHATBOT}`
).toString('base64');
export const getParameters = (
  message,
  slotToElicit,
  englishBot,
  sessionID,
  chatDeviceType,
  chatDeviceOS,
  SspUserLogin,
  uuid,
  isEncryptionNeeded,
  customerGuid,
  dobAtLogin
) => {
  let inputMessage = '';
  const uuidKey = GenerateUuid();
  if (
    slotToElicit === ADDITIONAL_DETAILS ||
    slotToElicit === MESSAGE_BODY ||
    slotToElicit === CUSTOMER_MESSAGE
  ) {
    inputMessage = `<slot>${message}<slot>`;
    // } else if (isEncryptionNeeded) {
    //   inputMessage = encryptData(message, uuidKey);
  } else {
    inputMessage = message;
  }
  const requestAttributes =
    SspUserLogin === 'true'
      ? {
          userId: lexUserId,
          deviceType: chatDeviceType,
          DeviceOS: chatDeviceOS,
          isLoggedIn: 'Y',
          chatbotktc: lexUserCookie,
          customerNumber: customerGuid,
          dob: dobAtLogin,
        }
      : {
          userId: lexUserId,
          deviceType: chatDeviceType,
          DeviceOS: chatDeviceOS,
          isLoggedIn: 'N',
          chatbotktc: lexUserCookie,
        };

  if (uuid && uuid.length > 0) {
    requestAttributes.DocumentsUUID = uuid.join(',');
  }

  const obj =
    // isEncryptionNeeded
    //   ? {
    //       inputText: inputMessage,
    //       locale: englishBot ? 'en_US' : 'es_US',
    //       sessionId: sessionID || '',
    //       uuid: uuidKey,
    //       requestAttributes,
    //     }
    //   :
    {
      inputText: inputMessage,
      locale: englishBot ? 'en_US' : 'es_US',
      sessionId: sessionID || '',
      requestAttributes,
    };

  return obj;
};

export const timeConvert = (num) => {
  let hours = Math.floor(num / 60);
  let minutes = num % 60;
  minutes = String(minutes).padStart(2, '0');
  hours = String(hours).padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const toggleChatbotContainerSize = (popupRunningFlag) => {
  if (!popupRunningFlag) {
    if ('parentIFrame' in window) {
      window.parentIFrame.getPageInfo((obj) => {
        const winHeight = (obj.windowHeight * 95) / 100;
        if (isMobile) {
          window.parentIFrame.size(winHeight, obj.windowWidth);
        } else {
          window.parentIFrame.size(winHeight, 470);
        }
      });
    }
  }
};

export const deleteSessioApiCall = (englishBot, sessionID) => {
  axios
    .post(
      process.env.REACT_APP_TOKEN_SERVICE_URL_CHATBOT,
      {},
      {
        headers: {
          Authorization: `Basic ${base64Authorization}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    .then((response) => {
      axios.post(
        `${process.env.REACT_APP_DELETE_SESSION_API_CHATBOT}`,
        {
          locale: englishBot ? 'en_US' : 'es_US',
          sessionId: sessionID,
        },
        {
          headers: {
            Authorization: `Bearer ${response.data.access_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
    })
    .catch();
};
export const focusInChatbot = () => {
  const messageGroup = document.getElementById('messageGroup');
  const messageTextarea = document.getElementById('messageText');
  if (
    messageGroup &&
    messageGroup.lastChild.lastElementChild.querySelectorAll('.quick-replies').length > 0
  ) {
    messageGroup.lastChild.lastElementChild
      .querySelectorAll('.quick-replies')[0]
      .firstChild.focus();
  } else if (messageTextarea) {
    messageTextarea.focus();
  }
};

export const generateuuid = () => uuidv4();

export const uploadDocumentByPreSignedURL = async (
  preSignedUrl,
  file,
  fileMd5,
  callbackFunction,
  args
) => {
  let response = null;
  try {
    response = await axios
      .put(preSignedUrl, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-MD5': fileMd5,
        },
      })
      .then(() => {
        if (args && callbackFunction) {
          const { payload, customerVerifyToken, lang, generateKey, isFileSizeGreaterThan4MB } =
            args;
          return callbackFunction(
            payload,
            customerVerifyToken,
            lang,
            generateKey,
            isFileSizeGreaterThan4MB
          );
        }
      });
  } catch (e) {
    return response;
  }
  return response;
};
